export const Colors = {
  AZURE: "#E1F0EC",
  BISQUE: "#FEF2ED",
  BLACK: "#000000",
  BLUE_BAYOUX: "#4F5C7E",
  CAPUCINE: "#ED694B",
  GIVRE: "#8CBEBA",
  MANDARINE: "#F7AC6F",
  MIRAGE: "#171D35",
  POUDRE: "#E5B5AA",
  WHITE: "#FFFFFF",
  _000000_BLACK: "#000000",
  _171D35_MIRAGE: "#171D35",
  _4F5C7E_BLUE_BAYOUX: "#4F5C7E",
  _8CBEBA_GIVRE: "#8CBEBA",
  _E1F0EC_AZURE: "#E1F0EC",
  _E5B5AA_POUDRE: "#E5B5AA",
  _ED694B_CAPUCINE: "#ED694B",
  _F7AC6F_MANDARINE: "#F7AC6F",
  _FEF2ED_BISQUE: "#FEF2ED",
  _FFFFFF_WHITE: "#FFFFFF",
};

export const Fonts = {
  SANS: "'Spartan', sans-serif",
  SERIF: "'Cormorant', serif",
  _Cormorant_SERIF: "'Cormorant', serif",
  _Spartan_SANS: "'Spartan', sans-serif",
};

export const MOBILE = 1024;
