import { Media } from "./media";

export type Supplier = {
  _id: string;
  slug: string;
  name: string;
  chapo: string;
  description: string;
  showQuote: boolean;
  quote: string;
  quoteAuthor: string;
  logo: Media | null;
  photo: Media | null;
};

export type SupplierDto = {
  _id?: string;
  slug: string;
  name: string;
  chapo: string;
  description: string;
  showQuote: boolean;
  quote: string;
  quoteAuthor: string;
  logo: Media | null;
  photo: Media | null;
};

export const supplierDbToDto = (supplier: Supplier): SupplierDto => {
  return {
    _id: supplier?._id,
    slug: supplier?.slug,
    name: supplier?.name,
    chapo: supplier?.chapo,
    description: supplier?.description,
    showQuote: supplier?.showQuote,
    quote: supplier?.quote,
    quoteAuthor: supplier?.quoteAuthor,
    logo: supplier?.logo || null,
    photo: supplier?.photo || null,
  };
};

export const defaultSupplier: SupplierDto = {
  name: "",
  slug: "",
  chapo: "",
  description: "",
  showQuote: true,
  quote: "",
  quoteAuthor: "",
  logo: null,
  photo: null,
};
