import React from "react";
import { Route } from "react-router-dom";
import TvaList from "./TvaList";
import TvaCreate from "./TvaCreate";
import TvaUpdate from "./TvaUpdate";
import TvaDelete from "./TvaDelete";

export default (
  <Route path="tva">
    <Route index element={<TvaList />} />
    <Route path="add" element={<TvaCreate />} />
    <Route path="update/:id" element={<TvaUpdate />} />
    <Route path="delete/:id" element={<TvaDelete />} />
  </Route>
);
