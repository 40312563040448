import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProductVariant, productVariantDbToDto, Product } from "../../types";
import ProductVariantForm from "./ProductVariantForm";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import price from "../../utils/price";
import BtnAdd from "../../components/Element/BtnAdd";
import {
  Edit as EditIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { Fab } from "@mui/material";
import {
  productVariantCreate,
  productVariantDelete,
  productVariantFindAll,
  productFindOne,
} from "../../api";
import { randomRef } from "../../utils/random";
import BtnDelete from "../../components/Element/BtnDelete";

export default function ProductList() {
  const [popup, setPopup] = useState<ProductVariant | null>(null);
  const [addForm, setAddForm] = useState<boolean>(false);
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([]);
  const [product, setProduct] = useState<Product | null>(null);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    productVariantFindAll(id).then(setProductVariants);
    productFindOne(id).then(setProduct);
  }, [id]);

  const copyVariant = useCallback(
    async (variant: ProductVariant) => {
      if (!product) return;
      const newPv = await productVariantCreate(product._id, {
        ...productVariantDbToDto(variant),
        ref: randomRef(),
        isActive: false,
      });

      setProductVariants((pvs) => {
        return pvs.reduce<ProductVariant[]>((acc, pv) => {
          if (pv._id !== variant._id) return [...acc, pv];
          return [...acc, pv, newPv];
        }, []);
      });
    },
    [product]
  );

  const onAdd = useCallback((pv?: ProductVariant) => {
    if (!pv) return;
    setProductVariants((pvs) => [...pvs, pv]);
  }, []);

  const onEdit = useCallback((pv?: ProductVariant) => {
    if (!pv) return;
    setProductVariants((pvs) => pvs.map((p) => (p._id === pv._id ? pv : p)));
  }, []);

  const deleteVariant = useCallback(
    async (variant: ProductVariant) => {
      if (!product) return;
      await productVariantDelete(product._id, variant._id);
      setProductVariants((pvs) => pvs.filter((p) => p._id !== variant._id));
    },
    [product]
  );

  if (!product) {
    return <span>chargement...</span>;
  }

  return (
    <>
      <BtnAdd onClick={() => setAddForm(true)} />
      <h1>Variantes pour {product.name}</h1>

      {productVariants.length === 0 && <p>Ajoutez une première variante :</p>}
      {productVariants.length > 0 && (
        <TableContainer component={Paper} sx={{ mb: 5 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom de la variante</TableCell>
                <TableCell align="right">Référence</TableCell>
                <TableCell align="right">Prix</TableCell>
                <TableCell align="right">En ligne</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productVariants.map((variant) => {
                return (
                  <TableRow
                    key={variant._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="variant">
                      {variant.name}
                    </TableCell>
                    <TableCell align="right">{variant.ref}</TableCell>
                    <TableCell align="right">{price(variant.price)}</TableCell>
                    <TableCell align="right">
                      {variant.isActive ? "Oui" : "Non"}
                    </TableCell>
                    <TableCell align="right">
                      <Fab
                        sx={{ mr: 1 }}
                        color="primary"
                        size="small"
                        aria-label="Modifier"
                        onClick={() => setPopup(variant)}
                      >
                        <EditIcon />
                      </Fab>

                      <Fab
                        sx={{ mr: 1 }}
                        color="primary"
                        size="small"
                        aria-label="Duppliquer"
                        onClick={() => copyVariant(variant)}
                      >
                        <ContentCopyIcon />
                      </Fab>

                      <BtnDelete
                        label="Supprimer"
                        onClick={() => {
                          if (window.confirm(`Supprimer ${variant.name} ?`)) {
                            deleteVariant(variant);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {addForm && (
        <ProductVariantForm
          product={product}
          onFinish={(productVariant) => {
            onAdd(productVariant);
            setAddForm(false);
          }}
        />
      )}
      {popup && (
        <ProductVariantForm
          product={product}
          productVariant={popup}
          onFinish={(productVariant) => {
            onEdit(productVariant);
            setPopup(null);
          }}
        />
      )}
    </>
  );
}
