import useSWR, { useSWRConfig } from "swr";
import { Tva } from "../types";
import { tvaFindAll } from "../api/tva";

type ReturnType = {
  tvas: Tva[] | null | undefined;
  isLoading: boolean;
  isError: Error | null;
  mutate: () => any;
};

export default function useTvas(): ReturnType {
  const key = `/supplier/`;
  const { data, error } = useSWR(key, tvaFindAll);
  const { mutate } = useSWRConfig();

  return {
    tvas: data,
    isLoading: !error && !data,
    isError: error,
    mutate: () => {
      mutate(key);
    },
  };
}
