import React from "react";
import { Route } from "react-router-dom";
import OrderList from "./OrderList";
import OrderCreate from "./OrderCreate";
import OrderUpdate from "./OrderUpdate";

export default (
  <Route path="order">
    <Route index element={<OrderList />} />
    <Route path="add" element={<OrderCreate />} />
    <Route path=":id/update" element={<OrderUpdate />} />
  </Route>
);
