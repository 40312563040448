import { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputText from "../Field/InputText";
import Button from "../Field/Button";
import { useSnack } from "../../contexts/SnackContext";
import Confirm from "../Element/Confirm";
import * as yup from "yup";
import axios from "../../utils/axios";

type Props = {
  url: string;
  name: string;
  onFinish: () => any;
};

type FormFields = {
  name: string;
};

export default function DeleteForm({
  url,
  name,
  onFinish,
}: Props): React.ReactElement {
  const [dialog, setDialog] = useState(false);
  const { control, watch, handleSubmit } = useForm<FormFields>({
    mode: "onChange",
    resolver: yupResolver(
      yup
        .object({
          name: yup
            .string()
            .oneOf([name], `Le champ doit correspondre à "${name}"`)
            .required("Le nom est un champ requis"),
        })
        .required()
    ),
  });

  const data = watch();
  const snack = useSnack();

  const onSubmit = async () => {
    try {
      await axios.delete(url, { data });
      snack.success(`Vous venez de supprimer ${name}`);
      onFinish();
    } catch (err: any) {
      snack.error(`Impossible de supprimer ${name}`);
    }
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(() => setDialog(true))}
    >
      {dialog && (
        <Confirm
          title={"Attention"}
          content={`Êtes-vous sûr de vouloir supprimer ${name} ?`}
          ok={"Oui"}
          ko={"Non"}
          onClick={(res) => {
            setDialog(false);
            if (res) {
              onSubmit();
            }
          }}
        />
      )}
      <InputText
        label={`Veuillez taper ${name} pour confirmer la suppression.`}
        name="name"
        control={control}
      />

      <Grid container alignItems="space-between">
        <Grid item xs={6}>
          <Button
            onClick={onFinish}
            type="button"
            label={"Non, retour à la liste"}
            fullWidth={false}
          />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <Button
            color="error"
            type="submit"
            label={"Oui, je supprime !"}
            fullWidth={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
