import { OrderCard } from "./Card";
import { OrderStatus, Order } from "../../../types";
import React from "react";

type Props = {
  label: string | React.ReactElement;
  orders: Order[];
  status: OrderStatus;
  prev: ((order: Order) => any) | null;
  next: ((order: Order) => any) | null;
};

export default function Column({
  orders,
  status,
  prev = null,
  next = null,
  label,
}: Props) {
  return (
    <div
      style={{
        width: "300px",
        marginLeft: "10px",
        paddingLeft: "10px",
        borderLeft: "1px solid grey",
      }}
    >
      <h2>{label}</h2>
      {orders
        .filter((order) => order.status === status)
        .map((order) => (
          <OrderCard
            order={order}
            key={order._id}
            prev={prev ? () => prev(order) : null}
            next={next ? () => next(order) : null}
          />
        ))}
    </div>
  );
}
