import React from "react";
import { Route } from "react-router-dom";
import UserList from "./UserList";
import UserCreate from "./UserCreate";
import UserUpdate from "./UserUpdate";
import UserOrderList from "./UserOrderList";

export default (
  <Route path="user">
    <Route index element={<UserList />} />
    <Route path="add" element={<UserCreate />} />
    <Route path="update/:id" element={<UserUpdate />} />
    <Route path="order/:id" element={<UserOrderList />} />
  </Route>
);
