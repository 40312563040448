import * as yup from "yup";
import { Address, AddressDto, addressToDto, defaultAddress } from "./address";

export enum UserRole {
  User = "user",
  Admin = "admin",
}

export const userRolesList = [
  { label: "Client", value: UserRole.User },
  { label: "Administrateur", value: UserRole.Admin },
];

export type UserDto = {
  roles: UserRole[];
  firstName: string;
  lastName: string;
  email: string;
  deliveryAddress: AddressDto;
  otherInvoiceAddress: boolean;
  invoiceAddress: AddressDto;
  acceptNewsletter: boolean;
  acceptMentions: boolean;
};

export type User = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  createOn: Date;
  roles: UserRole[];
  deliveryAddress: Address;
  invoiceAddress: Address;
  acceptNewsletter: boolean;
  acceptMentions: boolean;
};
export const userSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  roles: yup.array().of(yup.string().oneOf(Object.values(UserRole))),
  acceptMentions: yup
    .boolean()
    .oneOf([true], "Vous devez accepter les mentions légales.")
    .required("Vous devez accepter les mentions légales.")
    .label("Mention"),
});

export const userDbToDto = (user?: User | null): UserDto => {
  if (!user) {
    return {
      roles: [UserRole.User],
      firstName: "",
      lastName: "",
      email: "",
      deliveryAddress: defaultAddress,
      invoiceAddress: defaultAddress,
      otherInvoiceAddress: false,
      acceptNewsletter: false,
      acceptMentions: false,
    };
  }

  return {
    roles: user.roles || "",
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    deliveryAddress: addressToDto(user.deliveryAddress),
    invoiceAddress: addressToDto(user.invoiceAddress),
    otherInvoiceAddress: false,
    acceptNewsletter: user.acceptNewsletter,
    acceptMentions: user.acceptMentions,
  };
};
