import React from "react";
import { Route } from "react-router-dom";
import ProductCopy from "./ProductCopy";
import ProductCreate from "./ProductCreate";
import ProductDelete from "./ProductDelete";
import ProductList from "./ProductList";
import ProductUpdate from "./ProductUpdate";
import ProductVariantList from "./ProductVariantList";

export default (
  <Route path="product">
    <Route index element={<ProductList />} />
    <Route path="add" element={<ProductCreate />} />
    <Route path="copy/:id" element={<ProductCopy />} />
    <Route path="delete/:id" element={<ProductDelete />} />
    <Route path="update/:id" element={<ProductUpdate />} />
    <Route path="variant/:id" element={<ProductVariantList />} />
  </Route>
);
