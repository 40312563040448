import React, { createContext, useContext, useMemo } from "react";
import { useSnackbar, SnackbarKey } from "notistack";

type Variant = "success" | "error" | "warning" | "info" | "default";

export type SnackContextType = {
  success(message: string): SnackbarKey | false;
  error(message: string): SnackbarKey | false;
  info(message: string): SnackbarKey | false;
  warning(message: string): SnackbarKey | false;
  send(message: string, variant: Variant): SnackbarKey | false;
};

const Context = createContext<SnackContextType>({
  success: () => false,
  error: () => false,
  info: () => false,
  warning: () => false,
  send: () => false,
});

function SnackContext({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const value = useMemo(() => {
    const send = (message: string, variant: Variant) =>
      enqueueSnackbar(message, { variant });
    return {
      success: (message: string) => send(message, "success"),
      error: (message: string) => send(message, "error"),
      info: (message: string) => send(message, "info"),
      warning: (message: string) => send(message, "warning"),
      send: (message: string, variant: Variant) =>
        send(message, variant || "default"),
    };
  }, [enqueueSnackbar]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useSnack = (): SnackContextType => useContext(Context);
export default SnackContext;
