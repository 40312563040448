import React, { useEffect, useState } from "react";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import InputText from "../InputText";
import Select from "../Select";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { zipFindByCode } from "../../../api";
import Button from "../Button";
import { SelectOption } from "../../../types";

type Props = {
  control: Control<any>;
  label: string;
  name: string;
  disable?: string[];
  setValue: SetFieldValue<any>;
  onClose: () => any;
};

function Address({
  control,
  label,
  name,
  disable = [],
  setValue,
  onClose,
}: Props): React.ReactElement {
  const [cities, setCities] = useState<SelectOption[]>([]);

  const zip = useWatch({ control, name: `${name}.zip` });
  const city = useWatch({ control, name: `${name}.city` });

  useEffect(() => {
    if (!zip || zip.length < 4) setCities([]);
    const id = setTimeout(() => {
      zipFindByCode(zip).then((res) => {
        setCities(
          res.map((c) => ({
            label: c.city,
            value: c._id,
          }))
        );
        if (!res.find((c) => c._id === city)) {
          setValue(`${name}.city`, res[0]?._id || "");
        }
      });
    }, 600);
    return () => clearTimeout(id);
  }, [zip, setValue, city, name]);

  return (
    <Grid container spacing={1} columnSpacing={3} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <InputLabel sx={{ mt: 3 }}>{label}</InputLabel>
      </Grid>
      {!disable.includes("firstName") && (
        <Grid item xs={6}>
          <InputText
            name={`${name}.firstName`}
            label="Nom de famille"
            control={control}
          />
        </Grid>
      )}
      {!disable.includes("lastName") && (
        <Grid item xs={6}>
          <InputText
            name={`${name}.lastName`}
            label="Prénom"
            control={control}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <InputText
          name={`${name}.company`}
          label="Nom de société"
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <InputText name={`${name}.address`} label="Adresse" control={control} />
      </Grid>
      <Grid item xs={12}>
        <InputText
          name={`${name}.address2`}
          label="Complément d'adresse"
          control={control}
        />
      </Grid>
      <Grid item xs={4}>
        <InputText name={`${name}.zip`} label="Code postal" control={control} />
      </Grid>
      <Grid item xs={8}>
        <Select
          label="Ville"
          name={`${name}.city`}
          options={cities}
          control={control}
        />
      </Grid>
      {!disable.includes("phone") && (
        <Grid item xs={12}>
          <InputText
            name={`${name}.phone`}
            label="Téléphone"
            control={control}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Button
          sx={{ mt: "50px", mb: "50px" }}
          type="button"
          onClick={onClose}
          label="Enregistrer"
        />
      </Grid>
    </Grid>
  );
}

export default Address;
