import React from "react";
import { Route } from "react-router-dom";
import PurchaseList from "./PurchaseList";
import PurchaseCreate from "./PurchaseCreate";
import PurchaseUpdate from "./PurchaseUpdate";
import PurchaseDelete from "./PurchaseDelete";
import PurchaseCopy from "./PurchaseCopy";

export default (
  <Route path="purchase">
    <Route index element={<PurchaseList />} />
    <Route path="add" element={<PurchaseCreate />} />
    <Route path="update/:id" element={<PurchaseUpdate />} />
    <Route path="delete/:id" element={<PurchaseDelete />} />
    <Route path="copy/:id" element={<PurchaseCopy />} />
  </Route>
);
