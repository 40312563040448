import { Delete as DeleteIcon } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import Color from "color";

type Props = {
  onClick: () => any;
  label?: string;
};

export default function BtnDelete({ onClick, label = "Supprimer" }: Props) {
  return (
    <Fab
      onClick={onClick}
      sx={{
        mr: 1,
        backgroundColor: "#ED694B",
        "&:hover": {
          backgroundColor: new Color("#ED694B").darken(0.1).toString(),
        },
      }}
      color="secondary"
      size="small"
      aria-label={label}
    >
      <DeleteIcon />
    </Fab>
  );
}
