import DeliveryForm from "./DeliveryForm";

export default function DeliveryList() {
  return (
    <div>
      <h1>Lieux de livraison</h1>
      <DeliveryForm />
    </div>
  );
}
