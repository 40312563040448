import React from "react";
import {
  useForm,
  useFieldArray,
  SubmitHandler,
  FormProvider,
} from "react-hook-form";
import { Grid, Button as MuiButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import InputText from "../../components/Field/InputText";
import SelectAsyncSearch from "../../components/Field/SelectAsyncSearch";
import Button from "../../components/Field/Button";
import BtnDelete from "../../components/Element/BtnDelete";
import {
  Product,
  ProductVariant,
  ProductVariantDto,
  defaultProductVariant,
  productVariantDbToDto,
  productVariantSchema,
} from "../../types";
import {
  productVariantCreate,
  productVariantUpdate,
  purchaseFindAllForSelect,
  purchaseFindOneForSelect,
} from "../../api";
import Switch from "../../components/Field/Switch";
import InputNumber from "../../components/Field/InputNumber";
import PopupForm from "../../components/Element/PopupForm";
import { randomRef } from "../../utils/random";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  product: Product;
  productVariant?: ProductVariant;
  onFinish?: (product?: ProductVariant) => any;
};

const ProductVariantForm = ({ product, productVariant, onFinish }: Props) => {
  const id = productVariant ? productVariant?._id : null;
  const methods = useForm<ProductVariantDto>({
    resolver: yupResolver(productVariantSchema),
    defaultValues: productVariant
      ? productVariantDbToDto(productVariant)
      : { ...defaultProductVariant, ref: randomRef() },
  });
  const { control, handleSubmit } = methods;

  const { fields, append, remove } = useFieldArray({
    name: "purchases",
    control,
  });

  const onSubmit: SubmitHandler<ProductVariantDto> = async (data) => {
    const res = await (id
      ? productVariantUpdate(product._id, id, data)
      : productVariantCreate(product._id, data));
    if (typeof onFinish === "function") onFinish(res);
  };

  return (
    <PopupForm
      title="Variantes"
      key={productVariant?._id || ""}
      onClose={() => {
        if (typeof onFinish === "function") onFinish();
      }}
      onSave={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputText label="Nom" name="name" control={control} />
          <InputText label="Référence" name="ref" control={control} />

          <InputNumber
            label="Prix TTC"
            name="price"
            precision={2}
            step={0.01}
            control={control}
          />

          <Switch
            label="Afficher dans le catalogue"
            name="isActive"
            control={control}
          />

          <div style={{ marginTop: "20px" }}>
            {fields.map((field, index) => {
              return (
                <Grid container key={field.id} alignItems="center" spacing={3}>
                  <Grid item xs={9}>
                    <SelectAsyncSearch
                      name={`purchases.${index}.item`}
                      label="Achat"
                      control={control}
                      find={purchaseFindAllForSelect}
                      findOne={purchaseFindOneForSelect}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputNumber
                      label="Quantité"
                      name={`purchases.${index}.quantity`}
                      precision={2}
                      step={0.01}
                      control={control}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    alignSelf="center"
                    container
                    justifyContent="center"
                  >
                    <Grid item>
                      <BtnDelete onClick={() => remove(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <MuiButton
              variant="contained"
              style={{ margin: "15px 0" }}
              onClick={() => append({ item: "", quantity: 1 })}
            >
              <AddIcon /> Ajouter un produit
            </MuiButton>
          </div>

          <Button
            sx={{ mt: "50px", mb: "50px" }}
            type="submit"
            label={id ? "Modifier" : "Ajouter"}
          />
        </form>
      </FormProvider>
    </PopupForm>
  );
};

export default ProductVariantForm;
