import axios from "../utils/axios";
import { UserDto, User, SelectOption, PaginateResult } from "../types";

export const userFindOne = async (id: string): Promise<User> => {
  return axios.get<User>(`/user/${id}`).then((res) => res?.data);
};

export const userCreate = async (user: UserDto): Promise<User> => {
  return axios.post("/user", user).then((res) => res?.data);
};

export const userUpdate = async (id: string, user: UserDto): Promise<User> => {
  return axios.put(`/user/${id}`, user).then((res) => res?.data);
};

export const userToSelectOption = (user: User): SelectOption => {
  return {
    label: `${user.firstName} ${user.lastName} - ${user.email}`,
    value: user._id,
  };
};

export const userFindAllForSelect = async (
  s: string = ""
): Promise<SelectOption[]> => {
  return axios
    .get<PaginateResult<User>>(`/user${s !== "" ? `?s=${s}` : ""}`)
    .then((res) => {
      return res.data.docs.map(userToSelectOption);
    });
};

export const userFindOneForSelect = async (
  id: string
): Promise<SelectOption> => {
  const user = await userFindOne(id);
  return userToSelectOption(user);
};
