import React, { useState, useEffect, useCallback, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";

import BtnDelete from "../../components/Element/BtnDelete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Zip } from "../../types";
import sort from "natural-compare";
import {
  zipFindDelivery,
  zipDeleteDelivery,
  zipSetDelivery,
  zipFindByCode,
} from "../../api";

const DeliveryForm = () => {
  const [zip, setZip] = useState<string>(""); // Input zip code
  const [zips, setZips] = useState<Zip[]>([]); // Select cities list
  const [city, setCity] = useState<string>(""); // Select: selected id of city
  const [cities, setCities] = useState<Zip[]>([]); // Active deliveries

  useEffect(() => {
    zipFindDelivery().then((res) => {
      setCities(res.sort((a, b) => sort(a.city, b.city)));
    });
  }, []);

  const handleDeleteCity = useCallback(
    (city: Zip) => {
      if (!window.confirm(`Supprimer ${city.city}?`)) return;
      zipDeleteDelivery(city._id).then((res) => {
        setCities(cities.filter((c) => c._id !== res._id));
        if (res.zip === zip) {
          const newZips = [...zips, res].sort((a, b) => sort(a.city, b.city));
          setZips(newZips);
          setCity(newZips[0]?._id || "");
        }
      });
    },
    [cities, zip, zips]
  );

  const actualIds = useMemo(() => {
    return cities.map((c) => c._id);
  }, [cities]);

  useEffect(() => {
    zipFindByCode(zip).then((res) => {
      const newZips = res
        .filter((r) => !actualIds.includes(r._id))
        .sort((a, b) => sort(a.city, b.city));
      setZips(newZips);
      setCity(newZips[0]?._id || "");
    });
  }, [zip, actualIds]);

  const handleSetCity = useCallback(() => {
    const actual = zips.find((z) => z._id === city);
    if (!actual) return;

    zipSetDelivery(actual._id).then((res) => {
      setCities([...cities, res].sort((a, b) => sort(a.city, b.city)));
      const newZips = zips.filter((z) => z._id !== actual._id);
      setZips(newZips);
      setCity(newZips[0]?._id || "");
    });
  }, [cities, zips, city]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={2}>
            <TextField
              id="zip"
              fullWidth
              label="Code postale"
              variant="outlined"
              onChange={(e) => setZip(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="city-label">Ville</InputLabel>
              {zips.length === 0 && (
                <Skeleton height={56} variant="rectangular" />
              )}
              {zips.length > 0 && (
                <Select
                  labelId="city-label"
                  id="city"
                  label="Ville"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                >
                  {zips.map((zip) => {
                    return (
                      <MenuItem key={zip._id} value={zip._id}>
                        {zip.city}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              type="button"
              disabled={zips.length === 0}
              variant="contained"
              onClick={handleSetCity}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h2>Communes livrés :</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ville</TableCell>
                <TableCell>Code postale</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(cities || []).map((city: Zip) => (
                <TableRow
                  key={city._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{city.city}</TableCell>
                  <TableCell>{city.zip}</TableCell>
                  <TableCell align="right">
                    <BtnDelete onClick={() => handleDeleteCity(city)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default DeliveryForm;
