import React, { useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { filterFindAll, filterDelete } from "../../api/filter";
import { Filter } from "../../types";
import FilterForm from "./FilterForm";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Fab } from "@mui/material";
import BtnDelete from "../../components/Element/BtnDelete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

export default function FilterList() {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [showFormFilter, setShowFormFilter] = useState<boolean>(false);
  const [editFilter, setEditFilter] = useState<Filter | null>(null);

  useEffect(() => {
    filterFindAll().then(setFilters);
  }, []);

  const deleteFilter = useCallback(async (filter: Filter) => {
    if (!window.confirm(`Supprimer le filtre ${filter.name} ?`)) return;
    await filterDelete(filter._id);
    setFilters((fs) => fs.filter((f) => f._id !== filter._id));
  }, []);

  return (
    <>
      <div style={{ float: "right", padding: "20px 0" }}>
        <Fab color="primary" onClick={() => setShowFormFilter(true)}>
          <AddIcon />
        </Fab>
      </div>
      <h1>Gestion des filtres</h1>
      {showFormFilter && (
        <FilterForm
          filter={editFilter === null ? undefined : editFilter}
          onFinish={(filter) => {
            setShowFormFilter(false);
            setEditFilter(null);
            if (filter !== null) {
              if (filters.map((f) => f._id).includes(filter._id)) {
                setFilters(
                  filters.map((f) => (f._id === filter._id ? filter : f))
                );
              } else {
                setFilters([...filters, filter]);
              }
            }
          }}
        />
      )}

      <TableContainer component={Paper}>
        <Table aria-label="Filtres">
          <TableHead>
            <TableRow>
              <TableCell>Nom interne</TableCell>
              <TableCell>Nom sur le site</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filters.map((filter: Filter) => (
              <TableRow
                key={filter._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{filter.name}</TableCell>
                <TableCell>{filter.label}</TableCell>
                <TableCell align="right">
                  <Fab
                    component={Link}
                    to={`/filter/${filter._id}/`}
                    color="primary"
                    size="small"
                    sx={{ mr: 1 }}
                  >
                    <FormatListBulletedIcon />
                  </Fab>

                  <Fab
                    color="primary"
                    sx={{ mr: 1 }}
                    size="small"
                    onClick={() => {
                      setEditFilter(filter);
                      setShowFormFilter(true);
                    }}
                  >
                    <EditIcon />
                  </Fab>
                  <BtnDelete
                    onClick={() => {
                      deleteFilter(filter);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
