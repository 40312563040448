import ObjectId from "bson-objectid";
import {
  ActionType,
  Action,
  ActionMedia,
  ActionMedias,
  ActionMove,
  Media,
  MediaState,
  Progress,
} from "./media.dto";

export const initialState: MediaState = {
  progress: {},
  total: 0,
  pc: 100,
  medias: [],
};

const eq = (a: Media, b: Media) =>
  new ObjectId(a._id).equals(new ObjectId(b._id));

const getPcTotal = (progress: Progress) =>
  Object.keys(progress).reduce(
    (acc, value) => [acc[0] + progress[value][0], acc[1] + progress[value][1]],
    [0, 0]
  );

const cleanProgress = (progress: Progress) =>
  Object.keys(progress).reduce((acc, id) => {
    if (progress[id][0] >= progress[id][1]) {
      return acc;
    }

    return {
      ...acc,
      [id]: progress[id],
    };
  }, {});

export default function reducer(state: MediaState, action: Action) {
  const { type } = action;
  switch (type) {
    case ActionType.SET:
      const {
        payload: { medias },
      } = action as ActionMedias;
      return {
        ...state,
        medias,
      };

    case ActionType.MOVE: {
      const {
        payload: { a, b },
      } = action as ActionMove;
      const va = state.medias[a];
      const vb = state.medias[b];
      return {
        ...state,
        medias: state.medias.map((v, id) => {
          if (id === a) return vb;
          if (id === b) return va;
          return v;
        }),
      };
    }
    case ActionType.PROGRESS: {
      const {
        payload: { media },
      } = action as ActionMedia;

      const progress = cleanProgress({
        ...state.progress,
        [media._id]: [Number(media.loaded), Number(media.size)],
      });

      const [sizes, total] = getPcTotal(progress);
      return {
        ...state,
        total,
        sizes,
        pc: sizes > 0 ? (sizes * 100) / total : 100,
        progress,
      };
    }

    case ActionType.ADD: {
      const {
        payload: { media },
      } = action as ActionMedia;
      const progress = cleanProgress({
        ...state.progress,
        [media._id]: [0, Number(media.size)],
      });

      const [sizes, total] = getPcTotal(progress);
      return {
        ...state,
        total,
        sizes,
        progress,
        medias: [...state.medias, media],
        pc: sizes > 0 ? (sizes * 100) / total : 100,
      };
    }

    case ActionType.UPDATE: {
      const {
        payload: { media },
      } = action as ActionMedia;
      return {
        ...state,
        medias: state.medias.map((file) => {
          if (eq(file, media)) {
            return { ...file, ...media };
          }
          return file;
        }),
      };
    }

    case ActionType.DELETE: {
      const {
        payload: { media },
      } = action as ActionMedia;
      return {
        ...state,
        medias: state.medias.filter((file) => !eq(file, media)),
      };
    }

    default:
      return state;
  }
}
