import React from "react";
import InputText from "../../components/Field/InputText";
import Address from "../../components/Field/Address";
import Button from "../../components/Field/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  ClickAndCollectDto,
  ClickAndCollect,
  clickAndCollectDbToDto,
} from "../../types";
import { clickAndCollectCreate, clickAndCollectUpdate } from "../../api";

type Props = {
  clickAndCollect?: ClickAndCollect;
  onFinish?: (clickAndCollect: ClickAndCollect) => any;
};

const ClickAndCollectForm = ({ clickAndCollect, onFinish }: Props) => {
  const { control, handleSubmit, setValue } = useForm<ClickAndCollectDto>({
    defaultValues: clickAndCollectDbToDto(clickAndCollect),
  });

  const onSubmit: SubmitHandler<ClickAndCollectDto> = async (data) => {
    const id = clickAndCollect?._id;
    if (id) {
      const res = await clickAndCollectUpdate(id, data);
      return typeof onFinish === "function" ? onFinish(res) : null;
    }
    const res = await clickAndCollectCreate(data);
    return typeof onFinish === "function" ? onFinish(res) : null;
  };

  return (
    <form key={clickAndCollect?._id || ""} onSubmit={handleSubmit(onSubmit)}>
      <InputText label="Nom" name="name" control={control} />
      <Address
        label="Adresse"
        name="address"
        disable={["firstName", "lastName", "phone"]}
        control={control}
        setValue={setValue}
      />

      <Button
        type="submit"
        label={clickAndCollect?._id ? "Modifier" : "Ajouter"}
      />
    </form>
  );
};

export default ClickAndCollectForm;
