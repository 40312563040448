import useProduct from "../../hooks/useProduct";
import { useParams, useNavigate } from "react-router-dom";
import Delete from "../../components/Form/Delete";

export default function ProductList() {
  const { id } = useParams();
  const { product, isLoading, isError } = useProduct(id);
  const navigate = useNavigate();

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !product) {
    return <span>Erreur</span>;
  }

  const onFinish = () => {
    navigate("../");
  };

  return (
    <>
      <h1>Supprimer {product.name}</h1>
      <Delete
        url={`/product/${product._id}`}
        name={product.name}
        onFinish={onFinish}
      />
    </>
  );
}
