import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScrollRef from "../../components/ScrollRef";
import useClickAndCollects from "../../hooks/useClickAndCollects";
import { ClickAndCollect } from "../../types";
import LinkEdit from "../../components/Element/LinkEdit";
import LinkDelete from "../../components/Element/LinkDelete";
import LinkAdd from "../../components/Element/LinkAdd";

export default function ClickAndCollectList() {
  const { clickAndCollects, size, setSize } = useClickAndCollects("");

  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/click-and-collect/add`} />
      </div>
      <h1>Liste des Click & Collect</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(clickAndCollects || []).map(
              (clickAndCollect: ClickAndCollect) => (
                <TableRow
                  key={clickAndCollect._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{clickAndCollect.name}</TableCell>
                  <TableCell align="right">
                    <LinkEdit
                      url={`/click-and-collect/update/${clickAndCollect._id}`}
                    />
                    <LinkDelete
                      url={`/click-and-collect/delete/${clickAndCollect._id}`}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ScrollRef load={() => setSize(size + 1)} />
    </>
  );
}
