import axios from "../utils/axios";
import { Zip } from "../types";
import mem from "mem";

const zipFindByCodeNoCache = async (code: string): Promise<Zip[]> => {
  if (typeof code !== "string" || code === "") return [];
  return axios.get(`/zip/${code}`).then((res) => res?.data);
};

const zipFindByCodeWithCache = mem(zipFindByCodeNoCache);

export const zipFindByCode = zipFindByCodeWithCache;

export const zipFindDelivery = async (): Promise<Zip[]> => {
  return axios.get(`/zip`).then((res) => res?.data);
};

export const zipSetDelivery = async (id: string): Promise<Zip> => {
  return axios.put(`/zip/${id}`).then((res) => res?.data);
};

export const zipDeleteDelivery = async (id: string): Promise<Zip> => {
  return axios.delete(`/zip/${id}`).then((res) => res?.data);
};

export const zipFindById = async (id: string): Promise<Zip> => {
  return axios.get<Zip>(`/city/${id}`).then((res) => res?.data);
};
