import axios from "../utils/axios";
import { Tva, TvaDto } from "../types";

export const tvaFindAll = async (): Promise<Tva[]> => {
  return axios.get(`/tva`).then((res) => res?.data);
};

export const tvaFindOne = async (id: string): Promise<Tva> => {
  return axios.get(`/tva/${id}`).then((res) => res?.data);
};

export const tvaCreate = async (tvaDto: TvaDto): Promise<Tva> => {
  return axios.post(`/tva`, tvaDto).then((res) => res?.data);
};

export const tvaUpdate = async (id: string, tvaDto: TvaDto): Promise<Tva> => {
  return axios.put(`/tva/${id}`, tvaDto).then((res) => res?.data);
};

export const tvaDelete = async (id: string): Promise<Tva> => {
  return axios.delete(`/tva/${id}`).then((res) => res?.data);
};
