import UserForm from "./UserForm";
import { useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { User } from "../../types";

export default function UserList() {
  const navigate = useNavigate();
  const snack = useSnack();

  const onFinish = (user: User) => {
    snack.success(`${user.firstName} a bien été créé.`);
    navigate("/user");
  };

  return (
    <>
      <h1>Créer un Utilisateur</h1>
      <UserForm onFinish={onFinish} />
    </>
  );
}
