import useSWR, { useSWRConfig } from "swr";
import { fetcherOne } from "../utils/axios";
import { Purchase } from "../types";

type ReturnType = {
  purchase: Purchase | null | undefined;
  isLoading: boolean;
  isError: Error | null;
  mutate: () => any;
};

export default function usePurchase(
  id: string | string[] | undefined
): ReturnType {
  const key = id ? `/purchase/${id}` : null;
  const { data, error } = useSWR(key, (url) => fetcherOne<Purchase>(url));
  const { mutate } = useSWRConfig();

  return {
    purchase: data,
    isLoading: !error && !data,
    isError: error,
    mutate: () => {
      mutate(key);
    },
  };
}
