import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import InputNumber from "../../../components/Field/InputNumber";
import Select from "../../../components/Field/Select";
import { Control, useFormContext, useWatch } from "react-hook-form";
import axios from "../../../utils/axios";
import { ProductVariant, SelectOption } from "../../../types";
import { productFindAllForSelect, productFindOneForSelect } from "../../../api";
import SelectAsyncSearch from "../../../components/Field/SelectAsyncSearch";

interface Props {
  control: Control<any>;
  name: string;
}

export default function Variant({ control, name }: Props): React.ReactElement {
  const [variantOptions, setVariantOptions] = useState<SelectOption[]>([]);
  const [variantQtt, setVariantQtt] = useState<Map<string, string>>(new Map());

  const product = useWatch({ control, name: `${name}.product` });
  const variant = useWatch({ control, name: `${name}.productVariant` });

  const { setValue } = useFormContext();

  useEffect(() => {
    if (!product) {
      setValue(`${name}.variant`, null);
      setVariantOptions([]);
      return;
    }

    axios
      .get<ProductVariant[]>(`/product/${product}/variant`)
      .then((res) => res.data)
      .then((docs) => {
        setVariantQtt(
          docs.reduce((acc, doc) => {
            acc.set(doc._id, `${doc.quantity}`);
            return acc;
          }, new Map())
        );
        setVariantOptions(
          docs.map((d) => ({
            label: d.name,
            value: d._id,
          }))
        );
        const hasActualVariant = docs.map((d) => d._id).includes(variant);
        if (docs.length > 0 && !hasActualVariant) {
          setValue(`${name}.productVariant`, docs[0]._id);
        }
      });
  }, [product, setValue, name, variant]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        <SelectAsyncSearch
          name={`${name}.product`}
          label={"Choisir le produit"}
          control={control}
          find={productFindAllForSelect}
          findOne={productFindOneForSelect}
        />
      </Grid>
      <Grid item xs={5}>
        {variantOptions.length === 0 && (
          <Skeleton sx={{ mt: 2 }} variant="rectangular" height={56} />
        )}
        {variantOptions.length !== 0 && (
          <Select
            name={`${name}.productVariant`}
            label={"Choisir la variante"}
            options={variantOptions}
            control={control}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <InputNumber
          label={`Quantité (x${variantQtt.get(variant)})`}
          control={control}
          name={`${name}.quantity`}
        />
      </Grid>
    </Grid>
  );
}

Variant.defaultProps = {};
