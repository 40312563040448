import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useController, Control } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import { nanoid } from "nanoid";

type Props = {
  control: Control<any>;
  name: string;
  label: string;
};

export default function InputPassword({
  name,
  control,
  ...props
}: Props): React.ReactElement {
  const [id] = useState(`${name}-${nanoid()}`);
  const [show, setShow] = useState<boolean>(false);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl
      error={!!error}
      sx={{ mt: 2, width: "100%" }}
      variant="outlined"
    >
      <InputLabel htmlFor={id}>{props.label}</InputLabel>
      <OutlinedInput
        id={id}
        type={show ? "text" : "password"}
        fullWidth
        {...props}
        {...field}
        value={field.value || ""}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow((s) => !s)}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}
