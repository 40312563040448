import { useParams, useNavigate } from "react-router-dom";
import useOrder from "../../hooks/useOrder";
import OrderForm from "./OrderForm";
import { useSnack } from "../../contexts/SnackContext";

export default function OrderUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { order, isLoading, isError, mutate } = useOrder(id);
  const snack = useSnack();

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !order) {
    return <span>Une erreur est survenue</span>;
  }

  const onFinish = () => {
    snack.success(`La commande a bien été modifiée.`);
    mutate();
    navigate("../");
  };

  return (
    <>
      <h1>Commande #{order.number}</h1>
      <OrderForm order={order} onFinish={onFinish} />
    </>
  );
}
