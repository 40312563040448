import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import prettyBytes from "pretty-bytes";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Sortable from "sortablejs";
import { Close as DeleteIcon } from "@mui/icons-material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import Loader from "../../Element/Loader";
import { MediaState, Media } from "./media.dto";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const { REACT_APP_S3_URL } = process.env;

const Input = styled.input`
  border: 1px solid #eeeeee;
  line-height: 30px;
  padding: 4px 10px;
  font-size: 1rem;
  width: 300px;
`;

const SquareImage = styled.div<{ crop: boolean; img: string }>`
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid #eeeeee;
  overflow: hidden;
  background-image: url(${({ img }) => img});
  background-size: ${({ crop }) => (crop ? "cover" : "contain")};
  background-repeat: no-repeat;
  background-position: center center;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  .sortable-gost {
    opacity: 0.3;
  }

  .draggable {
    cursor: grab;
    margin-right: 5px;
    display: flex;
    &:hover svg {
      color: #1976d2;
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    margin: 3px 0;
    background-color: #ffffff;

    border-color: #eeeeee;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;

    div.media-thumb {
      position: relative;
      margin-right: 15px;
    }

    div:last-child {
      padding-left: auto;
      display: flex;
      align-items: center;
    }

    .loading {
      position: relative;
    }

    .link-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      display: inline;
      margin: 0;
      padding: 0;
    }

    button.delete,
    a.show {
      display: inline-block;
      padding: 0 10px;
      margin: 0;
      height: 32px;

      svg {
        display: inline-block;
        width: 32px;
        height: 32px;
        padding: 0;
        margin: 0;
      }

      &.delete:hover {
        color: red;
      }

      &.show:hover {
        color: #1976d2;
      }
    }

    &:hover {
      background-color: #eee;
      .square-image {
        border-color: white;
      }
    }
  }
`;

type Props = {
  state: MediaState;
  del: (media: Media) => any;
  readOnly: boolean;
  move: (a: number, b: number) => any;
  update: (media: Media) => any;
};

const getUrl = (p: string): string => {
  return new URL(p, REACT_APP_S3_URL || "").href;
};

const Medias = ({ state, del, readOnly, move, update }: Props) => {
  const ref = useRef<HTMLUListElement>(null);
  useEffect(() => {
    const actualRef = ref.current;
    if (actualRef) {
      const sortable = Sortable.create(ref.current, {
        draggable: ".sortable-draggable",
        handle: ".draggable",
        animation: 250,
        ghostClass: "sortable-gost",
        onEnd: (e) => {
          if (typeof e.oldIndex !== "number") {
            return;
          }
          if (typeof e.newIndex !== "number") {
            return;
          }
          move(e.oldIndex, e.newIndex);
        },
      });

      return () => {
        if (actualRef) {
          sortable.destroy();
        }
      };
    }

    return () => true;
  }, [ref, move]);

  return (
    <div>
      <List ref={ref}>
        {state.medias.map((media) => (
          <li key={media._id} className="sortable-draggable">
            {state.medias.length > 1 && (
              <span className="draggable">
                <DragIndicatorIcon />
              </span>
            )}
            {media.mimetype &&
              media.mimetype.match(/^image\//) &&
              media.path &&
              REACT_APP_S3_URL && (
                <div className="media-thumb">
                  <SquareImage
                    className="square-image"
                    img={getUrl(media.path)}
                    crop={!!media.crop}
                  />
                </div>
              )}
            <div style={{ width: "auto", marginRight: "20px" }}>
              {!media.path && media.filename}
              {media.path && (
                <>
                  {!readOnly && (
                    <Input
                      type="text"
                      onChange={(e) =>
                        update({ ...media, alt: e.target.value })
                      }
                      value={media.alt || media.filename}
                    />
                  )}
                  {readOnly && (
                    <a
                      href={getUrl(media.path)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {media.filename}
                    </a>
                  )}
                </>
              )}
              {media.size && <>&nbsp; ({prettyBytes(media.size)})</>}
            </div>
            {!readOnly && media.mimetype && media.mimetype.match(/^image\//) && (
              <div style={{ marginRight: "auto" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!media.crop}
                        onChange={(e) => {
                          update({ ...media, crop: !e.target.checked });
                        }}
                      />
                    }
                    label="Photo détouré"
                  />
                </FormGroup>
              </div>
            )}
            {!readOnly && (
              <div>
                {!media.finish && (
                  <span className="loading">
                    <Loader inline icon size={30} />
                  </span>
                )}
                <a
                  className="show"
                  href={getUrl(media.path)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VisibilityIcon />
                </a>

                <button
                  className="delete link-button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      window.confirm(
                        `Supprimer ${media.alt || media.filename} ?`
                      )
                    ) {
                      del(media);
                    }
                  }}
                  title="Supprimer la pièce jointe"
                >
                  <DeleteIcon />
                </button>
              </div>
            )}
          </li>
        ))}
      </List>
    </div>
  );
};

export default Medias;
