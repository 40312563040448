import { useRef, useEffect, useCallback } from "react";

type Props = {
  size?: number | undefined;
  load: () => any;
};

export default function ScrollRef({ load }: Props) {
  const ref = useRef<null | HTMLSpanElement>(null);
  const oldTop = useRef<number>(0);

  let callback = useCallback<IntersectionObserverCallback>(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.1) {
          const top = ref.current?.offsetTop || 0;
          if (oldTop.current === top) return;
          oldTop.current = top;
          load();
        }
      });
    },
    [load]
  );

  useEffect(() => {
    if (typeof IntersectionObserver === "undefined") return;

    const el = ref.current;
    if (!el) return;

    let observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: "300px",
      threshold: 0.1,
    });

    observer.observe(el);

    return () => {
      if (el !== null) {
        observer.unobserve(el);
      }
    };
  }, [ref, callback]);

  if (typeof IntersectionObserver === "undefined") return <span />;

  return <span ref={ref} />;
}
