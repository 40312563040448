import React from "react";
import Grid from "@mui/material/Grid";
import LoaderSpinner from "./LoaderPure";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const color = "#171c35";

const Spinner = styled(LoaderSpinner)`
  align-self: center;
  transform: translateY(-50px);
`;

const IconSpinner = styled(LoaderSpinner)`
  display: inline-block;
  margin: 0 0 0 5px;
`;

type Props = {
  inline: boolean;
  icon: boolean;
  size: number;
};

const Loader = ({ inline = false, icon, size = 80 }: Props) => {
  if (icon) {
    return <IconSpinner color={color} height={size / 3} width={size} />;
  }

  if (inline) {
    return (
      <Grid container justifyContent="center">
        <Grid item alignSelf="center">
          <LoaderSpinner color={color} height={80} width={80} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Container>
      <Spinner color={color} width={300} height={300} />
    </Container>
  );
};

export default Loader;
