import axios from "../utils/axios";
import { Filter, FilterDto } from "../types";

export const filterFindAll = async (): Promise<Filter[]> => {
  return axios.get<Filter[]>(`/filter`).then((res) => res?.data);
};

export const filterFindOne = async (id: string): Promise<Filter> => {
  return axios.get<Filter>(`/filter/${id}`).then((res) => res?.data);
};

export const filterCreate = async (filter: FilterDto): Promise<Filter> => {
  return axios.post(`/filter`, filter).then((res) => res?.data);
};

export const filterUpdate = async (
  id: string,
  filter: FilterDto
): Promise<Filter> => {
  return axios.put(`/filter/${id}`, filter).then((res) => res?.data);
};

export const filterDelete = async (id: string): Promise<Filter> => {
  return axios.delete(`/filter/${id}`).then((res) => res?.data);
};
