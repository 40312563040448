import axios from "../utils/axios";
import { ProductVariantDto, ProductVariant } from "../types";

export const productVariantFindAll = async (
  productId: string
): Promise<ProductVariant[]> => {
  return axios
    .get<ProductVariant[]>(`/product/${productId}/variant`)
    .then((res) => res?.data || []);
};

export const productVariantFindOne = async (
  productId: string,
  id: string
): Promise<ProductVariant | null> => {
  return axios
    .get<ProductVariant | null>(`/product/${productId}/variant/${id}`)
    .then((res) => res?.data || null);
};

export const productVariantCreate = async (
  productId: string,
  productVariant: ProductVariantDto
): Promise<ProductVariant> => {
  return axios
    .post(`/product/${productId}/variant`, productVariant)
    .then((res) => res?.data);
};

export const productVariantUpdate = async (
  productId: string,
  id: string,
  productVariant: ProductVariantDto
): Promise<ProductVariant> => {
  return axios
    .put(`/product/${productId}/variant/${id}`, productVariant)
    .then((res) => res?.data);
};

export const productVariantDelete = async (
  productId: string,
  id: string
): Promise<ProductVariant> => {
  return axios
    .delete(`/product/${productId}/variant/${id}`)
    .then((res) => res?.data);
};
