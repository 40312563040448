import React from "react";
import { Route } from "react-router-dom";
import PromoCodeList from "./PromoCodeList";
import PromoCodeCreate from "./PromoCodeCreate";
import PromoCodeUpdate from "./PromoCodeUpdate";
import PromoCodeDelete from "./PromoCodeDelete";
import PromoCodeOrderList from "./PromoCodeOrderList";

export default (
  <Route path="promo-code">
    <Route index element={<PromoCodeList />} />
    <Route path="add" element={<PromoCodeCreate />} />
    <Route path="update/:id" element={<PromoCodeUpdate />} />
    <Route path="delete/:id" element={<PromoCodeDelete />} />
    <Route path="order/:id" element={<PromoCodeOrderList />} />
  </Route>
);
