import { useEffect, useState, useRef } from "react";
import InputDate from "../components/Field/InputDate";
import { subMonths, format, startOfDay, endOfDay } from "date-fns";
import { useForm } from "react-hook-form";
import { stat, StatDto, StatResponse } from "../api";
import fr from "date-fns/locale/fr";
import price from "../utils/price";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const defaultEnd = new Date();
const defaultStart = subMonths(defaultEnd, 1);

export default function Dashboard() {
  const [stats, setStats] = useState<StatResponse>();
  const { control, watch } = useForm<StatDto>({
    defaultValues: {
      start: defaultStart,
      end: defaultEnd,
    },
  });

  const start = startOfDay(watch("start"));
  const end = endOfDay(watch("end"));

  const oldStart = useRef<string>();
  const oldEnd = useRef<string>();
  useEffect(() => {
    if (!start) return;
    if (!end) return;
    /*
    if (
      oldStart.current === start.toString() &&
      oldEnd.current === end.toString()
    )
      return;
     */
    oldStart.current = start.toString();
    oldEnd.current = end.toString();
    stat({ start, end }).then(setStats);
  }, [start, end]);

  return (
    <>
      <h2>Stats</h2>

      <div style={{ margin: "0 50px" }}>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <InputDate name="start" label="Début" control={control} />
          </div>
          <div style={{ marginRight: "20px" }}>
            <InputDate name="end" label="Fin" control={control} />
          </div>
        </div>

        <div>
          <div>
            <h2 style={{ margin: 0 }}>Infos</h2>
            <ul>
              <li>
                Début le{" "}
                {format(start, "eeee d MMMM yyyy HH:mm", { locale: fr })}
              </li>
              <li>
                Fin le {format(end, "eeee d MMMM yyyy HH:mm", { locale: fr })}
              </li>
              <li>Nombre de factures: {stats?.nb}</li>
              <li>HT : {stats?.ttc && price(stats?.ttc / 100)}</li>
              <li>TVA : {stats?.tva && price(stats?.tva / 100)}</li>
              <li>TTC : {stats?.ht && price(stats?.ht / 100)}</li>
            </ul>
          </div>

          <div>
            <h2 style={{ margin: "0 0 10px" }}>Factures</h2>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Facture</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Commande</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(stats?.invoiceNumbers || []).map(
                    ({ email, number, invoiceOn, invoiceNumber, _id }) => {
                      return (
                        <TableRow
                          key={_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{email}</TableCell>
                          <TableCell>
                            <Link
                              to={`/invoice/${_id}/show`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {invoiceNumber}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {format(
                              new Date(invoiceOn),
                              "eeee d MMMM yyyy HH:mm",
                              {
                                locale: fr,
                              }
                            )}
                          </TableCell>
                          <TableCell>
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              to={`/order/${_id}/show`}
                            >
                              {number}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
