import useUser from "../../hooks/useUser";
import { useParams, useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { User } from "../../types";
import UserForm from "./UserForm";

export default function UserList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, isLoading, isError, mutate } = useUser(id);
  const snack = useSnack();

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !user) {
    return <span>Erreur</span>;
  }

  const onFinish = (user: User) => {
    snack.success(`${user.firstName} a bien été modifié.`);
    mutate();
    navigate("../");
  };

  return (
    <>
      <h1>
        {user.firstName} {user.lastName}
      </h1>
      <UserForm user={user} onFinish={onFinish} />
    </>
  );
}
