import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Select from "../../../components/Field/Select";
import InputDate from "../../../components/Field/InputDate";
import { Control } from "react-hook-form";
import { SelectOption, deliveryDatePeriodOptions } from "../../../types";
import { clickAndCollectFindAllForSelect } from "../../../api";

interface Props {
  control: Control<any>;
}

export default function Delivery({ control }: Props): React.ReactElement {
  const [clicks, setClicks] = useState<SelectOption[]>([]);

  useEffect(() => {
    clickAndCollectFindAllForSelect().then((res) => {
      setClicks([
        {
          label: "A domicile",
          value: "home",
        },
        ...res,
      ]);
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {clicks.length === 0 && (
          <Skeleton sx={{ mt: 2 }} variant="rectangular" height={56} />
        )}
        {clicks.length > 0 && (
          <Select
            name={`deliveryDate.clickAndCollect`}
            label={"Lieu de livraison"}
            control={control}
            options={clicks}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <InputDate
          label="Date de livraison"
          control={control}
          name="deliveryDate.date"
        />
      </Grid>
      <Grid item xs={2}>
        <Select
          name={`deliveryDate.period`}
          label={"Période"}
          control={control}
          options={deliveryDatePeriodOptions}
        />
      </Grid>
    </Grid>
  );
}
