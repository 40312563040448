import { useState } from "react";
import { TextField } from "@mui/material";
import { useController, Control } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import { nanoid } from "nanoid";

type Props = {
  control: Control<any>;
  disabled?: boolean;
  label: string;
  name: string;
  maxLength?: number;
  type?: "text" | "email";
};

function InputText({
  control,
  disabled,
  label,
  maxLength,
  name,
  type,
}: Props): React.ReactElement {
  const [id] = useState(nanoid());
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <TextField
        id={`${name}-${id}`}
        fullWidth
        sx={{ mt: 2 }}
        error={!!error}
        variant="outlined"
        autoComplete={id}
        disabled={disabled}
        type={type}
        label={label}
        inputProps={{ maxLength }}
        {...field}
        value={field.value || ""}
        inputRef={ref}
      />
      <ErrorMessage name={name} control={control} />
    </>
  );
}

InputText.defaultProps = {
  type: "text",
  disabled: false,
};

export default InputText;
