import { Dispatch } from "react";

import { SnackContextType } from "../../../contexts/SnackContext";
import ObjectId from "bson-objectid";
import isArray from "lodash/isArray";
import axios from "../../../utils/axios";
import { ActionType, Media } from "./media.dto";

export const initMediaValue = (value: Media | Media[]): Media[] => {
  if (!value) {
    return [];
  }

  if (isArray(value)) {
    return value.map((v) => {
      const crop = typeof v.crop === "boolean" ? v.crop : true;
      return { ...v, finish: true, crop };
    });
  }

  const crop = typeof value.crop === "boolean" ? value.crop : true;
  return [{ ...value, crop, finish: true }];
};

export const add = (
  file: File,
  dispatch: Dispatch<any>,
  extensions: string[],
  snack: SnackContextType
) => {
  if (isArray(extensions) && extensions.length > 0) {
    const [extension] = file.name.toLowerCase().split(".").reverse();
    if (!extensions.includes(extension)) {
      return snack.error(
        `Impossible d'envoyer le fichier "${file.name}": extension ${extension} non autorisée.`
      );
    }
  }

  const media = {
    _id: new ObjectId().toString(),
    finish: false,
    ...file,
  };
  dispatch({ type: ActionType.ADD, payload: { media } });

  const form = new FormData();
  form.append("file", file);

  const onUploadProgress = ({ loaded }: { loaded: number }) => {
    dispatch({
      type: ActionType.PROGRESS,
      payload: {
        media: {
          loaded: loaded,
          size: media.size,
          _id: media._id,
        },
      },
    });
  };

  axios
    .post("/media", form, { onUploadProgress })
    .then((res) => res?.data)
    .then((res) => {
      setTimeout(() => {
        dispatch({
          type: ActionType.PROGRESS,
          payload: {
            media: {
              loaded: media.size,
              size: media.size,
              _id: media._id,
            },
          },
        });

        dispatch({
          type: ActionType.UPDATE,
          payload: {
            media: {
              ...media,
              ...res,
              finish: true,
            },
          },
        });
      }, 500);
    });
};

export const del = (media: Media, dispatch: Dispatch<any>) => {
  dispatch({
    type: ActionType.UPDATE,
    payload: { media: { ...media, finish: false } },
  });

  return setTimeout(() => {
    dispatch({
      type: ActionType.DELETE,
      payload: {
        media,
      },
    });
  }, 500);
};
