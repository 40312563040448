export type Tva = {
  _id: string;
  name: string;
  value: number;
};

export type TvaDto = {
  name: string;
  value: number;
};

export const defaultTva: TvaDto = {
  name: "",
  value: 20,
};

export const tvaDbToDto = (tva?: Tva | null): TvaDto => {
  if (!tva) return defaultTva;
  return {
    name: tva?.name,
    value: tva?.value,
  };
};
