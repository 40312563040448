import { Add as AddIcon } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";

type Props = {
  url: string;
  label?: string;
};

export default function LinkAdd({ url, label = "Ajouter" }: Props) {
  return (
    <Fab
      component={Link}
      to={url}
      sx={{ mr: 1 }}
      color="primary"
      size="large"
      aria-label={label}
    >
      <AddIcon />
    </Fab>
  );
}
