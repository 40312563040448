import React from "react";
import InputText from "../../components/Field/InputText";
import Address from "../../components/Field/Address";
import Button from "../../components/Field/Button";
import Switch from "../../components/Field/Switch";
import Checkboxes from "../../components/Field/Checkboxes";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  UserDto,
  User,
  userDbToDto,
  userRolesList,
  userSchema,
} from "../../types";
import { userCreate, userUpdate } from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  user?: User;
  onFinish?: (user: User) => any;
};

const UserForm = ({ user, onFinish }: Props) => {
  const { control, handleSubmit, setValue, watch } = useForm<UserDto>({
    defaultValues: userDbToDto(user),
    resolver: yupResolver(userSchema),
  });

  const onSubmit: SubmitHandler<UserDto> = async (data) => {
    if (user?._id) {
      const res = await userUpdate(user._id, data);
      if (onFinish) {
        onFinish(res);
      }
      return;
    }
    const res = await userCreate(data);
    if (onFinish) {
      onFinish(res);
    }
  };

  const otherInvoiceAddress = watch("otherInvoiceAddress");

  return (
    <form key={user?._id || ""} onSubmit={handleSubmit(onSubmit)}>
      <InputText label="Nom" name="lastName" control={control} />
      <InputText label="Prénom" name="firstName" control={control} />
      <InputText label="Email" name="email" control={control} />

      <div style={{ display: "flex" }}>
        <Address
          label="Adresse de livraison"
          name="deliveryAddress"
          control={control}
          setValue={setValue}
        />
        {otherInvoiceAddress && (
          <Address
            label="Adresse de livraison"
            name="deliveryAddress"
            control={control}
            setValue={setValue}
          />
        )}
      </div>

      <Switch
        label="Utiliser une adresse de facturation différente"
        name="otherInvoiceAddress"
        control={control}
      />

      <Switch
        label="Accepte les mentions légales"
        name="acceptMentions"
        control={control}
      />

      <Switch
        label="Inscrit à la newsletter"
        name="acceptNewsletter"
        control={control}
      />

      <Checkboxes
        id="roles"
        label="Droits de l'utilisateur"
        name="roles"
        choices={userRolesList}
        control={control}
      />

      <Button type="submit" label={user?._id ? "Modifier" : "Ajouter"} />
    </form>
  );
};

export default UserForm;
