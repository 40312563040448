import * as yup from "yup";

export enum PurchaseUnity {
  "G" = "g",
  "Kg" = "kg",
  "Dozen" = "dozen",
  "Unity" = "unity",
}

export const purchaseUnityList = [
  { label: "Unité(s)", value: PurchaseUnity.Unity },
  { label: "Gramme(s)", value: PurchaseUnity.G },
  { label: "Kg", value: PurchaseUnity.Kg },
  { label: "Douzaine(s)", value: PurchaseUnity.Dozen },
];

export const purchaseUnityListIndex = new Map();
purchaseUnityList.forEach((el) => {
  purchaseUnityListIndex.set(el.value, el.label);
});

export type Purchase = {
  _id: string;
  name: string;
  ref: string;
  supplier: {
    _id: string;
    name: string;
  };
  unity: PurchaseUnity;
  createOn: Date;
  search: string;
};

export type PurchaseDto = {
  name: string;
  ref: string;
  supplier: string;
  unity: PurchaseUnity;
};

export const defaultPurchase: PurchaseDto = {
  name: "",
  ref: "",
  supplier: "",
  unity: PurchaseUnity.Unity,
};

export const purchaseDbToDto = (
  purchase?: Partial<Purchase> | null
): PurchaseDto => {
  if (!purchase) return defaultPurchase;

  const p = Object.assign(defaultPurchase, purchase);

  return {
    name: p?.name,
    ref: p?.ref,
    supplier: p?.supplier?._id,
    unity: p?.unity,
  };
};

export const purchaseSchema = yup.object({
  name: yup.string().required(),
  ref: yup.string().required(),
  supplier: yup.string().required(),
  unity: yup.string().oneOf(Object.values(PurchaseUnity)).required(),
});
