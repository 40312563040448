import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Control, useController } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  id: string;
  name: string;
  label: string;
  options: OptionType[];
  control: Control<any>;
  row?: boolean;
}

export default function Select({
  control,
  label,
  options,
  name,
  row,
  id,
}: Props): React.ReactElement {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl component="fieldset" error={!!error} sx={{ mt: 2, width: '100%' }}>
      <FormLabel component="legend" htmlFor={id}>
        {label}
      </FormLabel>

      <RadioGroup row={row} aria-label={label} id={id} {...field} value={field.value || ''}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>

      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}

Select.defaultProps = {
  row: true,
};
