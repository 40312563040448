import axios from "../utils/axios";
import { Configuration } from "../types";

export const configurationFindAll = async (): Promise<Configuration> => {
  return axios.get(`/configuration`).then((res) => res?.data);
};

export const configurationFindOne = async (key: string): Promise<any> => {
  return axios.get(`/configuration/${key}`).then((res) => res?.data);
};

export const configurationUpdate = async (
  configuration: Configuration
): Promise<Configuration> => {
  return axios.post(`/configuration`, configuration).then((res) => res?.data);
};
