import axios from "../utils/axios";
import { FilterValue, FilterValueDto } from "../types";

export const filterValueFindAll = async (
  filterId: string
): Promise<FilterValue[]> => {
  return axios
    .get<FilterValue[]>(`/filter/${filterId}/value`)
    .then((res) => res?.data);
};

export const filterValueCreate = async (
  filterId: string,
  filterValue: FilterValueDto
): Promise<FilterValue> => {
  return axios
    .post(`/filter/${filterId}/value`, filterValue)
    .then((res) => res?.data);
};

export const filterValueUpdate = async (
  filterId: string,
  id: string,
  filter: FilterValueDto
): Promise<FilterValue> => {
  return axios
    .put(`/filter/${filterId}/value/${id}`, filter)
    .then((res) => res?.data);
};

export const filterValueDelete = async (
  filterId: string,
  id: string
): Promise<FilterValue> => {
  return axios
    .delete(`/filter/${filterId}/value/${id}`)
    .then((res) => res?.data);
};
