import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";

type Props = {
  url: string;
  label?: string;
  icon: React.ReactElement;
  target?: "_blank";
};

export default function LinkCustom({
  url,
  label = "Modifier",
  icon,
  target,
}: Props) {
  return (
    <Fab
      component={Link}
      to={url}
      sx={{ mr: 1 }}
      color="primary"
      size="small"
      aria-label={label}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}
    >
      {icon}
    </Fab>
  );
}
