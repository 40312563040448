import { Address, defaultAddress, AddressDto } from ".";

export type ClickAndCollect = {
  _id: string;
  name: string;
  address: Address;
};

export type ClickAndCollectDto = {
  _id?: string;
  name: string;
  address: AddressDto;
};

export const defaultClickAndCollect: ClickAndCollectDto = {
  name: "",
  address: defaultAddress,
};

export const clickAndCollectDbToDto = (
  clickAndCollect?: ClickAndCollect | null
): ClickAndCollectDto => {
  if (!clickAndCollect) return defaultClickAndCollect;
  return {
    name: clickAndCollect?.name,
    address: {
      ...clickAndCollect?.address,
      city: clickAndCollect.address.city._id,
    },
  };
};
