import axios from "../utils/axios";
import { ClickAndCollect, ClickAndCollectDto, SelectOption } from "../types";

export const clickAndCollectFindAll = async (): Promise<ClickAndCollect[]> => {
  return axios.get(`/click-and-collect`).then((res) => res?.data.docs);
};

export const clickAndCollectFindOne = async (
  id: string
): Promise<ClickAndCollect> => {
  return axios.get(`/click-and-collect/${id}`).then((res) => res?.data);
};

export const clickAndCollectCreate = async (
  clickAndCollect: ClickAndCollectDto
): Promise<ClickAndCollect> => {
  return axios
    .post(`/click-and-collect`, clickAndCollect)
    .then((res) => res?.data);
};

export const clickAndCollectUpdate = async (
  id: string,
  dto: ClickAndCollectDto
): Promise<ClickAndCollect> => {
  return axios
    .put<ClickAndCollect>(`/click-and-collect/${id}`, dto)
    .then((res) => res?.data);
};

export const clickAndCollectDelete = async (
  clickAndCollect: ClickAndCollectDto
): Promise<ClickAndCollect> => {
  return axios
    .delete(`/click-and-collect/${clickAndCollect._id}`)
    .then((res) => res?.data);
};

export const clickAndCollectToSelect = (
  clickAndCollect: ClickAndCollect
): SelectOption => {
  return {
    value: clickAndCollect._id,
    label: clickAndCollect.name,
  };
};

export const clickAndCollectFindAllForSelect = async (): Promise<
  SelectOption[]
> => {
  return clickAndCollectFindAll().then((res) =>
    res.map(clickAndCollectToSelect)
  );
};
