export type Progress = {
  [index: string]: [number, number];
};

export type Media = {
  _id: string;
  path: string;
  size: number;
  type: string;
  loaded: boolean;
  finish?: boolean;
  filename: string;
  crop: boolean;
  name: string;
  alt: string;
  mimetype: string;
  image?: {
    width: number;
    height: number;
  };
};

export function cleanMedia(media: Media) {
  return {
    _id: media._id,
    path: media.path,
    size: media.size,
    filename: media.filename,
    alt: media.alt || media.filename,
    crop: typeof media.crop === "boolean" ? media.crop : true,
    name: media.name,
    mimetype: media.mimetype,
    image: media.image,
  };
}

export enum ActionType {
  SET = "SET",
  PROGRESS = "PROGRESS",
  ADD = "ADD",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  MOVE = "MOVE",
  ALT = "ALT",
}

export type ActionMedias = {
  type: ActionType.SET;
  payload: {
    medias: Media[];
  };
};

export type ActionMedia = {
  type:
    | ActionType.DELETE
    | ActionType.UPDATE
    | ActionType.ADD
    | ActionType.PROGRESS
    | ActionType.ALT;
  payload: {
    media: Media;
  };
};

export type ActionMove = {
  type: ActionType.MOVE;
  payload: {
    a: number;
    b: number;
  };
};

export type Action = ActionMedias | ActionMedia | ActionMove;

export interface MediaState {
  total: number;
  pc: number;
  medias: Media[];
  progress: Progress;
}
