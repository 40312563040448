import React from "react";

import { Grid, Button as MuiButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import InputText from "../../../components/Field/InputText";
import { useFieldArray, Control } from "react-hook-form";
import BtnDelete from "../../../components/Element/BtnDelete";

type Props = {
  control: Control<any>;
  name: string;
};

export default function PromoCodes({ name, control }: Props) {
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  return (
    <div style={{ marginTop: "20px" }}>
      <h2>Code promo</h2>
      {fields.map((field, index) => {
        return (
          <Grid container key={field.id} alignItems="center">
            <Grid item xs={11}>
              <InputText
                label="Code"
                name={`${name}.${index}.code`}
                control={control}
              />
            </Grid>
            <Grid
              item
              xs={1}
              alignSelf="center"
              container
              justifyContent="center"
            >
              <Grid item>
                <BtnDelete onClick={() => remove(index)} />
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      <MuiButton
        variant="contained"
        style={{ margin: "15px 0" }}
        onClick={() => append({ code: "" })}
      >
        <AddIcon /> Ajouter un code promo
      </MuiButton>
    </div>
  );
}
