import { ContentCopy as CopyIcon } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";

type Props = {
  url: string;
  label?: string;
};

export default function LinkCopy({ url, label = "Copier" }: Props) {
  return (
    <Fab
      component={Link}
      to={url}
      sx={{ mr: 1 }}
      color="primary"
      size="small"
      aria-label={label}
    >
      <CopyIcon />
    </Fab>
  );
}
