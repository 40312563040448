import React from "react";
import Grid from "@mui/material/Grid";
import InputDate from "../../components/Field/InputDate";
import InputDateTime from "../../components/Field/InputDateTime";
import Button from "../../components/Field/Button";
import Radios from "../../components/Field/Radios";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  DeliveryDate,
  DeliveryDateDto,
  deliveryDateDbToDto,
  ClickAndCollect,
  DeliveryDatePeriod,
} from "../../types";
import { deliveryDateCreate, deliveryDateUpdate } from "../../api";

type Props = {
  deliveryDate?: DeliveryDate;
  clickAndCollect?: ClickAndCollect | null;
  onFinish?: (deliveryDate: DeliveryDate) => any;
};

const DeliveryDateForm = ({
  deliveryDate,
  clickAndCollect,
  onFinish,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<DeliveryDateDto>({
    defaultValues: deliveryDateDbToDto(deliveryDate || null),
  });

  const onSubmit: SubmitHandler<DeliveryDateDto> = async (data) => {
    const click = clickAndCollect ? clickAndCollect._id : null;
    if (deliveryDate?._id) {
      const res = await deliveryDateUpdate(deliveryDate?._id, {
        ...data,
        clickAndCollect: click,
      });
      if (onFinish) onFinish(res);
      return reset(data);
    }

    const res = await deliveryDateCreate({ ...data, clickAndCollect: click });
    if (onFinish) onFinish(res);
    return;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <InputDate label="Date de livraison" name="date" control={control} />
          <Radios
            id="period"
            label="Période"
            name="period"
            options={[
              { label: "10h -> 13h", value: DeliveryDatePeriod.AM },
              { label: "17h -> 21h", value: DeliveryDatePeriod.PM },
            ]}
            control={control}
          />
        </Grid>
        <Grid item>
          <InputDateTime
            label="Dernière commande"
            name="lastCommand"
            control={control}
          />
          <InputDateTime
            label="Dernière commande produits ultra frais"
            name="lastSuperFreshCommand"
            control={control}
          />
        </Grid>
        <Grid item>
          <Button
            sx={{ mt: "25px" }}
            type="submit"
            disabled={isSubmitting}
            label={deliveryDate?._id ? "Modifier" : "Ajouter"}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default DeliveryDateForm;
