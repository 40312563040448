import { Button as MuiButton } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  variant?: 'outlined' | 'text' | 'contained' | undefined;
  color?: 'primary' | 'secondary' | 'error' | 'success';
  label: string;
  disabled?: boolean;
  sx?: SxProps;
  onClick?: () => void;
  fullWidth?: boolean;
};

export default function Button({
  onClick,
  color,
  variant,
  type,
  disabled,
  label,
  sx,
  fullWidth,
}: Props): React.ReactElement {
  return (
    <MuiButton
      onClick={onClick}
      sx={{ mt: '5px', mb: '5px', ...sx }}
      fullWidth={fullWidth}
      variant={variant}
      disabled={disabled}
      type={type}
      color={color}
    >
      {label}
    </MuiButton>
  );
}

Button.defaultProps = {
  type: 'button',
  variant: 'contained',
  color: 'primary',
  disabled: false,
  sx: {},
  onClick: () => true,
  fullWidth: true,
};
