import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Routes from "./Routes";
import { SnackbarProvider } from "notistack";
import { createGlobalStyle } from "styled-components";

import type {} from "@mui/lab/AdapterDateFns";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import frLocale from "date-fns/locale/fr";

import UserContext from "./contexts/UserContext";
import SnackContext from "./contexts/SnackContext";

import "./components/Field/InputNumber.style.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Roboto;
  }
`;

const themeOptions = createTheme({
  palette: {
    primary: {
      main: "#171D35",
    },
    secondary: {
      main: "#f50057",
    },
    text: {
      secondary: "rgba(236,41,41,0.54)",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <SnackbarProvider
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <GlobalStyle />
          <SnackContext>
            <BrowserRouter>
              <UserContext>
                <Helmet>
                  <title>E-Picurien - Admin</title>
                  <meta name="description" content="Ambassadeur du goût" />
                  <link
                    rel="icon"
                    type="image/svg"
                    sizes="32x32"
                    href={"/favicon/fav-icon_32x32.svg"}
                  />
                  <link
                    rel="icon"
                    type="image/svg"
                    sizes="16x16"
                    href={"/favicon/fav-icon_16x16.svg"}
                  />
                  <link rel="stylesheet" href="/Editor.css" />
                  <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                  />
                  <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                  />
                  <link rel="icon" href="/favicon.ico" />
                </Helmet>
                <Routes />
              </UserContext>
            </BrowserRouter>
          </SnackContext>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
