type Filter = {
  _id: string;
  name: string;
  label: string;
};

export type FilterValue = {
  _id: string;
  value: string;
  filter: Filter;
};

export type FilterValueDto = {
  value: string;
};

export const filterValueDbToDto = (
  filterValue: FilterValue
): FilterValueDto => {
  return {
    value: filterValue?.value,
  };
};

export const defaultFilterValue: FilterValueDto = {
  value: "",
};
