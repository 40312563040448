import React, { useState, createContext, useContext, useEffect } from "react";

import jwtDecode, { JwtPayload } from "jwt-decode";
import { AuthLoginDto, User, UserRole } from "../types";
import { authLogin, authRefresh, authMe, authRenew } from "../api";

import { setAxiosToken } from "../utils/axios";

type ContextType = {
  user: User | null;
  update: (user: Partial<User>) => any;
  token: string | null;
  setToken: (token: string | null) => any;
  hasRole: (role: UserRole) => boolean;
  login: (authLoginDto: AuthLoginDto) => Promise<boolean>;
  logout(): boolean;
};

const Context = createContext<ContextType>({
  user: null,
  update: () => false,
  token: null,
  setToken: () => false,
  hasRole: () => false,
  login: async () => false,
  logout: () => false,
});

function UserContext({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [loader, setLoader] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const id = setTimeout(() => {
      setLoader(false);
    }, 500);
    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    authRefresh().then(setToken);
  }, []);

  useEffect(() => {
    setAxiosToken(token);
    if (token === null) {
      setUser(null);
      return () => true;
    }
    const { exp } = jwtDecode<JwtPayload>(token);

    if (typeof exp !== "number") {
      return () => true;
    }

    const expireOn = exp * 1000; // to miliseconds
    const delay = expireOn - Date.now();

    authMe().then(setUser);

    if (delay < 0) {
      setToken(null);
      setUser(null);
      return;
    }

    const refreshId = setTimeout(() => {
      authRenew().then(setToken);
    }, delay - 60 * 1000); // Renew 60 secondes before expired time

    return () => {
      clearTimeout(refreshId);
    };
  }, [token]);

  return (
    <Context.Provider
      value={{
        user,
        update: (newUser: Partial<User>) => {
          setUser((u) => {
            if (!u) {
              return u;
            }
            return {
              ...u,
              ...newUser,
            };
          });
        },
        token,
        setToken: (t) => setToken(t),
        hasRole: (role: UserRole): boolean => {
          if (!user) {
            return false;
          }

          return user.roles.includes(role);
        },
        login: async (authLoginDto: AuthLoginDto): Promise<boolean> => {
          const t = await authLogin(authLoginDto);
          setToken(t);
          return t !== null;
        },
        logout: () => {
          setUser(null);
          setToken(null);
          return true;
        },
      }}
    >
      {loader ? null : children}
    </Context.Provider>
  );
}

export const useUser = (): ContextType => useContext(Context);
export default UserContext;
