import random from "../utils/random";
import { addYears, endOfDay } from "date-fns";

export enum PromoCodeType {
  Percent = "percent",
  Amount = "amount",
}

export type PromoCode = {
  _id: string;
  name: string;
  description: string;
  code: string;
  type: PromoCodeType;
  value: number;
  used: number;
  minBuy: number;
  uniq: boolean;
  expireOn: Date;
  createOn: Date;
  updateOn: Date;
  cumulative: boolean;
};

export type PromoCodeDto = {
  _id?: string;
  name: string;
  description: string;
  code: string;
  type: PromoCodeType;
  value: number;
  minBuy: number;
  uniq: boolean;
  expireOn: Date;
  cumulative: boolean;
};

export const defaultPromoCode: PromoCodeDto = {
  name: "",
  description: "",
  code: "",
  type: PromoCodeType.Percent,
  minBuy: 0,
  expireOn: endOfDay(addYears(new Date(), 1)),
  value: 10,
  uniq: true,
  cumulative: true,
};

export const promoCodeDbToDto = (promoCode: PromoCode | null): PromoCodeDto => {
  if (!promoCode) {
    return {
      ...defaultPromoCode,
      code: random(26),
    };
  }
  return {
    _id: promoCode._id,
    name: promoCode.name,
    description: promoCode.description,
    code: promoCode.code,
    type: promoCode.type,
    value: promoCode.value,
    uniq: promoCode.uniq,
    expireOn: promoCode.expireOn,
    minBuy: promoCode.minBuy,
    cumulative: promoCode.cumulative,
  };
};
