import axios from "../utils/axios";
import { Supplier, SupplierDto } from "../types";

export const supplierFindAll = async (): Promise<Supplier[]> => {
  return axios.get(`/supplier`).then((res) => res?.data);
};

export const supplierFindOne = async (id: string): Promise<Supplier> => {
  return axios.get(`/supplier/${id}`).then((res) => res?.data);
};

export const supplierCreate = async (
  supplier: SupplierDto
): Promise<Supplier> => {
  return axios.post(`/supplier`, supplier).then((res) => res?.data);
};

export const supplierUpdate = async (
  supplier: SupplierDto
): Promise<Supplier> => {
  const { _id, ...dto } = supplier;
  return axios.put(`/supplier/${_id}`, dto).then((res) => res?.data);
};

export const supplierDelete = async (
  supplier: SupplierDto
): Promise<Supplier> => {
  return axios.delete(`/supplier/${supplier._id}`).then((res) => res?.data);
};
