import { useParams } from "react-router-dom";
import useOrder from "../../hooks/useOrder";
import { useUser } from "../../contexts/UserContext";
import { Order } from "../../components/Order/Order";

export default function OrderShow() {
  const { user } = useUser();
  const { id } = useParams();
  const { order, isLoading, isError } = useOrder(id);

  if (isLoading || !user) {
    return <span>chargement...</span>;
  }
  if (!user || isError || !order) {
    return <span>Erreur</span>;
  }

  return <Order order={order} />;
}
