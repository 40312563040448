import React, { useState, useEffect, useRef } from "react";
import { Control, useController } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { nanoid } from "nanoid";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ErrorMessage from "./ErrorMessage";

import styled from "styled-components";

// import "./Editor.css";

const Style = styled.div`
  margin-top: 50px;
  .draft {
    z-index: -1;
    width: 100%;
    border: 1px solid #f1f1f1;
    padding: 0px 5px;
    background-color: white;

    li > div {
      margin: 0;
    }

    & > div {
      z-index: 0;
    }
  }

  .rdw-editor-toolbar {
    background: none;
    border: 0;
    padding: 0;
    margin-bottom: 0;

    div {
      margin: 0 1px 1px 0;
    }
  }
`;

type Props = {
  control: Control<any>;
  name: string;
  label: string;
};

export default function RicheEditor({ name, label, control }: Props) {
  const [id] = useState(`${name}-${nanoid()}`);
  const [draftValue, setDraftValue] = useState<any>();

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const isLoaded = useRef(false);
  useEffect(() => {
    if (isLoaded.current) return;
    isLoaded.current = true;
    const contentBlock = htmlToDraft(value || "");
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    setDraftValue(EditorState.createWithContent(contentState));
  }, [value]);

  return (
    <FormControl
      error={!!error}
      sx={{ mt: 2, width: "100%" }}
      variant="outlined"
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Style>
        <Editor
          editorState={draftValue}
          editorClassName="draft"
          onEditorStateChange={(value) => {
            setDraftValue(value);
            const html = draftToHtml(convertToRaw(value.getCurrentContent()));
            if (onChange) {
              onChange(html);
            }
          }}
          toolbar={{
            options: ["inline", "list" /*, "remove" */],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered"],
            },
            fontSize: {
              className: "bordered-option-classname",
            },
          }}
        />
      </Style>
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}
