import useSupplier from "../../hooks/useSupplier";
import { useParams, useNavigate } from "react-router-dom";
import Delete from "../../components/Form/Delete";

export default function SupplierList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { supplier, isLoading, isError } = useSupplier(id);

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !supplier) {
    return <span>Erreur</span>;
  }

  const onFinish = () => {
    navigate("../");
  };

  return (
    <>
      <h1>Supprimer {supplier.name}</h1>
      <Delete
        url={`/supplier/${supplier._id}`}
        name={supplier.name}
        onFinish={onFinish}
      />
    </>
  );
}
