import { ChangeEvent } from "react";
import { OutlinedInput, InputLabel, FormControl } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useMemo } from "react";
import throttle from "lodash/throttle";

type Props = {
  onChange: (value: string) => any;
};

export default function Search({ onChange }: Props): React.ReactElement {
  const change = useMemo(
    () =>
      throttle((e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      }, 800),
    [onChange]
  );

  return (
    <FormControl sx={{ mt: 2, mb: 2, width: "100%" }} variant="outlined">
      <InputLabel htmlFor="search">Recherche</InputLabel>
      <OutlinedInput
        id="search"
        label="Recherche"
        name="search"
        type="search"
        fullWidth
        autoComplete="off"
        onChange={change}
        startAdornment={<SearchIcon sx={{ mr: 1 }} />}
      />
    </FormControl>
  );
}
