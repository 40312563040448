import numeral from "numeral";

try {
  numeral.register("locale", "fr", {
    delimiters: {
      thousands: " ",
      decimal: ",",
    },
    abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t",
    },
    ordinal: function (number) {
      return number === 1 ? "er" : "ème";
    },
    currency: {
      symbol: "€",
    },
  });
} catch (e) {
  // hide this error
  // console.error(e);
}

numeral.locale("fr");

export function price(value: number, force = false): string {
  if (force) {
    return numeral(value).format("0,0.00 $");
  }
  return numeral(value).format("0,0[.]00 $");
}

export default price;
