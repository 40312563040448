import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  title: string;
  content: string;
  ok: string;
  ko: string;
  onClick: (response: boolean) => void;
};

export default function Confirm({
  title,
  content,
  ok,
  ko,
  onClick,
}: Props): React.ReactElement {
  return (
    <Dialog
      open
      keepMounted
      onClose={() => onClick(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClick(false)}>{ko}</Button>
        <Button onClick={() => onClick(true)}>{ok}</Button>
      </DialogActions>
    </Dialog>
  );
}
