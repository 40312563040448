import { customAlphabet } from "nanoid";
import { nolookalikesSafe } from "nanoid-dictionary";

export default function random(length: number = 10): string {
  return customAlphabet(nolookalikesSafe, length)();
}

export const randomRef = (): string => {
  return Date.now().toString(36).toUpperCase();
};
