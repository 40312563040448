import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Drawer from "../components/Element/Drawer";
import { useUser } from "../contexts/UserContext";
import { UserRole } from "../types";

const LoggedLayout = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const isLogged = !!user;
    if (!isLogged) {
      navigate("/");
    }
    if (user && user.roles.includes(UserRole.Admin)) return;
    window.location.href = `https://www.${process.env.REACT_APP_DOMAIN}`;
  }, [navigate, user]);

  if (!user || !(user.roles || []).includes(UserRole.Admin)) {
    return <div />;
  }

  return (
    <div>
      <Drawer>
        <Outlet />
      </Drawer>
    </div>
  );
};

export default LoggedLayout;
