import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import isArray from "lodash/isArray";

type ColorType = {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
};

const getcolor = (props: ColorType) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 10px 0;
  color: #bdbdbd;
  cursor: pointer;
  background-color: #ffffff;
  border-color: ${(props: ColorType) => getcolor(props)};
  border-style: dashed;
  border-width: 2px;
  border-radius: 2px;
  outline: none;
  transition: border 0.24s ease-in-out;

  p {
    margin: 5px;
  }
`;

type Props = {
  id: string;
  name: string;
  onDrop: (file: File[]) => any;
  multi: boolean;
  extensions: string[];
};

const InputFiles = ({ id, name, onDrop, multi, extensions }: Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input id={id} name={name} {...getInputProps()} multiple={multi} />
      {isDragActive ? (
        <p>Posez les fichiers ici ...</p>
      ) : (
        <>
          <p>
            Glissez vos fichiers ici, ou cliquez pour ouvrir votre explorateur
            de fichiers.
          </p>
          {isArray(extensions) && extensions.length > 0 && (
            <p>{`Extensions autorisées: ${extensions.join(", ")}.`}</p>
          )}
        </>
      )}
    </Container>
  );
};

export default InputFiles;
