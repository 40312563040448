import ProductForm from "./ProductForm";
import { useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { Product } from "../../types";

export default function ProductList() {
  const navigate = useNavigate();
  const snack = useSnack();

  const onFinish = (product: Product) => {
    snack.success(`${product.name} a bien été créé.`);
    navigate(`/product/variant/${product._id}`);
  };

  return (
    <>
      <h1>Créer un produit</h1>
      <ProductForm onFinish={onFinish} />
    </>
  );
}
