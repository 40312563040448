import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Control, useController } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import Skeleton from "@mui/material/Skeleton";
import { nanoid } from "nanoid";

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label: string;
  options: OptionType[];
  control: Control<any>;
}

export default function Select({
  control,
  label,
  options,
  name,
}: Props): React.ReactElement {
  const [id] = useState(nanoid());
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <div>
      <FormControl error={!!error} sx={{ mt: 2, width: "100%" }}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        {options.length === 0 ? (
          <Skeleton variant="rectangular" height={56} />
        ) : (
          <MuiSelect
            id={id}
            aria-label={label}
            {...field}
            value={field.value || ""}
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
            label={label}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </MuiSelect>
        )}

        <ErrorMessage name={name} control={control} />
      </FormControl>
    </div>
  );
}
