import useSWR, { useSWRConfig } from "swr";
import { fetcherOne } from "../utils/axios";
import { Tva } from "../types";

type ReturnType = {
  tva: Tva | null | undefined;
  isLoading: boolean;
  isError: Error | null;
  mutate: () => any;
};

export default function useTva(id: string | string[] | undefined): ReturnType {
  const key = id ? `/tva/${id}` : null;
  const { data, error } = useSWR(key, (url) => fetcherOne<Tva>(url));
  const { mutate } = useSWRConfig();

  return {
    tva: data,
    isLoading: !error && !data,
    isError: error,
    mutate: () => {
      mutate(key);
    },
  };
}
