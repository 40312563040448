import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import {
  Order,
  OrderDto,
  orderToOrderDto,
  orderStatusOptions,
  addressToDto,
  User,
  defaultAddress,
} from "../../types";
import Button from "../../components/Field/Button";
import {
  userFindAllForSelect,
  userFindOneForSelect,
  userFindOne,
  orderCreate,
  orderUpdate,
  clickAndCollectFindOne,
  configurationFindAll,
} from "../../api";
import SelectAsyncSearch from "../../components/Field/SelectAsyncSearch";
import Select from "../../components/Field/Select";
import InputNumber from "../../components/Field/InputNumber";
import Address from "../../components/Field/Address";
import DeliveryField from "./fields/Delivery";
import Variants from "./fields/Variants";
import PromoCodes from "./fields/PromoCodes";

type Props = {
  onFinish?: (order: Order) => any;
  order?: Order;
};

export default function OrderForm({ onFinish, order }: Props) {
  const [user, setUser] = useState<User | null>(null);
  const methods = useForm<OrderDto>({
    defaultValues: orderToOrderDto(order),
  });
  const { control, handleSubmit, setValue, watch } = methods;

  useEffect(() => {
    if (order?._id) return;
    configurationFindAll().then((config) => {
      setValue("delivery", Number(config.deliveryPrice));
    });
  }, [order?._id, setValue]);

  const onSubmit: SubmitHandler<OrderDto> = async (data) => {
    if (order?._id) {
      const res = await orderUpdate(order._id, data);
      if (typeof onFinish === "function") onFinish(res);
      return;
    }
    const res = await orderCreate(data);
    if (typeof onFinish === "function") onFinish(res);
  };

  const click = watch("deliveryDate.clickAndCollect");
  const userId = watch("user");

  useEffect(() => {
    if (!click || click === "home") {
      setValue(
        "deliveryAddress",
        user ? addressToDto(user.deliveryAddress) : defaultAddress
      );
      return;
    }
    clickAndCollectFindOne(click).then((value) => {
      if (!value) return;
      setValue("deliveryAddress", addressToDto(value.address));
    });
  }, [click, setValue, user]);

  useEffect(() => {
    if (!user) return;
    if (user) {
      setValue("invoiceAddress", addressToDto(user.invoiceAddress));
    }
  }, [user, setValue]);

  useEffect(() => {
    if (!userId) {
      setUser(null);
      return;
    }
    userFindOne(userId).then((u) => {
      if (!u) {
        setUser(null);
        return;
      }
      setUser(u);
    });
  }, [userId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectAsyncSearch
          control={control}
          name="user"
          find={userFindAllForSelect}
          findOne={userFindOneForSelect}
          label="Client"
        />

        <DeliveryField control={control} />

        {click && (
          <div
            style={{ marginTop: "30px", display: "flex", alignItems: "center" }}
          >
            <Address
              control={control}
              name="deliveryAddress"
              label="Adresse de livraison"
              setValue={setValue}
              editable={click === "home"}
            />

            <Address
              control={control}
              name="invoiceAddress"
              label="Adresse de facturation"
              setValue={setValue}
            />
          </div>
        )}

        <Variants control={control} name="items" />

        <PromoCodes control={control} name="promoCodes" />

        <InputNumber
          label="Frais de livraison TTC"
          control={control}
          name="delivery"
        />

        <Select
          label="Statut de la commande"
          name="status"
          options={orderStatusOptions}
          control={control}
        />

        <Button
          sx={{ mt: 2, width: "100%" }}
          type="submit"
          label={order?._id ? "Modifier" : "Ajouter"}
        />
      </form>
    </FormProvider>
  );
}
