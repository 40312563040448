import React from "react";
import { Route } from "react-router-dom";
import ClickAndCollectList from "./ClickAndCollectList";
import ClickAndCollectCreate from "./ClickAndCollectCreate";
import ClickAndCollectUpdate from "./ClickAndCollectUpdate";
import ClickAndCollectDelete from "./ClickAndCollectDelete";

export default (
  <Route path="click-and-collect">
    <Route index element={<ClickAndCollectList />} />
    <Route path="add" element={<ClickAndCollectCreate />} />
    <Route path="update/:id" element={<ClickAndCollectUpdate />} />
    <Route path="delete/:id" element={<ClickAndCollectDelete />} />
  </Route>
);
