import * as yup from "yup";

export const mediaSchema = yup
  .mixed()
  .test("is-media", "Ceci n'est pas un média valide", (value) =>
    yup.string().min(2).isValid(value?.path)
  );

export type Media = {
  _id: string;
  path: string;
  size: number;
  filename: string;
  crop: boolean;
  name: string;
  mimetype: string;
  image?: {
    width: number;
    height: number;
  };
};
