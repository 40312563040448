import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { FormControl } from "@mui/material";
import InputNumber from "rc-input-number";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Control, useController } from "react-hook-form";
import { nanoid } from "nanoid";
import ErrorMessage from "./ErrorMessage";

// https://input-number.vercel.app/
type Props = {
  control: Control<any>;
  name: string;
  label: string;
  precision?: number;
  step?: number;
  min?: number | undefined;
  max?: number | undefined;
};

const UpHandler = (
  <KeyboardArrowUpIcon sx={{ width: "100%", height: "100%" }} />
);
const DownHandler = (
  <KeyboardArrowDownIcon sx={{ width: "100%", height: "100%" }} />
);

export default function InputPassword({
  control,
  name,
  label,
  ...rest
}: Props): React.ReactElement {
  const [id] = useState(`${name}-${nanoid()}`);
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  return (
    <FormControl error={!!error} sx={{ mt: 2, width: "100%" }} focused>
      <InputLabel
        focused
        sx={{
          backgroundColor: "white",
          padding: "0 10px",
          marginLeft: "-5px",
        }}
        htmlFor={id}
      >
        {label}
      </InputLabel>

      <InputNumber
        id={id}
        decimalSeparator=","
        parser={(v) => Number(`${v}`.replace(/,/, "."))}
        {...field}
        onChange={(value) => {
          field.onChange({ target: { value } });
        }}
        upHandler={UpHandler}
        downHandler={DownHandler}
        {...rest}
      />

      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}

InputPassword.defaultProps = {
  precision: 0,
  step: 1,
  min: undefined,
  max: undefined,
};
