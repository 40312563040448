import { useState, useMemo, useRef, useEffect } from "react";
import { nanoid } from "nanoid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Control, useController } from "react-hook-form";
import throttle from "lodash/throttle";
import uniqBy from "lodash/uniqBy";
import ErrorMessage from "./ErrorMessage";
import { SelectOption } from "../../types";

interface Props {
  name: string;
  label: string;
  control: Control<any>;
  find: (s: string) => Promise<SelectOption[]>;
  findOne: (id: string) => Promise<SelectOption | null>;
}

export default function SelectAsyncSearch({
  control,
  label,
  name,
  find,
  findOne,
}: Props): React.ReactElement {
  const [id] = useState(nanoid());
  const [selected, setSelected] = useState<SelectOption | null>(null);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const selectedCache = useRef(
    new Map<string, SelectOption | null>([["", null]])
  );
  useEffect(() => {
    if (field.value === null) {
      setSelected(null);
      return;
    }

    if (selectedCache.current.has(field.value)) {
      setSelected(selectedCache.current.get(field.value) || null);
      return;
    }

    findOne(field.value).then((res) => {
      selectedCache.current.set(field.value, res);
      setSelected(res);
    });
  }, [field.value, findOne]);

  const fetchCache = useRef(new Map<string, SelectOption[]>());
  useEffect(() => {
    find("").then((res) => {
      fetchCache.current.set("", res);
      setOptions(res);
    });
  }, [find]);

  const fetch = useMemo(
    () =>
      throttle(async (s: string) => {
        if (fetchCache.current.has(s)) {
          const res = fetchCache.current.get(s);
          if (res !== undefined) {
            setOptions(res);
            return true;
          }
        }

        find(s).then((res) => {
          fetchCache.current.set(s, res);
          setOptions(res);
        });
      }, 1000),
    [find]
  );

  const realOptions = useMemo(() => {
    if (selected === null) return options;
    return uniqBy([selected, ...options], "value");
  }, [selected, options]);

  const defaultValue = useMemo(() => {
    return realOptions.find((option) => option.value === field.value) || null;
  }, [realOptions, field.value]);

  return (
    <FormControl
      error={!!error}
      sx={{ mt: 2, width: "100%" }}
      variant="outlined"
    >
      <Autocomplete
        id={id}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.label
        }
        filterOptions={(x) => x}
        options={realOptions}
        autoComplete
        includeInputInList
        value={defaultValue}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => {
          if (!option || !value) return false;
          return (option?.value || option) === (value?.value || value);
        }}
        noOptionsText={`Rechercher...`}
        onChange={(_event, newValue: SelectOption | null) => {
          onChange({ target: { value: newValue?.value || null } });
        }}
        onInputChange={(_event, newInputValue) => {
          fetch(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} fullWidth />
        )}
      />
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}
