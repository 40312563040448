import { Zip } from ".";

export enum Civility {
  M = "M.",
  MME = "Mme",
}

export type Address = {
  _id: string;
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  address2: string;
  zip: string;
  city: Zip;
  phone: string;
};

export type AddressDto = {
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  address2: string;
  zip: string;
  city: string;
  phone: string;
};

export const defaultAddress: AddressDto = {
  firstName: "",
  lastName: "",
  company: "",
  address: "",
  address2: "",
  zip: "",
  city: "",
  phone: "",
};

export const addressToDto = (address: Address): AddressDto => {
  if (!address) return defaultAddress;
  return {
    ...address,
    city: address?.city?._id,
  };
};
