import useTva from "../../hooks/useTva";
import { useParams, useNavigate } from "react-router-dom";
import Delete from "../../components/Form/Delete";

export default function TvaList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { tva, isLoading, isError } = useTva(id);

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !tva) {
    return <span>Erreur</span>;
  }

  const onFinish = () => {
    navigate("../");
  };

  return (
    <>
      <h1>Supprimer le taux de TVA {tva.name}</h1>
      <Delete url={`/tva/${tva._id}`} name={tva.name} onFinish={onFinish} />
    </>
  );
}
