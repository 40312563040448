import { useState } from "react";
import { Switch as MSwitch, FormGroup, FormControlLabel } from "@mui/material";
import { useController, Control } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import { nanoid } from "nanoid";

type Props = {
  name: string;
  control: Control<any>;
  label: string;
  disabled?: boolean;
};

export default function Switch({
  name,
  label,
  control,
  disabled,
}: Props): React.ReactElement {
  const [id] = useState(`${name}-${nanoid()}`);
  const { field } = useController({
    name,
    control,
  });

  return (
    <FormGroup sx={{ mt: 2 }}>
      <FormControlLabel
        control={
          <MSwitch
            id={id}
            disabled={disabled}
            color="secondary"
            {...field}
            checked={!!field.value}
          />
        }
        label={label}
      />
      <ErrorMessage name={name} control={control} />
    </FormGroup>
  );
}

Switch.defaultProps = {
  disabled: false,
};
