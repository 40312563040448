import React, { useEffect } from "react";
import InputText from "../../components/Field/InputText";
import InputCode from "../../components/Field/InputCode";
import InputNumber from "../../components/Field/InputNumber";
import Radios from "../../components/Field/Radios";
import InputDateTime from "../../components/Field/InputDateTime";
import Textarea from "../../components/Field/Textarea";
import Button from "../../components/Field/Button";
import { Alert } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { format, isValid } from "date-fns";
import frLocale from "date-fns/locale/fr";
import {
  PromoCode,
  PromoCodeDto,
  promoCodeDbToDto,
  PromoCodeType,
} from "../../types";
import { promoCodeCreate, promoCodeUpdate } from "../../api";
import Switch from "../../components/Field/Switch";
import price from "../../utils/price";

type Props = {
  promoCode?: PromoCode;
  onFinish?: (promoCode: PromoCode) => any;
};

const PromoCodeForm = ({ promoCode, onFinish }: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<PromoCodeDto>({
    defaultValues: promoCodeDbToDto(promoCode || null),
  });

  const onSubmit: SubmitHandler<PromoCodeDto> = async (data) => {
    const action = promoCode?._id ? promoCodeUpdate : promoCodeCreate;
    const res = await action(data);

    if (onFinish) {
      onFinish(res);
    }
    return reset(promoCodeDbToDto(promoCode || null));
  };

  const data = watch();

  useEffect(() => {
    if (
      data.type === PromoCodeType.Amount &&
      !data.uniq &&
      data.minBuy <= data.value
    ) {
      setValue("uniq", true);
    }
  }, [data.type, data.uniq, setValue, data.minBuy, data.value]);

  return (
    <form key={promoCode?._id || ""} onSubmit={handleSubmit(onSubmit)}>
      <InputText label="Donnez un nom au code" name="name" control={control} />

      <Textarea
        label="Description / Memo"
        name="description"
        control={control}
      />

      <InputCode label="Code" name="code" control={control} />
      <Radios
        id="type"
        label="Type de code"
        name="type"
        options={[
          { label: "Pourcentage", value: PromoCodeType.Percent },
          { label: "Montant", value: PromoCodeType.Amount },
        ]}
        control={control}
      />

      <InputNumber
        label="Valeur"
        name="value"
        control={control}
        precision={2}
      />

      <InputNumber
        label="Minimum d'achat"
        name="minBuy"
        control={control}
        precision={2}
      />

      <InputDateTime
        label="Date d'expiration"
        name="expireOn"
        control={control}
      />

      <Switch label="Code à usage unique" name="uniq" control={control} />
      <Switch
        label="Code cumulable avec d'autres codes"
        name="cumulative"
        control={control}
      />

      <Alert severity="info" sx={{ mt: 3 }}>
        La promo <strong>{data.name}</strong> permet{" "}
        <strong>{data.uniq ? "à la personne" : "à toutes personnes"}</strong>{" "}
        {data.minBuy > 0 && (
          <>
            ayant un panier minimum de <strong>{price(data.minBuy)}</strong> et{" "}
          </>
        )}
        ajoutant {data.cumulative && <strong>uniquement</strong>} le code{" "}
        <strong>
          <code>{data.code}</code>
        </strong>{" "}
        au moment du paiment de bénéficier d&apos;une réduction de{" "}
        <strong>
          {data.value}
          {data.type === PromoCodeType.Percent ? "%" : "€"}
        </strong>{" "}
        sur la totalité {data.uniq ? "de sa commande" : "de leurs commandes"}{" "}
        {isValid(data.expireOn) && (
          <>
            avant le{" "}
            <strong>
              {format(new Date(data.expireOn), "cccc d LLLL yyyy à H:mm", {
                locale: frLocale,
              })}
            </strong>
          </>
        )}
        .
      </Alert>

      <Button
        sx={{ mt: "25px", mb: "50px" }}
        type="submit"
        disabled={isSubmitting}
        label={promoCode?._id ? "Modifier" : "Ajouter"}
      />
    </form>
  );
};

export default PromoCodeForm;
