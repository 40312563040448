import SupplierForm from "./SupplierForm";
import { useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { Supplier } from "../../types";

export default function SupplierList() {
  const navigate = useNavigate();
  const snack = useSnack();

  const onFinish = (supplier: Supplier) => {
    snack.success(`${supplier.name} a bien été créé.`);
    navigate("/supplier");
  };

  return (
    <>
      <h1>Créer un fournisseur</h1>
      <SupplierForm onFinish={onFinish} />
    </>
  );
}
