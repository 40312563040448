import axios from "../utils/axios";
import mem from "mem";

export type StatDto = {
  start: Date;
  end: Date;
};

type Invoice = {
  _id: string;
  email: string;
  invoiceNumber: string;
  invoiceOn: Date;
  number: string;
};

export type StatResponse = {
  ttc: number;
  tva: number;
  ht: number;
  nb: number;
  invoiceNumbers: Invoice[];
};

export const statMem = mem(
  async (data: StatDto): Promise<StatResponse> => {
    return axios
      .get<StatResponse>("/stat", {
        params: data,
      })
      .then((res) => res?.data);
  },
  {
    cacheKey: (args) => {
      const { start, end } = args[0];
      return `${start.toString()}-${end.toString()}`;
    },
  }
);

export const stat = statMem;
