import OrderForm from "./OrderForm";
import { useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";

export default function OrderList() {
  const snack = useSnack();
  const navigate = useNavigate();

  const onFinish = () => {
    snack.success(`La commande a bien été créé.`);
    navigate("/order");
  };

  return (
    <>
      <h1>Créer une commande</h1>
      <OrderForm onFinish={onFinish} />
    </>
  );
}
