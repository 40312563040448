import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import InputNumber from "../../components/Field/InputNumber";
import Button from "../../components/Field/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { ConfigurationDto, Configuration } from "../../types";
import { configurationUpdate, configurationFindAll } from "../../api";
import price from "../../utils/price";

type Props = {
  clickAndCollect?: Configuration;
  onFinish?: (clickAndCollect: Configuration) => any;
};

const ConfigurationForm = ({ onFinish }: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { control, handleSubmit, reset, watch } = useForm<ConfigurationDto>();

  useEffect(() => {
    configurationFindAll().then((res) => {
      reset(res);
      setIsLoaded(true);
    });
  }, [reset]);

  const onSubmit: SubmitHandler<ConfigurationDto> = async (data) => {
    const res = await configurationUpdate(data);
    if (onFinish) {
      onFinish(res);
    }
  };

  const minBasketForFreeDelivery = watch("minBasketForFreeDelivery");

  if (!isLoaded) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputNumber
            label="Panier minimum TTC pour la livraison gratuite à domicile"
            name="minBasketForFreeDelivery"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputNumber
            label={`Prix TTC de la livraison à domicile si le panier est en dessous de ${price(
              minBasketForFreeDelivery
            )}`}
            name="deliveryPrice"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputNumber
            label={`Prix TTC du click & collect si le panier est en dessous de ${price(
              minBasketForFreeDelivery
            )}`}
            name="clickAndCollectPrice"
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" label="Modifier" />
        </Grid>
      </Grid>
    </form>
  );
};

export default ConfigurationForm;
