import useSupplier from "../../hooks/useSupplier";
import { useParams, useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { Supplier } from "../../types";
import SupplierForm from "./SupplierForm";

export default function SupplierList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { supplier, isLoading, isError, mutate } = useSupplier(id);
  const snack = useSnack();

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !supplier) {
    return <span>Erreur</span>;
  }

  const onFinish = (supplier: Supplier) => {
    snack.success(`${supplier.name} a bien été modifié.`);
    mutate();
    navigate("../");
  };

  return (
    <>
      <h1>{supplier.name}</h1>
      <SupplierForm supplier={supplier} onFinish={onFinish} />
    </>
  );
}
