import P from "bluebird";
import axios from "axios";
import QuickLRU from "quick-lru";

const lru = new QuickLRU<string, any>({ maxSize: 10, maxAge: 1000 });

const baseURL = process.env.REACT_APP_API_URL;
const baseURLServer = process.env.REACT_APP_API_URL_SERVER;
if (typeof window === "undefined") {
  axios.defaults.baseURL = baseURLServer;
} else {
  axios.defaults.baseURL = baseURL;
}

axios.defaults.withCredentials = true;

export const setAxiosToken = (t: string | null) => {
  if (t !== null) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + t;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default axios;

export async function fetcherMany<T>(url: string): Promise<T[]> {
  if (!lru.has(url)) {
    const res = axios.get<{ docs: T[] }>(url).then((res) => res?.data?.docs);
    lru.set(url, res);
  }
  return lru.get(url);
}

export async function fetcherOne<T>(url: string): Promise<T | null> {
  if (!lru.has(url)) {
    const res = axios.get<T>(url).then((res) => res?.data || null);
    lru.set(url, res);
  }
  return lru.get(url);
}

export async function findAll<T>(url: string): Promise<T[]> {
  if (lru.has(url)) {
    return lru.get(url);
  }

  const resP = new P<T[]>(async (resolve) => {
    let hasNext = true;
    const all = [];
    let page = 0;
    do {
      page += 1;
      const res = await axios
        .get<{ docs: T[]; hasNextPage: boolean }>(url, {
          params: { page },
        })
        .then((res) => res?.data);
      all.push(...res.docs);
      hasNext = res.hasNextPage;
    } while (hasNext);
    resolve(all);
  });

  lru.set(url, resP);
  return resP;
}
