import { useEffect, Dispatch } from "react";
import useSWRInfinite from "swr/infinite";
import { fetcherMany } from "../utils/axios";
import { Supplier } from "../types";

type BasicStateAction<T> = ((v: T) => T) | T;

type ReturnType = {
  suppliers: Supplier[];
  size: number;
  setSize: Dispatch<BasicStateAction<number>>;
  mutate: () => any;
};

export default function useSuppliers(params: string): ReturnType {
  const { data, size, setSize, mutate } = useSWRInfinite(
    (pageIndex: number, previousPageData: Supplier[]) => {
      if (previousPageData && !previousPageData.length) {
        return null;
      }
      return `/supplier?page=${pageIndex + 1}${params}`;
    },
    (url) => fetcherMany<Supplier>(url),
    {
      revalidateFirstPage: false,
      revalidateOnMount: true,
      // dedupingInterval: 0,
    }
  );

  useEffect(() => {
    mutate();
  }, [mutate]);

  const suppliers = (data || []).reduce((acc, list) => {
    list.forEach((el: Supplier) => {
      acc.push(el);
    });
    return [...acc];
  }, []);

  return {
    suppliers,
    size,
    setSize,
    mutate: () => {
      mutate();
    },
  };
}
