import styled, { createGlobalStyle } from "styled-components";
import { Fonts, Colors } from "../../utils/style";
import Color from "color";

export const GlobalStyle = createGlobalStyle`

  html {
    font-size: 0.625em;
    font-size: calc(1em * 0.625);
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-size: 1.4em;
    margin: 0;
    padding: 50px 0;
    background-color: ${Colors.MIRAGE};
  }

  @media print {
    body {
      background-color: ${Colors.WHITE};
      padding: 0;
    }
  }
`;

export const StyledAddress = styled.address`
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-style: normal;
  strong {
    font-size: 1.9rem;
  }
`;

export const StyledInvoice = styled.div`
  background-color: ${Colors.WHITE};
  width: 1000px;
  margin: auto;
  padding: 50px;
  aspect-ratio: 21/29.3;
  box-sizing: border-box;
  position: relative;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 200px auto auto 55px 1fr auto;

  p,
  address,
  td,
  th,
  h1 {
    color: ${Colors.MIRAGE};
    font-family: ${Fonts.SANS};
  }

  th {
    background-color: ${Colors.BISQUE};
  }

  h1 {
    font-size: 2.5rem;
  }

  @media print {
    border: none;
  }
`;

export const Logo = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export const Me = styled.div`
  grid-column: 1;
  grid-row: 2;
  padding: 20px 0 50px;
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-style: normal;
  strong {
    font-size: 1.9rem;
  }
`;

export const Info = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;

  h1 {
    color: ${Colors.CAPUCINE};
    font-size: 4rem;
    margin: 100px 0 0;
    line-height: 4rem;
  }
  p {
    line-height: 2.3rem;
  }
`;

export const Customer = styled.div`
  grid-column: 2;
  grid-row: 2;
  text-align: right;
  display: flex;
  align-self: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  grid-column: 1 / 3;
  grid-row: 3 / 5;

  .invoiceOn {
    margin: 0 0 10px;
    padding: 0;
    font-style: italic;
  }

  table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;
    td,
    th {
      border: 1px solid ${new Color(Colors.MIRAGE).fade(0.8).toString()};
      padding: 7px;
      font-size: 1.2rem;
      line-height: 1.3rem;
      vertical-align: middle;

      &.center {
        text-align: center;
        white-space: nowrap;
      }

      &.right {
        text-align: right;
        white-space: nowrap;
      }
    }

    tfoot {
      tr {
        td:first-child {
          border-left: 0px;
          border-bottom: 0px;
          border-top: 0px;
        }
      }
    }

    .item-product {
      .ref,
      .name {
        border-bottom: 0px;
        padding-bottom: 0;
      }

      .name {
        font-size: 1.3rem;
      }
    }

    .ref {
      font-size: 1rem;
      vertical-align: top;
    }

    .item-variant {
      .ref,
      .name {
        border-top: 0;
        padding-top: 3px;
      }

      .name {
        font-size: 0.9rem;
      }
    }
  }
`;

export const Tva = styled(Content)`
  grid-column: 1 / 3;
  grid-row: 4 / 6;

  table {
    width: auto;

    td,
    th {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
`;

export const Footer = styled.div`
  grid-column: 1 / 3;
  grid-row: 6;
  display: flex;
  align-items: flex-end;
  font-size: 1.2rem;
`;

export const Print = styled.div`
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;

  button {
    color: ${Colors.CAPUCINE};
    font-family: ${Fonts.SANS};
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.8rem;
    padding: 10px 30px;
    border-radius: 0px 15px;
    border: 1px solid ${Colors.CAPUCINE};
    background: none;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
    transition-timing-function: ease-in-out;
    transition-property: background-color, color;
    transition-duration: 0.2s;
    text-align: center;

    &:hover {
      background-color: ${Colors.CAPUCINE};
      color: ${Colors.WHITE};
    }
  }

  @media print {
    display: none;
  }
`;
