import { useCallback } from "react";
import ConfigurationForm from "./ConfigurationForm";
import { useSnack } from "../../contexts/SnackContext";

export default function ProductList() {
  const snack = useSnack();

  const onFinish = useCallback(() => {
    snack.success("La configuration a bien été enregitrée");
  }, [snack]);

  return (
    <div>
      <h1>Configurations</h1>
      <ConfigurationForm onFinish={onFinish} />
    </div>
  );
}
