import useProduct from "../../hooks/useProduct";
import ProductForm from "./ProductForm";
import { useParams, useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { Product } from "../../types";
import omit from "lodash/omit";

export default function ProductList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { product, isLoading, isError, mutate } = useProduct(id);
  const snack = useSnack();

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !product) {
    return <span>Erreur</span>;
  }

  const onFinish = (product: Product) => {
    snack.success(`${product.name} a bien été enregistré.`);
    mutate();
    navigate("../");
  };

  return (
    <>
      <h1>{product.name}</h1>
      <ProductForm
        product={omit(product, ["_id", "ref"])}
        onFinish={onFinish}
      />
    </>
  );
}
