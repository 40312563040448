import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";

const LoggoutLayout = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const isLogged = !!user;
    if (isLogged) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default LoggoutLayout;
