import { User, AuthLoginDto } from "../types";
import axios from "../utils/axios";

export const authLogin = async (
  authLoginDto: AuthLoginDto
): Promise<string | null> => {
  return axios
    .post("/auth/login", authLoginDto)
    .then((res) => res?.data?.token || null);
};

export const authRenew = async (): Promise<string | null> => {
  return axios.get("/auth/renew").then((res) => res?.data?.token || null);
};

export const authRefresh = async (): Promise<string | null> => {
  return axios.get("/auth/refresh").then((res) => res?.data?.token || null);
};

export const authMe = async (): Promise<User> => {
  return axios.get("/auth/me").then((res) => res?.data);
};

export const authUpdateMe = async (user: Partial<User>): Promise<User> => {
  return axios.patch("/auth/me", user).then((res) => res?.data);
};

export const authCheckMailAlreadyExists = async (
  mail: string
): Promise<boolean> => {
  return axios
    .post("/auth/check-mail-already-exists", { mail })
    .then((res) => !res?.data?.exists);
};

export const authForgot = async (mail: string): Promise<boolean> => {
  return axios
    .post("/auth/forgot", { mail })
    .then((res) => !res?.data?.success);
};

export const authForgotCheck = async (
  id: string,
  token: string
): Promise<boolean> => {
  return axios
    .post("/auth/forgot/check", { id, token })
    .then((res) => res?.data?.success);
};

export const authForgotReset = async (
  id: string,
  token: string,
  password: string
): Promise<boolean> => {
  return axios
    .post("/auth/forgot/reset", { id, token, password })
    .then((res) => res?.data?.success);
};
