import React, { useCallback, useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import Sortable from "sortablejs";
import { arrayMoveImmutable } from "array-move";
import clsx from "clsx";

import {
  StyledOrder,
  Print,
  Content,
  Logo,
  GlobalStyle,
} from "./Delivery.style";
import { findAll } from "../../utils/axios";
import { Order } from "../../types";
import { DragIndicator } from "@mui/icons-material";
import { orderSetInvoice, orderSetPosition } from "../../api";

type ItemType = { id: number };
type SortableOrder = ItemType & Order;

export const Delivery = () => {
  const [orders, setOrders] = useState<SortableOrder[]>([]);
  const [toInvoice, setToInvoice] = useState<Order | null>(null);

  const print = useCallback(() => {
    window.print();
  }, []);

  useEffect(() => {
    findAll<Order>("/order?status=delivery").then((res) =>
      setOrders(
        res
          .map((r, id) => ({ id, ...r }))
          .sort((a, b) => (a.position || 1) - (b.position || 1))
      )
    );
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      orderSetPosition(orders);
    }, 100);
    return () => clearTimeout(id);
  }, [orders]);

  const createOn = format(new Date(new Date()), "dd-MMMM-yyyy");

  const ref = useRef<HTMLTableSectionElement>(null);
  useEffect(() => {
    const actualRef = ref.current;
    if (actualRef) {
      const sortable = Sortable.create(ref.current, {
        draggable: ".sortable-draggable",
        handle: ".draggable",
        animation: 250,
        ghostClass: "sortable-gost",
        onEnd: (e) => {
          setOrders((os) => {
            if (typeof e.newIndex === "undefined") return os;
            if (typeof e.oldIndex === "undefined") return os;
            return arrayMoveImmutable(os, e.oldIndex, e.newIndex);
          });
        },
      });

      return () => {
        if (actualRef) {
          sortable.destroy();
        }
      };
    }

    return () => true;
  }, [ref]);

  const invoice = useCallback((order: Order) => {
    setToInvoice(order);
    const id = setTimeout(async () => {
      const message = `Générer la facture de la commande ${order.number} pour ${order.deliveryAddress?.firstName} ${order.deliveryAddress?.lastName} ?`;
      if (!window.confirm(message)) {
        setToInvoice(null);
        return;
      }
      await orderSetInvoice(order._id);
      setOrders((os) => os.filter((o) => o._id !== order._id));
    }, 100);
    return () => clearTimeout(id);
  }, []);

  return (
    <StyledOrder>
      <GlobalStyle />
      <Helmet>
        <title>{`e-picurien_livraison_${createOn}`}</title>
      </Helmet>

      <Logo>
        <img src="/logo/logo.svg" alt="Logo E-Picurien" width={180} />
      </Logo>

      <Print>
        <button onClick={print}>Imprimer</button>
      </Print>

      <Content>
        <table>
          <thead>
            <tr>
              <th className="no-print"></th>
              <th></th>
              <th>Commande</th>
              <th>Entreprise</th>
              <th>Nom</th>
              <th>Adresse</th>
              <th>Téléphone</th>
              <th className="no-print">Facturer</th>
            </tr>
          </thead>

          <tbody ref={ref}>
            {orders.map((order, id) => (
              <tr
                key={order.id}
                className={clsx(
                  "sortable-draggable",
                  toInvoice && toInvoice._id === order._id && "to-invoice"
                )}
              >
                <td className="no-print center draggable">
                  <DragIndicator fontSize="large" />
                </td>
                <td className="center">{id + 1}</td>
                <td>{order.number}</td>
                <td>{order.deliveryAddress?.company || "-"}</td>
                <td>
                  {order.deliveryAddress?.lastName || "-"}
                  <br />
                  {order.deliveryAddress?.firstName || "-"}
                </td>
                <td>
                  {order.deliveryAddress?.address}
                  <br />
                  {order.deliveryAddress?.address2 && (
                    <>
                      {order.deliveryAddress?.address2}
                      <br />
                    </>
                  )}
                  {order.deliveryAddress?.zip}{" "}
                  {order.deliveryAddress?.city.city}
                </td>
                <td>{order.deliveryAddress?.phone || "-"}</td>
                <td className="no-print center">
                  <button onClick={() => invoice(order)}>Facturer</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>
    </StyledOrder>
  );
};
