import { useState } from "react";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import InputAdornment from "@mui/material/InputAdornment";
import { useController, Control } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import { nanoid } from "nanoid";
import random from "../../utils/random";

type Props = {
  control: Control<any>;
  disabled?: boolean;
  label: string;
  name: string;
};

function InputText({
  control,
  disabled,
  label,
  name,
}: Props): React.ReactElement {
  const [id] = useState(`${name}-${nanoid()}`);
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <TextField
        id={id}
        fullWidth
        sx={{ mt: 2 }}
        error={!!error}
        variant="outlined"
        disabled={disabled}
        label={label}
        {...field}
        value={field.value || ""}
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment sx={{ mr: 3 }} position="start">
                <IconButton
                  aria-label="Generate new code"
                  onClick={() =>
                    field.onChange({ target: { value: random(24) } })
                  }
                  edge="end"
                >
                  <ShuffleIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <ErrorMessage name={name} control={control} />
    </>
  );
}

InputText.defaultProps = {
  disabled: false,
};

export default InputText;
