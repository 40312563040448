import React from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import InputText from "../../components/Field/InputText";
import SelectAsync from "../../components/Field/SelectAsync";
import Button from "../../components/Field/Button";
import {
  Purchase,
  PurchaseDto,
  purchaseDbToDto,
  purchaseUnityList,
  purchaseSchema,
  Supplier,
} from "../../types";
import { purchaseCreate, purchaseUpdate } from "../../api";
import Select from "../../components/Field/Select";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  purchase?: Partial<Purchase>;
  onFinish?: (purchase: Purchase) => any;
};

const PurchaseForm = ({ purchase, onFinish }: Props) => {
  const id = purchase ? purchase?._id : null;
  const methods = useForm<PurchaseDto>({
    resolver: yupResolver(purchaseSchema),
    defaultValues: purchaseDbToDto(purchase),
  });
  const { control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<PurchaseDto> = async (data) => {
    const res = await (purchase?._id
      ? purchaseUpdate(purchase._id, data)
      : purchaseCreate(data));
    if (typeof onFinish === "function") onFinish(res);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputText label="Nom" name="name" control={control} />

        <SelectAsync<Supplier>
          id="supplier"
          name="supplier"
          control={control}
          label="Fournisseur"
          url="/supplier"
        />

        <InputText
          label="Référence fournisseurs"
          name="ref"
          control={control}
        />

        <Select
          label="Unité"
          name="unity"
          control={control}
          options={purchaseUnityList}
        />

        <Button
          sx={{ mt: "50px", mb: "50px" }}
          type="submit"
          label={id ? "Modifier" : "Ajouter"}
        />
      </form>
    </FormProvider>
  );
};

export default PurchaseForm;
