import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useTvas from "../../hooks/useTvas";
import { Tva } from "../../types";
import LinkEdit from "../../components/Element/LinkEdit";
import LinkDelete from "../../components/Element/LinkDelete";
import LinkAdd from "../../components/Element/LinkAdd";

export default function TvaList() {
  const { tvas } = useTvas();

  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/tva/add`} />
      </div>
      <h1>Liste des taux de TVA</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>valeur</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(tvas || []).map((tva: Tva) => (
              <TableRow
                key={tva._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{tva.name}</TableCell>
                <TableCell>{tva.value}%</TableCell>
                <TableCell align="right">
                  <LinkEdit url={`/tva/update/${tva._id}`} />
                  <LinkDelete url={`/tva/delete/${tva._id}`} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
