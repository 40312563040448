import { Add as AddIcon } from "@mui/icons-material";
import Fab from "@mui/material/Fab";

type Props = {
  onClick: () => any;
  label?: string;
};

export default function BtnAdd({ onClick, label = "Ajouter" }: Props) {
  return (
    <div style={{ float: "right", padding: "20px" }}>
      <Fab
        sx={{ mr: 1 }}
        color="primary"
        size="large"
        aria-label={label}
        onClick={onClick}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
