import {
  Address,
  AddressDto,
  DeliveryDate,
  DeliveryDateDto,
  DeliveryDatePeriod,
  OrderData,
  ProductVariant,
  PromoCode,
  SelectOption,
  defaultAddress,
} from ".";

export enum OrderStatus {
  Draft = "draft",
  Pending = "pending",
  Paid = "paid",
  Order = "order",
  Prepare = "prepare",
  Delivery = "delivery",
  Finish = "finish",
  Canceled = "canceled",
}

export const orderStatusIndex = new Map<OrderStatus, string>();
orderStatusIndex.set(OrderStatus.Draft, "Brouillon");
orderStatusIndex.set(OrderStatus.Pending, "Paiement en cours");
orderStatusIndex.set(OrderStatus.Paid, "Payé");
orderStatusIndex.set(OrderStatus.Prepare, "En préparation");
orderStatusIndex.set(OrderStatus.Order, "En pré-commande");
orderStatusIndex.set(OrderStatus.Delivery, "Livraison");
orderStatusIndex.set(OrderStatus.Finish, "Livré");
orderStatusIndex.set(OrderStatus.Canceled, "Annulé");

export const orderStatusOptions: SelectOption[] = Object.values(
  OrderStatus
).map((value) => ({
  label: orderStatusIndex.get(value) || "?",
  value,
}));

export type OrderItem = {
  productVariant: ProductVariant;
  quantity: number;
};

export type OrderContain = {
  _id: string;
  product: string;
  variant: string;
  quantity: number;
  supplier: string;
};

export type OrderUser = {
  _id: string;
  email: string;
};

export type Order = {
  _id: string;
  number: number | null;
  items: OrderItem[];
  promoCodes: PromoCode[];
  deliveryDate: DeliveryDate;
  delivery: number;
  invoiceAddress?: Address;
  deliveryAddress?: Address;
  total: number;
  status: OrderStatus;
  createOn: Date;

  user: OrderUser;
  intent: string;

  events: any[];
  contains: OrderContain[];
  payOn: Date | null;
  deliveryOn: Date | null;
  quoteNumber: string | null;
  invoiceNumber: string | null;
  invoiceOn: Date | null;
  search: string;
  data: OrderData;
  position: number | null;
};

// DTO

export type OrderBasketItemDto = {
  product: string;
  productVariant: string;
  quantity: number;
};

export type OrderBasketPromoCodeDto = {
  code: string;
};

export type OrderDto = {
  user: string | null;

  items: OrderBasketItemDto[];
  promoCodes: OrderBasketPromoCodeDto[];

  invoiceAddress?: AddressDto;
  deliveryAddress?: AddressDto;
  deliveryDate: Pick<DeliveryDateDto, "clickAndCollect" | "date" | "period">;

  delivery: number;
  status: OrderStatus;
  payOn: Date | null;
  deliveryOn: Date | null;
};

export const defaultOrder: OrderDto = {
  user: null,
  items: [],
  promoCodes: [],
  deliveryAddress: defaultAddress,
  invoiceAddress: defaultAddress,
  status: OrderStatus.Draft,
  delivery: 0,
  deliveryDate: {
    clickAndCollect: null,
    date: new Date(),
    period: DeliveryDatePeriod.PM,
  },
  payOn: null,
  deliveryOn: null,
};

export function orderToOrderDto(order: Order | null | undefined): OrderDto {
  if (!order) {
    return defaultOrder;
  }

  return {
    user: order?.user?._id,
    payOn: null,
    deliveryOn: null,
    items: order.items.map((item) => ({
      product: item.productVariant.product._id,
      productVariant: item.productVariant._id,
      quantity: item.quantity,
    })),
    promoCodes: order.promoCodes.map((promo) => ({
      code: promo.code,
    })),
    deliveryDate: {
      ...order.deliveryDate,
      clickAndCollect:
        order.deliveryDate.clickAndCollect === null
          ? "home"
          : order.deliveryDate.clickAndCollect,
    },
    deliveryAddress: order.deliveryAddress
      ? {
          ...order.deliveryAddress,
          city: order.deliveryAddress?.city?._id || "",
        }
      : undefined,
    delivery: order.delivery,
    invoiceAddress: order.invoiceAddress
      ? {
          ...order.invoiceAddress,
          city: order.invoiceAddress?.city?._id || "",
        }
      : undefined,
    status: order.status,
  };
}
