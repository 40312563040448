import axios from "../utils/axios";
import { OrderDto, Order, OrderStatus, OrderPrepare } from "../types";
import { debounce } from "lodash";

const formatOrderDtoForApi = (orderDto: OrderDto | Partial<OrderDto>) => {
  if (!orderDto.promoCodes) return orderDto;
  return {
    ...orderDto,
    promoCodes: orderDto.promoCodes.map((pc) => pc.code),
  };
};

export const orderPrepare = async (): Promise<OrderPrepare> => {
  return axios.get<OrderPrepare>(`/order-prepare`).then((res) => res?.data);
};

export const orderCreate = async (orderDto: OrderDto): Promise<Order> => {
  return axios
    .post<Order>(`/order`, formatOrderDtoForApi(orderDto))
    .then((res) => res?.data);
};

export const orderUpdate = async (
  id: string,
  orderDto: Partial<OrderDto>
): Promise<Order> => {
  return axios
    .put<Order>(`/order/${id}`, formatOrderDtoForApi(orderDto))
    .then((res) => res?.data);
};

export const orderUpdateStatus = async (
  id: string,
  status: OrderStatus
): Promise<Order> => {
  return axios
    .put<Order>(`/order/${id}/status`, { status })
    .then((res) => res?.data);
};

export const orderSetInvoice = async (id: string): Promise<Order> => {
  return axios.put<Order>(`/invoice/${id}`).then((res) => res?.data);
};

type OrderPosition = {
  _id: string;
  position: number;
};

const orderSetPositionDebounce = debounce(
  async (orders: Order[]): Promise<true> => {
    const update: OrderPosition[] = [];
    orders.forEach((order, id) => {
      if (order.position === id + 1) return;
      update.push({ _id: order._id, position: id + 1 });
    });

    if (update.length === 0) return true;

    await axios.put(`/invoice-positions`, update);
    return true;
  },
  500
);

export const orderSetPosition = orderSetPositionDebounce;
