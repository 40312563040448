import { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import LoggedLayout from "./layouts/Logged";
import LoggoutLayout from "./layouts/Loggout";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

import clickAndCollect from "./pages/click-and-collect";
import configuration from "./pages/configuration";
import date from "./pages/date";
import delivery from "./pages/delivery";
import filter from "./pages/filter";
import order from "./pages/order";
import prepare from "./pages/prepare";
import product from "./pages/product";
import promoCode from "./pages/promo-code";
import purchase from "./pages/purchase";
import supplier from "./pages/supplier";
import tva from "./pages/tva";
import user from "./pages/user";

import InvoiceShow from "./pages/order/InvoiceShow";
import OrderShow from "./pages/order/OrderShow";
import { Preparation } from "./components/Preparation/Preparation";
import { Delivery } from "./components/Delivery/Delivery";

export default function Routing(): React.ReactElement {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/">
        <Route element={<LoggoutLayout />}>
          <Route index element={<Login />} />
        </Route>

        <Route element={<LoggedLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          {clickAndCollect}
          {configuration}
          {date}
          {delivery}
          {filter}
          {order}
          {prepare}
          {product}
          {promoCode}
          {purchase}
          {supplier}
          {tva}
          {user}
        </Route>
      </Route>
      <Route path="/order/:id/show" element={<OrderShow />} />
      <Route path="/invoice/:id/show" element={<InvoiceShow />} />
      <Route path="/order-prepare" element={<Preparation />} />
      <Route path="/prepare-delivery" element={<Delivery />} />
      <Route path="*" element={<Navigate to={`/`} />} />
    </Routes>
  );
}
