import { useState, useEffect } from "react";
import P from "bluebird";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { Control, useController } from "react-hook-form";
import ErrorMessage from "../../../components/Field/ErrorMessage";
import { filterValueFindAll, filterFindAll } from "../../../api";
import { Filter, FilterValue } from "../../../types";
import sort from "natural-compare";

interface OptionType {
  label: string;
  value: string;
  group: string;
  sort: string;
}

interface Props {
  id: string;
  name: string;
  label: string;
  control: Control<any>;
}

export default function SelectFilters({
  control,
  label,
  name,
  id,
}: Props): React.ReactElement {
  const [options, setOptions] = useState<OptionType[]>([]);
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    filterFindAll()
      .then((filters) => {
        return P.reduce<Filter, FilterValue[]>(
          filters,
          async (acc, filter) => {
            const filterValues = await filterValueFindAll(filter._id);
            return [...acc, ...filterValues];
          },
          []
        );
      })
      .then((res) => {
        setOptions(
          res
            .map((option) => {
              return {
                label: option.value,
                value: option._id,
                group: option.filter.name,
                sort:
                  option.filter.name.toLowerCase() +
                  "_" +
                  option.value.toLowerCase(),
              };
            })
            .sort((a, b) => {
              return sort(a.sort, b.sort);
            })
        );
      });
  }, []);

  return (
    <div>
      <FormControl error={!!error} sx={{ mt: 2, width: "100%" }}>
        <Autocomplete
          multiple
          id={id}
          options={options}
          autoHighlight={true}
          groupBy={(option) => option.group}
          filterSelectedOptions
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} label={label} />}
          {...field}
          value={options.filter((o) => field.value.includes(o.value))}
          onChange={(_e, newValue) => {
            field.onChange({ target: { value: newValue.map((v) => v.value) } });
          }}
        />

        <ErrorMessage name={name} control={control} />
      </FormControl>
    </div>
  );
}
