import TvaForm from "./TvaForm";
import { useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { Tva } from "../../types";

export default function TvaList() {
  const navigate = useNavigate();
  const snack = useSnack();

  const onFinish = (tva: Tva) => {
    snack.success(`${tva.name} a bien été créé.`);
    navigate("/tva");
  };

  return (
    <>
      <h1>Créer un taux de TVA</h1>
      <TvaForm onFinish={onFinish} />
    </>
  );
}
