import { useState, useCallback, SyntheticEvent } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DateItem from "./DateItem";

import styled from "styled-components";
import useClickAndCollects from "../../hooks/useClickAndCollects";

const Styled = styled.div`
  .ListItemOdd {
    background-color: #f8f8f0;
  }
`;

export default function DateList() {
  const [tab, setTab] = useState<number>(0);
  const { clickAndCollects } = useClickAndCollects();

  const handleChange = useCallback((_e: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }, []);

  return (
    <Styled>
      <h1>Gestion des dates de livraison</h1>
      <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Livraison à domicile" />
        {clickAndCollects.map((click) => {
          return <Tab key={click._id} label={click.name} />;
        })}
      </Tabs>
      <DateItem clickAndCollect={clickAndCollects[tab - 1] || null} />
    </Styled>
  );
}
