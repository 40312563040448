import useClickAndCollect from "../../hooks/useClickAndCollect";
import { useParams, useNavigate } from "react-router-dom";
import Delete from "../../components/Form/Delete";

export default function ClickAndCollectList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { clickAndCollect, isLoading, isError } = useClickAndCollect(id);

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !clickAndCollect) {
    return <span>Erreur</span>;
  }

  const onFinish = () => {
    navigate("../");
  };

  return (
    <>
      <h1>Supprimer {clickAndCollect.name}</h1>
      <Delete
        url={`/click-and-collect/${clickAndCollect._id}`}
        name={clickAndCollect.name}
        onFinish={onFinish}
      />
    </>
  );
}
