import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useController, Control } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import { nanoid } from "nanoid";

type Props = {
  control: Control<any>;
  type?: "text" | "email";
  disabled?: boolean;
  name: string;
  label: string;
};

export default function InputText({
  control,
  name,
  ...props
}: Props): React.ReactElement {
  const [id] = useState(`${name}-${nanoid()}`);
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  return (
    <>
      <TextField
        id={id}
        fullWidth
        sx={{ mt: 2 }}
        multiline
        error={!!error}
        variant="outlined"
        {...props}
        {...field}
      />
      <ErrorMessage name={name} control={control} />
    </>
  );
}

InputText.defaultProps = {
  type: "text",
  disabled: false,
};
