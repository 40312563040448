import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import price from "../../../utils/price";
import format from "date-fns/format";
import localeFr from "date-fns/locale/fr";
import { DeliveryDate, Order, DeliveryDatePeriod } from "../../../types";

const getDate = (deliveryDate: DeliveryDate): string => {
  const { date, period } = deliveryDate;
  return `${format(new Date(date), "eeee d LLLL", {
    locale: localeFr,
  })} ${period === DeliveryDatePeriod.AM ? "10h -> 13h" : "17h -> 21h"}`;
};

type Props = {
  order: Order;
  prev: (() => any) | null;
  next: (() => any) | null;
};

export const OrderCard = ({ order, prev, next }: Props) => {
  return (
    <>
      <Card variant="outlined" style={{ margin: "10px 0" }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {order.number} {order.user.email}
          </Typography>
          <Typography component="div">
            <strong>{getDate(order.deliveryDate)}</strong>
          </Typography>
          <Typography sx={{ mb: 1.5 }}>{price(order.total)}</Typography>
          <Typography variant="body2" color="text.secondary">
            <>
              {order?.deliveryAddress?.zip} {order?.deliveryAddress?.city?.city}
            </>
          </Typography>
        </CardContent>
        <CardActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <IconButton
            aria-label="Retour"
            disabled={prev === null}
            onClick={() => prev && prev()}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <a
            href={`/order/${order._id}/show`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <VisibilityIcon />
          </a>
          <IconButton
            aria-label="Préparer"
            disabled={next === null}
            onClick={() => next && next()}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </CardActions>
      </Card>
    </>
  );
};
