import React from "react";
import InputText from "../../components/Field/InputText";
import Media from "../../components/Field/Media";
import Editor from "../../components/Field/Editor";
import Button from "../../components/Field/Button";
import Switch from "../../components/Field/Switch";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  SupplierDto,
  Supplier,
  supplierDbToDto,
  defaultSupplier,
} from "../../types";
import { supplierCreate, supplierUpdate } from "../../api";

type Props = {
  supplier?: Supplier;
  onFinish?: (supplier: Supplier) => any;
};

const SupplierForm = ({ supplier, onFinish }: Props) => {
  const { control, handleSubmit } = useForm<SupplierDto>({
    defaultValues: supplier ? supplierDbToDto(supplier) : defaultSupplier,
  });

  const onSubmit: SubmitHandler<SupplierDto> = async (data) => {
    const action = supplier?._id ? supplierUpdate : supplierCreate;
    const res = await action(data);

    if (onFinish) {
      onFinish(res);
    }
  };

  return (
    <form key={supplier?._id || ""} onSubmit={handleSubmit(onSubmit)}>
      <InputText label="Nom" name="name" control={control} />
      <InputText label="Chapô" name="chapo" control={control} />
      <Editor label="Description" name="description" control={control} />

      <Switch label="Afficher la citation" name="showQuote" control={control} />
      <InputText label="Citation" name="quote" control={control} />
      <InputText
        label="Auteur de la citation"
        name="quoteAuthor"
        control={control}
      />

      <Media
        label="Logo"
        name="logo"
        extensions={["png", "jpg", "jpeg"]}
        control={control}
      />
      <Media
        label="Photo"
        name="photo"
        extensions={["png", "jpg", "jpeg"]}
        control={control}
      />

      <Button type="submit" label={supplier?._id ? "Modifier" : "Ajouter"} />
    </form>
  );
};

export default SupplierForm;
