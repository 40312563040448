import React, { useState } from "react";
import ScrollRef from "../../components/ScrollRef";
import LinkAdd from "../../components/Element/LinkAdd";
import Search from "../../components/Field/Search";
import LinkEdit from "../../components/Element/LinkEdit";
import LinkDelete from "../../components/Element/LinkDelete";
import LinkCopy from "../../components/Element/LinkCopy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import usePurchases from "../../hooks/usePurchases";
import { purchaseUnityListIndex } from "../../types";

export default function ProductList() {
  const [search, setSearch] = useState("");
  const { purchases, size, setSize } = usePurchases(
    `&s=${search}&populate=supplier&sort=createOn&desc=createOn`
  );

  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/purchase/add`} />
      </div>
      <h1>Liste des achats</h1>

      <Search onChange={setSearch} />

      {purchases.length > 0 && (
        <TableContainer component={Paper} sx={{ mb: 5 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom du produit</TableCell>
                <TableCell align="right">Référence</TableCell>
                <TableCell align="right">Fournisseur</TableCell>
                <TableCell align="right">Unité</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases.map((purchase) => {
                return (
                  <TableRow
                    key={purchase._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="purchase">
                      {purchase.name}
                    </TableCell>
                    <TableCell align="right">{purchase.ref}</TableCell>
                    <TableCell align="right">
                      {purchase.supplier?.name}
                    </TableCell>
                    <TableCell align="right">
                      {purchaseUnityListIndex.get(purchase.unity)}
                    </TableCell>
                    <TableCell align="right">
                      <LinkCopy url={`/purchase/copy/${purchase._id}`} />
                      <LinkEdit url={`/purchase/update/${purchase._id}`} />
                      <LinkDelete url={`/purchase/delete/${purchase._id}`} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ScrollRef load={() => setSize(size + 1)} />
    </>
  );
}
