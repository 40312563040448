import { findAll } from "../../../utils/axios";
import { orderUpdateStatus } from "../../../api";
import { useCallback, useEffect, useState } from "react";
import { OrderStatus, Order, DeliveryDatePeriod } from "../../../types";
import Column from "./Column";
import { setHours, compareDesc } from "date-fns";
import { FactCheck } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Fab } from "@mui/material";

const types = [
  OrderStatus.Paid,
  OrderStatus.Order,
  OrderStatus.Prepare,
  OrderStatus.Delivery,
];

export default function Prepare() {
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    findAll<Order>("/order?status=" + types.join(",")).then(setOrders);
  }, []);

  const changeStatus = useCallback(
    async (order: Order, status: OrderStatus) => {
      await orderUpdateStatus(order._id, status);
      setOrders((all) =>
        all
          .map((o) => {
            if (o._id !== order._id) return o;
            return {
              ...order,
              status,
            };
          })
          .sort((a, b) => {
            const da =
              a.deliveryDate.period === DeliveryDatePeriod.AM
                ? setHours(new Date(a.deliveryDate.date), 9)
                : setHours(new Date(a.deliveryDate.date), 12);
            const db =
              b.deliveryDate.period === DeliveryDatePeriod.AM
                ? setHours(new Date(b.deliveryDate.date), 9)
                : setHours(new Date(b.deliveryDate.date), 12);

            return compareDesc(da, db);
          })
      );
    },
    []
  );

  return (
    <div style={{ display: "flex" }}>
      <Column
        label="En attente"
        orders={orders}
        status={OrderStatus.Paid}
        prev={null}
        next={(order) => changeStatus(order, OrderStatus.Order)}
      />
      <Column
        label={
          <>
            En pré-commande
            <Fab
              sx={{ margin: "0 20px" }}
              component={Link}
              to="/order-prepare"
              target="_blank"
              rel="noopener noreferrer"
              size="small"
            >
              <FactCheck />
            </Fab>
          </>
        }
        orders={orders}
        status={OrderStatus.Order}
        prev={(order) => changeStatus(order, OrderStatus.Paid)}
        next={(order) => changeStatus(order, OrderStatus.Prepare)}
      />
      <Column
        label="En préparation"
        orders={orders}
        status={OrderStatus.Prepare}
        prev={(order) => changeStatus(order, OrderStatus.Order)}
        next={(order) => changeStatus(order, OrderStatus.Delivery)}
      />
      <Column
        label="Prêt à livrer"
        orders={orders}
        status={OrderStatus.Delivery}
        prev={(order) => changeStatus(order, OrderStatus.Prepare)}
        next={null}
      />
    </div>
  );
}
