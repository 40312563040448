import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Skeleton from "@mui/material/Skeleton";
import MuiSelect from "@mui/material/Select";
import { Control, useController } from "react-hook-form";
import { findAll } from "../../utils/axios";
import ErrorMessage from "./ErrorMessage";

interface OptionType {
  label: string;
  value: string;
}

interface Props<T> {
  id: string;
  name: string;
  label: string;
  control: Control<any>;
  url: string;
  getLabelAndValue?: (option: T) => OptionType;
}

const defaultGetLabelAndValue = (doc: any): OptionType => ({
  label: doc.name,
  value: doc._id,
});

export default function SelectAsync<T>({
  control,
  label,
  name,
  id,
  url,
  getLabelAndValue = defaultGetLabelAndValue,
}: Props<T>): React.ReactElement {
  const [options, setOptions] = useState<OptionType[]>([]);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    findAll<T>(url).then((docs) => {
      setOptions(docs.map(getLabelAndValue));
    });
  }, [getLabelAndValue, url]);

  return (
    <FormControl error={!!error} sx={{ mt: 2, width: "100%" }}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      {options.length === 0 ? (
        <Skeleton variant="rectangular" height={56} />
      ) : (
        <MuiSelect
          id={id}
          aria-label={label}
          label={label}
          {...field}
          value={field.value || ""}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
      )}

      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}

SelectAsync.defaultProps = {
  getLabelAndValue: defaultGetLabelAndValue,
};
