import { Route } from "react-router-dom";
import FilterValueList from "./FilterValueList";
import FilterList from "./FilterList";

export default (
  <Route path="filter">
    <Route index element={<FilterList />} />
    <Route path=":id" element={<FilterValueList />} />
  </Route>
);
