import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import InputText from "../../components/Field/InputText";
import Button from "../../components/Field/Button";
import {
  FilterValue,
  defaultFilterValue,
  filterValueDbToDto,
  FilterValueDto,
} from "../../types";
import { filterValueCreate, filterValueUpdate } from "../../api";
import PopupForm from "../../components/Element/PopupForm";

type Props = {
  filterId: string;
  filterValue?: FilterValue | null;
  onFinish?: (filter: FilterValue | null) => any;
};

const FilterValueForm = ({ filterId, filterValue, onFinish }: Props) => {
  const id = filterValue ? filterValue?._id : null;
  const { control, handleSubmit, setFocus } = useForm<FilterValueDto>({
    defaultValues: filterValue
      ? filterValueDbToDto(filterValue)
      : { ...defaultFilterValue },
  });

  useEffect(() => {
    const id = setTimeout(() => {
      setFocus("value");
    }, 200);
    return () => clearTimeout(id);
  }, [setFocus]);

  const onSubmit: SubmitHandler<FilterValueDto> = async (data) => {
    const res = await (id
      ? filterValueUpdate(filterId, id, data)
      : filterValueCreate(filterId, data));
    if (typeof onFinish === "function") onFinish(res);
  };

  return (
    <PopupForm
      title="Filtre"
      onClose={() => {
        if (typeof onFinish === "function") onFinish(null);
      }}
      onSave={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputText label="Nom" name="value" control={control} />
        <Button
          sx={{ mt: "50px", mb: "50px" }}
          type="submit"
          label={id ? "Modifier" : "Ajouter"}
        />
      </form>
    </PopupForm>
  );
};

export default FilterValueForm;
