import axios from "../utils/axios";
import { PromoCodeDto, PromoCode, PaginateResult } from "../types";

export const promoCodePage = async (
  page = 1,
  params: any = {}
): Promise<PaginateResult<PromoCode>> => {
  return axios
    .get(`/promo-code`, {
      params: { page, ...params },
    })
    .then((res) => res.data)
    .catch(console.error);
};

export const promoCodeCreate = async (
  promoCode: PromoCodeDto
): Promise<PromoCode> => {
  return axios.post(`/promo-code`, promoCode).then((res) => res?.data);
};

export const promoCodeUpdate = async (
  promoCode: PromoCodeDto
): Promise<PromoCode> => {
  const { _id, ...dto } = promoCode;
  return axios.put(`/promo-code/${_id}`, dto).then((res) => res?.data);
};

export const promoCodeDelete = async (
  promoCode: PromoCode
): Promise<PromoCode> => {
  return axios.delete(`/promo-code/${promoCode._id}`).then((res) => res?.data);
};
