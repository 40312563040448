import React, { Fragment, useCallback } from "react";
import { Order, Address as TAddress } from "../../types";
import { price } from "../../utils/price";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import { Helmet } from "react-helmet";
import {
  StyledAddress,
  StyledInvoice,
  Me,
  Customer,
  Print,
  Info,
  Content,
  Footer,
  Logo,
  Tva,
  GlobalStyle,
} from "./Invoice.style";

type AddressProps = {
  address: TAddress;
};

export const Address = ({ address }: AddressProps) => {
  const content = [];

  if (address?.company) {
    content.push(<strong>{address.company}</strong>);
  }

  if (address?.firstName || address?.lastName) {
    const name = `${address?.firstName} ${address?.lastName}`;
    if (address?.company) {
      content.push(name);
    } else {
      content.push(<strong>{name}</strong>);
    }
  }

  if (address?.address) {
    content.push(address.address);
  }

  if (address?.address2) {
    content.push(address.address2);
  }

  if (address?.zip || address?.city) {
    content.push(`${address?.zip} ${address?.city?.city}`);
  }

  const jsxContent = content.reduce<(string | React.ReactElement)[]>(
    (acc, line) => {
      acc.push(<React.Fragment key={line + "content"}>{line}</React.Fragment>);
      acc.push(<br key={line + "br"} />);
      return acc;
    },
    []
  );

  return <StyledAddress>{content.length > 0 && jsxContent}</StyledAddress>;
};

export const Invoice = ({ order }: { order: Order }) => {
  const print = useCallback(() => {
    window.print();
  }, []);

  const data = order.data;

  const invoiceOn = format(
    new Date(order.invoiceOn || new Date()),
    "eeee d MMMM yyyy",
    {
      locale: fr,
    }
  );

  const colSpan = 5;
  return (
    <StyledInvoice>
      <GlobalStyle />
      <Helmet>
        <title>{`e-picurien_facture_${order.invoiceNumber || "-"}`}</title>
      </Helmet>

      <Me>
        <StyledAddress>
          <strong>SARL e-Picurien</strong>
          <br />
          523 Maison Neuve
          <br />
          42320 Saint Christo en Jarez
          <br />
          contact@e-picurien.com
          <br />
          <br />
          SIREN: 911256824
          <br />
          Capital social: 8 500 €<br />
        </StyledAddress>
      </Me>

      <Logo>
        <img src="/logo/logo.svg" alt="Logo E-Picurien" width={180} />
      </Logo>

      <Print>
        <button onClick={print}>Imprimer</button>
      </Print>

      <Info>
        <div>
          <h1>Facture</h1>
          <p>
            Date : {invoiceOn}
            <br />
            Numéro : {order.invoiceNumber}
            <br />
            Commande : {order.number}
          </p>
        </div>
      </Info>

      <Customer>
        {order.invoiceAddress && <Address address={order.invoiceAddress} />}
      </Customer>

      <Content>
        <table className="products">
          <thead>
            <tr>
              <th rowSpan={2}>Ref</th>
              <th rowSpan={2}>Produit</th>
              <th rowSpan={2} className="center">
                % TVA
              </th>
              <th rowSpan={2} className="center">
                PU HT
              </th>
              <th rowSpan={2} className="center">
                Qté
              </th>
              <th colSpan={3} className="center">
                Total
              </th>
            </tr>
            <tr>
              <th className="center">HT</th>
              <th className="center">TVA</th>
              <th className="center">TTC</th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((item) => {
              return (
                <Fragment key={item.variant._id}>
                  <tr className="item-product">
                    <td className="ref">{item.product.ref}</td>
                    <td className="name">
                      <strong>{item.product.name}</strong>
                    </td>
                    <td rowSpan={2} className="right">
                      {item.tva}%
                    </td>
                    <td rowSpan={2} className="right">
                      {price(item.pu.ht / 100, true)}
                    </td>
                    <td rowSpan={2} className="center">
                      {item.quantity}
                    </td>
                    <td rowSpan={2} className="right">
                      {price(item.total.ht / 100, true)}
                    </td>
                    <td rowSpan={2} className="right">
                      {price(item.total.tva / 100, true)}
                    </td>
                    <td rowSpan={2} className="right">
                      {price(item.total.ttc / 100, true)}
                    </td>
                  </tr>
                  <tr className="item-variant">
                    <td className="ref">{item.variant.ref}</td>
                    <td className="name">
                      <em>{item.variant.name}</em>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={colSpan} className="right">
                Sous-total
              </td>
              <td className="right">{price(data.totalItems.ht / 100, true)}</td>
              <td className="right">
                {price(data.totalItems.tva / 100, true)}
              </td>
              <td className="right">
                {price(data.totalItems.ttc / 100, true)}
              </td>
            </tr>
            {data.promoCodes.map((promoCode) => {
              return (
                <tr key={promoCode._id}>
                  <td colSpan={colSpan} className="right">
                    Réduction {promoCode.name}
                  </td>
                  <td className="right">
                    -{price(promoCode.total.ht / 100, true)}
                  </td>
                  <td className="right">
                    -{price(promoCode.total.tva / 100, true)}
                  </td>
                  <td className="right">
                    -{price(promoCode.total.ttc / 100, true)}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={colSpan} className="right">
                Frais de port
              </td>
              {data.totalDelivery.ttc > 0 && (
                <>
                  <td className="right">
                    {price(data.totalDelivery.ht / 100, true)}
                  </td>
                  <td className="right">
                    {price(data.totalDelivery.tva / 100, true)}
                  </td>
                  <td className="right">
                    {price(data.totalDelivery.ttc / 100, true)}
                  </td>
                </>
              )}
              {data.totalDelivery.ttc === 0 && (
                <>
                  <td colSpan={colSpan} className="right">
                    Offert
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td colSpan={colSpan} className="right">
                <strong>Total</strong>
              </td>
              <td className="right">{price(data.total.ht / 100, true)}</td>
              <td className="right">{price(data.total.tva / 100, true)}</td>
              <td className="right">
                <strong>{price(data.total.ttc / 100, true)}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </Content>

      <Tva>
        <table>
          <thead>
            <tr>
              <th>% TVA</th>
              <th>Base de calcul</th>
              <th>TVA</th>
              <th>Total TTC</th>
            </tr>
          </thead>
          <tbody>
            {data.tvas.map((tva) => {
              return (
                <tr key={tva.tva}>
                  <td className="right">{tva.tva}%</td>
                  <td className=" right">{price(tva.total.ht / 100, true)}</td>
                  <td className="right">{price(tva.total.tva / 100, true)}</td>
                  <td className="right">{price(tva.total.ttc / 100, true)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td className="right">Total :</td>
              <td className=" right">{price(data.total.ht / 100, true)}</td>
              <td className="right">{price(data.total.tva / 100, true)}</td>
              <td className="right">{price(data.total.ttc / 100, true)}</td>
            </tr>
          </tfoot>
        </table>
      </Tva>

      <Footer>
        <p>
          e-Picurien vous a envoyé cette facture le {invoiceOn}. Celle-ci doit
          être réglée sous 30 jour(s) à compter de cette date. Passé ce délai,
          une pénalité de retard de 10 % sera appliquée, ainsi qu'une indemnité
          forfaitaire de 40€ due au titre des frais de recouvrement. <br />
          Pas d'escompte pour règlement anticipé.
        </p>
      </Footer>
    </StyledInvoice>
  );
};
