import axios from "../utils/axios";
import { ProductDto, Product, PaginateResult, SelectOption } from "../types";

export const productPage = async (
  page = 1,
  query: any = {}
): Promise<PaginateResult<Product>> => {
  return axios
    .get(`/product`, {
      params: { page, limit: 9, ...query },
    })
    .then((res) => res.data)
    .catch(console.error);
};

export const productFindOne = async (id: string): Promise<Product | null> => {
  return axios
    .get<Product | null>(`/product/${id}`)
    .then((res) => res?.data || null);
};

export const productCreate = async (product: ProductDto): Promise<Product> => {
  return axios.post(`/product`, product).then((res) => res?.data);
};

export const productUpdate = async (
  id: string,
  product: ProductDto
): Promise<Product> => {
  return axios.put(`/product/${id}`, product).then((res) => res?.data);
};

export const productDelete = async (id: string): Promise<Product> => {
  return axios.delete(`/product/${id}`).then((res) => res?.data);
};

export const productFilterFindAll = async (query: any = {}): Promise<any> => {
  return axios
    .get(`/product-filter`, {
      params: query,
    })
    .then((res) => res?.data)
    .catch(console.error);
};

// For select

export const productToSelectOption = (product: Product): SelectOption => {
  return {
    label: product.name,
    value: product._id,
  };
};
export const productFindAllForSelect = async (
  s: string = ""
): Promise<SelectOption[]> => {
  return productPage(1, { s }).then((res) => {
    return res.docs.map(productToSelectOption);
  });
};
export const productFindOneForSelect = async (
  id: string
): Promise<SelectOption | null> => {
  const product = await productFindOne(id);
  if (!product) return null;
  return productToSelectOption(product);
};
