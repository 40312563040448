import React from "react";
import { Route } from "react-router-dom";
import SupplierList from "./SupplierList";
import SupplierCreate from "./SupplierCreate";
import SupplierUpdate from "./SupplierUpdate";
import SupplierDelete from "./SupplierDelete";

export default (
  <Route path="supplier">
    <Route index element={<SupplierList />} />
    <Route path="add" element={<SupplierCreate />} />
    <Route path="update/:id" element={<SupplierUpdate />} />
    <Route path="delete/:id" element={<SupplierDelete />} />
  </Route>
);
