import axios from "../utils/axios";
import {
  DeliveryDate,
  DeliveryDateDto,
  DeliveryDatePeriod,
  SelectOption,
} from "../types";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export const deliveryDateFindAll = async (): Promise<DeliveryDate[]> => {
  return axios
    .get(`/delivery-date`)
    .then((res) => res.data)
    .catch(console.error);
};

export const deliveryDateFindAllByClickAndCollect = async (
  id: string | null
): Promise<DeliveryDate[]> => {
  const params: any = {};
  if (id === null) {
    params.home = 1;
  }
  if (typeof id === "string") {
    params.clickAndCollect = id;
  }
  return axios
    .get(`/delivery-date`, { params })
    .then((res) => res.data)
    .catch(console.error);
};

export const deliveryDateFindOne = async (
  id: string
): Promise<DeliveryDate[]> => {
  return axios
    .get(`/delivery-date/${id}`)
    .then((res) => res.data)
    .catch(console.error);
};

export const deliveryDateCreate = async (
  deliveryDateDto: DeliveryDateDto
): Promise<DeliveryDate> => {
  return axios
    .post(`/delivery-date`, deliveryDateDto)
    .then((res) => res.data)
    .catch(console.error);
};

export const deliveryDateUpdate = async (
  id: string,
  deliveryDateDto: DeliveryDateDto
): Promise<DeliveryDate> => {
  return axios
    .put(`/delivery-date/${id}`, deliveryDateDto)
    .then((res) => res.data)
    .catch(console.error);
};

export const deliveryDateDelete = async (id: string): Promise<DeliveryDate> => {
  return axios
    .delete(`/delivery-date/${id}`)
    .then((res) => res.data)
    .catch(console.error);
};

export const deliveryDateToSelect = (
  deliveryDate: DeliveryDate
): SelectOption => {
  const period =
    deliveryDate.period === DeliveryDatePeriod.AM ? "10h -> 13h" : "17h -> 21h";
  const date = format(new Date(deliveryDate.date), "iiii dd MMMM yyyy", {
    locale: fr,
  });
  return {
    value: deliveryDate._id,
    label: `${date} ${period}`,
  };
};

export const deliveryDateFindAllByClickAndCollectForSelect = async (
  id: string | null
): Promise<SelectOption[]> => {
  return deliveryDateFindAllByClickAndCollect(id).then((res) =>
    res.map(deliveryDateToSelect)
  );
};
