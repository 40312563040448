import useTva from "../../hooks/useTva";
import { useParams, useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { Tva } from "../../types";
import TvaForm from "./TvaForm";

export default function TvaList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { tva, isLoading, isError, mutate } = useTva(id);
  const snack = useSnack();

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !tva) {
    return <span>Erreur</span>;
  }

  const onFinish = (tva: Tva) => {
    snack.success(`Le taux de TVA ${tva.name} a bien été modifié.`);
    mutate();
    navigate("../");
  };

  return (
    <>
      <h1>{tva.name}</h1>
      <TvaForm tva={tva} onFinish={onFinish} />
    </>
  );
}
