import React, { useState, useEffect } from "react";
import { Grid, Alert } from "@mui/material";
import InputText from "../components/Field/InputText";
import InputPassword from "../components/Field/InputPassword";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { AuthLoginDto } from "../types";
import { useUser } from "../contexts/UserContext";

const { REACT_APP_DOMAIN } = process.env;

export default function LoginPage() {
  const { login } = useUser();
  const [err, setErr] = useState<null | Error>(null);

  useEffect(() => {
    if (err === null) return;
    const id = setTimeout(() => {
      setErr(null);
    }, 5000);

    return () => clearTimeout(id);
  }, [err]);

  const { control, handleSubmit } = useForm<AuthLoginDto>();
  const onSubmit: SubmitHandler<AuthLoginDto> = async (data) => {
    await login(data).catch(setErr);
  };
  return (
    <Grid container>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <div style={{ textAlign: "center" }}>
          <img src="/logo/logo.svg" width="250" alt="Logo e-picurien" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label="Email"
            type="email"
            name="email"
            control={control}
          />
          <InputPassword
            label="Mot de passe"
            name="password"
            control={control}
          />

          {err && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Impossible de vous connecter avec ces identiants.
            </Alert>
          )}

          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{ mt: 2, mb: 4 }}
          >
            Connexion
          </Button>
          <div>
            <a href={`https://www.${REACT_APP_DOMAIN}/mot-de-passe-oublie`}>
              Mot de passe oublié
            </a>
          </div>
        </form>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
}
