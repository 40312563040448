import React, { useReducer, useEffect, useRef, useState, useMemo } from "react";
import { useController, Control } from "react-hook-form";
import ErrorMessage from "../ErrorMessage";
import P from "bluebird";
import { useSnack } from "../../../contexts/SnackContext";
import { Line } from "rc-progress";
import { FormControl, InputLabel } from "@mui/material";
import { nanoid } from "nanoid";

import reducer, { initialState } from "./reducer";
import { add, del, initMediaValue } from "./actions";

import Dropzone from "./Dropzone";
import Files from "./Files";
import { cleanMedia, ActionType } from "./media.dto";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

type Props = {
  label: string;
  name: string;
  multi: boolean;
  extensions: string[];
  control: Control<any>;
  readOnly: boolean;
  isLoading?: (value: boolean) => any;
};

const Media = ({
  label,
  name,
  multi,
  control,
  extensions,
  isLoading,
  readOnly,
}: Props) => {
  const [id] = useState(`${name}-${nanoid()}`);
  const snack = useSnack();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    medias: initMediaValue(value),
  });

  const sendChange = useMemo(
    () =>
      debounce((val) => {
        if (readOnly) {
          return;
        }
        onChange(val);
      }, 1000),
    [readOnly, onChange]
  );

  const oldMedias = useRef(value);
  useEffect(() => {
    const cleaned = state.medias.map(cleanMedia);
    if (isEqual(cleaned, oldMedias.current)) {
      return;
    }
    oldMedias.current = cleaned;
    if (multi) {
      sendChange(state.medias.map(cleanMedia));
      return;
    }

    sendChange(cleaned[0] || null);
  }, [state.medias, multi, readOnly, sendChange]);

  const loading = useRef(state.pc < 100);
  useEffect(() => {
    if (typeof isLoading !== "function") {
      return;
    }

    const load = state.pc < 100;
    if (loading.current === load) {
      return;
    }

    loading.current = load;
    isLoading(loading.current);
  }, [state.pc, isLoading]);

  return (
    <FormControl
      error={!!error}
      sx={{ pt: 4, width: "100%" }}
      variant="outlined"
    >
      <InputLabel htmlFor={id} sx={{ fontSize: "0.85rem" }}>
        {label}
      </InputLabel>

      <Files
        state={state}
        del={(media) => del(media, dispatch)}
        move={(a, b) => dispatch({ type: ActionType.MOVE, payload: { a, b } })}
        update={(media) =>
          dispatch({ type: ActionType.UPDATE, payload: { media } })
        }
        readOnly={readOnly}
      />

      {(multi || state.medias.length === 0) && !readOnly && (
        <>
          <Dropzone
            name={name}
            multi={multi}
            extensions={extensions}
            id={name}
            onDrop={(medias) => {
              P.map(medias, (media) => add(media, dispatch, extensions, snack));
            }}
          />
          {state.pc < 100 && (
            <Line percent={Math.round(state.pc)} strokeColor="#ACC81B" />
          )}
        </>
      )}
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
};

Media.defaultProps = {
  multi: false,
  readOnly: false,
  extensions: [],
};

export default Media;
