import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

if (process.env.REACT_APP_NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: `${process.env.REACT_APP_NODE_ENV}_admin`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: false,
  });
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
