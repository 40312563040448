import PromoCodeForm from "./PromoCodeForm";
import { useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { PromoCode } from "../../types";

export default function PromoCodeList() {
  const navigate = useNavigate();
  const snack = useSnack();

  const onFinish = (promoCode: PromoCode) => {
    snack.success(`${promoCode.name} a bien été créé.`);
    navigate("/promo-code");
  };

  return (
    <>
      <h1>Créer un code promo</h1>
      <PromoCodeForm onFinish={onFinish} />
    </>
  );
}
