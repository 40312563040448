import usePromoCode from "../../hooks/usePromoCode";
import { useParams, useNavigate } from "react-router-dom";
import Delete from "../../components/Form/Delete";

export default function PromoCodeList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { promoCode, isLoading, isError } = usePromoCode(id);

  if (isLoading) {
    return <span>chargement...</span>;
  }
  if (isError || !promoCode) {
    return <span>Erreur</span>;
  }

  const onFinish = () => {
    navigate("../");
  };

  return (
    <>
      <h1>Supprimer {promoCode.name}</h1>
      <Delete
        url={`/promo-code/${promoCode._id}`}
        name={promoCode.name}
        onFinish={onFinish}
      />
    </>
  );
}
