import React, { useCallback, useState, useEffect } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import PopupForm from "../../Element/PopupForm";
import AddressForm from "./form";
import styled from "styled-components";
import { zipFindById } from "../../../api/zip";

const StyledAddress = styled.button<{ editable: boolean }>`
  display: inline-block;
  padding: 15px 60px 15px 15px;
  margin: 15px 15px 15px 0;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: ${({ editable }) => (editable ? "pointer" : "default")};
  color: rgba(0, 0, 0, 0.8);
  background-color: white;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.33);
    color: rgba(0, 0, 0, 1);
    cursor: ${({ editable }) => (editable ? "pointer" : "default")};
    .edit svg {
      fill: blue;
    }
  }

  address {
    display: block;
    margin-top: 10px;
    text-align: left;
  }

  label {
    display: block;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-align: left;
    cursor: ${({ editable }) => (editable ? "pointer" : "default")};
    font-size: 18px;
  }

  .edit {
    position: absolute;
    top: 5px;
    right: 5px;
    svg {
      fill: rgba(0, 0, 0, 0.54);
    }
  }
`;

type Props = {
  control: Control<any>;
  label: string;
  name: string;
  disable?: string[];
  setValue: SetFieldValue<any>;
  editable?: boolean;
};

function Address({
  control,
  label,
  name,
  disable = [],
  setValue,
  editable = true,
}: Props): React.ReactElement {
  const [city, setCity] = useState<string | null>("");
  const [isOpen, setIsOpen] = useState(false);
  const value = useWatch({ control, name });

  useEffect(() => {
    if (!value.city) {
      setCity("");
      return;
    }
    zipFindById(value.city).then((zip) => setCity(zip?.city || ""));
  }, [value.city]);

  const show = useCallback(
    (key: string, nl = true) => {
      if (!value[key]) return null;
      return (
        <>
          {value[key]}
          {nl && <br />}
        </>
      );
    },
    [value]
  );

  return (
    <>
      {editable && isOpen && (
        <PopupForm
          onSave={() => setIsOpen(false)}
          onClose={() => setIsOpen(false)}
          title={label}
        >
          <AddressForm
            control={control}
            onClose={() => setIsOpen(false)}
            label={label}
            name={name}
            disable={disable}
            setValue={setValue}
          />
        </PopupForm>
      )}
      <StyledAddress
        as={editable ? "button" : "div"}
        editable={editable}
        type="button"
        onClick={() => editable && setIsOpen(true)}
      >
        {editable && (
          <div className="edit">
            <EditIcon />
          </div>
        )}
        <label>{label}</label>
        <address>
          {show("company")}
          {show("firstName", false)} {show("lastName")}
          {show("address")}
          {show("address2")}
          {show("zip", false)} {city}
          {(value.zip || city) && <br />}
          {show("phone", false)}
        </address>
      </StyledAddress>
    </>
  );
}

export default Address;
