import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScrollRef from "../../components/ScrollRef";
import usePromoCodes from "../../hooks/usePromoCodes";
import { PromoCodeType, PromoCode } from "../../types";
import LinkEdit from "../../components/Element/LinkEdit";
import LinkDelete from "../../components/Element/LinkDelete";
import LinkAdd from "../../components/Element/LinkAdd";
import LinkCustom from "../../components/Element/LinkCustom";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Search from "../../components/Field/Search";
import { Button } from "@mui/material";

export default function PromoCodeList() {
  const [search, setSearch] = useState("");
  const { promoCodes, size, setSize } = usePromoCodes(`&s=${search}`);
  const [show, setShow] = useState<string>("");

  useEffect(() => {
    if (!show) return;

    const id = setTimeout(() => {
      setShow("");
    }, 5000);

    return () => clearTimeout(id);
  }, [show]);

  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/promo-code/add`} />
      </div>
      <h1>Liste des produits</h1>
      <Search onChange={setSearch} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Consommé</TableCell>
              <TableCell>Cumulable</TableCell>
              <TableCell align="right">Montant</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(promoCodes || []).map((promoCode: PromoCode) => (
              <TableRow
                key={promoCode._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{promoCode.name}</TableCell>
                <TableCell>
                  {show !== promoCode._id && (
                    <Button onClick={() => setShow(promoCode._id)}>
                      Afficher le code
                    </Button>
                  )}
                  {show === promoCode._id && <code>{promoCode.code}</code>}
                </TableCell>
                <TableCell>
                  {promoCode.uniq ? "Usage unique" : "Usage multiple"}
                </TableCell>
                <TableCell>
                  {!promoCode.uniq && "-"}
                  {promoCode.uniq && <>{promoCode.used ? "OUI" : "NON"}</>}
                </TableCell>
                <TableCell>{promoCode.cumulative ? "OUI" : "NON"}</TableCell>
                <TableCell align="right">
                  {promoCode.value}
                  {promoCode.type === PromoCodeType.Percent ? "%" : "€"}
                </TableCell>
                <TableCell align="right">
                  {!(promoCode.uniq && promoCode.used) && (
                    <LinkEdit url={`/promo-code/update/${promoCode._id}`} />
                  )}
                  <LinkCustom
                    url={`/promo-code/order/${promoCode._id}`}
                    icon={<ShoppingBagIcon />}
                  />
                  <LinkDelete url={`/promo-code/delete/${promoCode._id}`} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ScrollRef load={() => setSize(size + 1)} />
    </>
  );
}
