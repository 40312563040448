import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import {
  StyledOrder,
  Print,
  Content,
  Logo,
  GlobalStyle,
} from "./Preparation.style";
import { orderPrepare } from "../../api";
import { OrderPrepare, purchaseUnityListIndex } from "../../types";

export const Preparation = () => {
  const [prepare, setPrepare] = useState<OrderPrepare>([]);
  const print = useCallback(() => {
    window.print();
  }, []);

  useEffect(() => {
    orderPrepare().then(setPrepare);
  }, []);

  const createOn = format(new Date(new Date()), "dd-MMMM-yyyy");

  return (
    <StyledOrder>
      <GlobalStyle />
      <Helmet>
        <title>{`e-picurien_preparation_${createOn}`}</title>
      </Helmet>

      <Logo>
        <img src="/logo/logo.svg" alt="Logo E-Picurien" width={180} />
      </Logo>

      <Print>
        <button onClick={print}>Imprimer</button>
      </Print>

      <Content>
        {prepare.map((supplier) => {
          return (
            <div key={supplier._id}>
              <h2>{supplier.name}</h2>
              <table className="products">
                <thead>
                  <tr>
                    <th className="ref">Ref</th>
                    <th className="name">
                      Produit{supplier.purchases.length > 1 && "s"}
                    </th>
                    <th className="quantity">
                      Quantité{supplier.purchases.length > 1 && "s"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {supplier.purchases.map((item) => {
                    return (
                      <tr key={item.purchase._id}>
                        <td className="ref">{item.purchase.ref}</td>
                        <td className="name">{item.purchase.name}</td>
                        <td className="center">
                          {item.quantity}{" "}
                          {purchaseUnityListIndex.get(item.purchase.unity) ||
                            item.purchase.unity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </Content>
    </StyledOrder>
  );
};
