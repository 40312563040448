import { useState, useEffect, useMemo, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BtnDelete from "../../components/Element/BtnDelete";
import Paper from "@mui/material/Paper";
import {
  format,
  compareAsc,
  addDays,
  previousThursday,
  setHours,
  startOfHour,
} from "date-fns";
import localefr from "date-fns/locale/fr";
import nextFriday from "date-fns/nextFriday";
import Button from "../../components/Field/Button";

import styled from "styled-components";
import { ClickAndCollect, DeliveryDate, DeliveryDatePeriod } from "../../types";
import DeliveryDateForm from "./DateForm";
import {
  deliveryDateCreate,
  deliveryDateFindAllByClickAndCollect,
  deliveryDateDelete,
} from "../../api";
import { Grid } from "@mui/material";

type Fast = {
  date: Date;
  period: DeliveryDatePeriod;
  label: string;
};

const Styled = styled.div`
  tr:nth-child(even) {
    background-color: #f8f8f0;
  }
`;

type Props = {
  clickAndCollect: ClickAndCollect | null;
};

export default function DateItem({ clickAndCollect }: Props) {
  const [nbFast, setNbFast] = useState<number>(7);
  const [dates, setDates] = useState<DeliveryDate[]>([]);

  useEffect(() => {
    deliveryDateFindAllByClickAndCollect(clickAndCollect?._id || null).then(
      setDates
    );
  }, [clickAndCollect]);

  const genKey = useCallback((date: Date, period: DeliveryDatePeriod) => {
    return `${format(new Date(date), "dd-MM-yyyy")} ${period}`;
  }, []);

  const getLabel = useCallback((date: Date, period: DeliveryDatePeriod) => {
    return `${format(new Date(date), "eeee d LLLL yyyy", {
      locale: localefr,
    })} ${period === DeliveryDatePeriod.AM ? "10h -> 13h" : "17h -> 21h"}`;
  }, []);

  const fasts: Fast[] = useMemo(() => {
    const keys = new Set(dates.map((d) => genKey(d.date, d.period)));

    const btns = [];
    let date = startOfHour(setHours(new Date(), 12));
    while (btns.length <= nbFast) {
      // Vendredi
      date = nextFriday(date);
      if (!keys.has(genKey(date, DeliveryDatePeriod.PM))) {
        btns.push({
          date: new Date(date),
          period: DeliveryDatePeriod.PM,
          label: getLabel(date, DeliveryDatePeriod.PM),
        });
      }
      // Samedi
      date = addDays(date, 1);
      if (!keys.has(genKey(date, DeliveryDatePeriod.AM))) {
        btns.push({
          date: new Date(date),
          period: DeliveryDatePeriod.AM,
          label: getLabel(date, DeliveryDatePeriod.AM),
        });
      }
      if (!keys.has(genKey(date, DeliveryDatePeriod.PM))) {
        btns.push({
          date: new Date(date),
          period: DeliveryDatePeriod.PM,
          label: getLabel(date, DeliveryDatePeriod.PM),
        });
      }
      // Dimanche
      date = addDays(date, 1);
      if (!keys.has(genKey(date, DeliveryDatePeriod.AM))) {
        btns.push({
          date: new Date(date),
          period: DeliveryDatePeriod.AM,
          label: getLabel(date, DeliveryDatePeriod.AM),
        });
      }
    }

    return btns.splice(0, nbFast);
  }, [dates, genKey, getLabel, nbFast]);

  const handleAddDate = useCallback(
    (date: DeliveryDate) => {
      setDates(
        [date, ...dates.filter((d) => d.key !== date.key)].sort((a, b) =>
          compareAsc(new Date(a.date), new Date(b.date))
        )
      );
    },
    [dates]
  );

  const addFastDate = useCallback(
    async (fast: Fast) => {
      const lastSuperFreshCommand = startOfHour(
        setHours(previousThursday(fast.date), 12)
      );
      const res = await deliveryDateCreate({
        clickAndCollect: clickAndCollect?._id || null,
        date: fast.date,
        period: fast.period,
        lastCommand: nextFriday(lastSuperFreshCommand),
        lastSuperFreshCommand: lastSuperFreshCommand,
      });
      handleAddDate(res);
    },
    [clickAndCollect, handleAddDate]
  );

  const handleDeleteDate = useCallback(
    async (date: DeliveryDate) => {
      const label = getLabel(date.date, date.period);
      if (!window.confirm(`Supprimer la livraison du ${label} ?`)) return;
      await deliveryDateDelete(date._id);
      setDates(dates.filter((d) => d._id !== date._id));
    },
    [dates, getLabel]
  );

  return (
    <Styled>
      <h2>{clickAndCollect?.name || "Livraison à domicile"}</h2>
      <DeliveryDateForm
        clickAndCollect={clickAndCollect}
        onFinish={handleAddDate}
      />
      <div style={{ margin: "40px 0" }}>
        <Grid container spacing={1}>
          {fasts.map((fast) => {
            return (
              <Grid key={genKey(fast.date, fast.period)} item xs={3}>
                <Button
                  type="button"
                  color={"primary"}
                  label={fast.label}
                  onClick={() => addFastDate(fast)}
                />
              </Grid>
            );
          })}
          <Grid item xs={3}>
            <Button
              type="button"
              color="secondary"
              label="+ Ajouter 4 dates"
              onClick={() => setNbFast(nbFast + 4)}
            />
          </Grid>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Jour de livraison</TableCell>
              <TableCell>Date de livraison</TableCell>
              <TableCell>Période</TableCell>
              <TableCell>Dernière commande</TableCell>
              <TableCell>Dernière commande Ultra frais</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(dates || []).map((date: DeliveryDate) => (
              <TableRow
                key={date._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {format(new Date(date.date), "eeee", {
                    locale: localefr,
                  })}
                </TableCell>
                <TableCell>
                  {format(new Date(date.date), "d LLLL yyyy", {
                    locale: localefr,
                  })}
                </TableCell>
                <TableCell>
                  {date.period === DeliveryDatePeriod.AM
                    ? "10h -> 13h"
                    : "17h -> 21h"}
                </TableCell>
                <TableCell>
                  {format(
                    new Date(date.lastCommand),
                    "eeee d LLLL yyyy HH:mm",
                    { locale: localefr }
                  )}
                </TableCell>
                <TableCell>
                  {format(
                    new Date(date.lastSuperFreshCommand),
                    "eeee d LLLL yyyy HH:mm",
                    { locale: localefr }
                  )}
                </TableCell>
                <TableCell align="right">
                  <BtnDelete onClick={() => handleDeleteDate(date)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Styled>
  );
}
