import React from "react";

import BtnDelete from "../../../components/Element/BtnDelete";
import { Grid, Button as MuiButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useFieldArray, Control } from "react-hook-form";
import ProductVariantField from "./Variant";

type Props = {
  control: Control<any>;
  name: string;
};

export default function Variants({ name, control }: Props) {
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  return (
    <div style={{ marginTop: "20px" }}>
      <h2>Produits</h2>
      {fields.map((field, index) => {
        return (
          <Grid container key={field.id} alignItems="center">
            <Grid item xs={11}>
              <ProductVariantField
                name={`${name}.${index}`}
                control={control}
              />
            </Grid>
            <Grid
              item
              xs={1}
              alignSelf="center"
              container
              justifyContent="center"
            >
              <Grid item>
                <BtnDelete onClick={() => remove(index)} />
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      <MuiButton
        variant="contained"
        style={{ margin: "15px 0" }}
        onClick={() => append({ product: "", productVariant: "", quantity: 1 })}
      >
        <AddIcon /> Ajouter un produit
      </MuiButton>
    </div>
  );
}
