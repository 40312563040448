import { Media, ProductType } from ".";
import * as yup from "yup";
import { Tva } from "./tva";
import { Purchase } from "./purchase";

export type ProductVariantPurchaseDto = {
  item: string;
  quantity: number;
};

export type ProductVariantDto = {
  name: string;
  ref: string;
  quantity: number;
  price: number;
  isActive: boolean;
  purchases: ProductVariantPurchaseDto[];
};

export type ProductVariantPurchase = {
  item: Purchase;
  quantity: number;
};

export type ProductVariantProduct = {
  _id: string;
  name: string;
  slug: string;
  ref: string;
  isSuperFresh: boolean;
  supplier: string;
  photo: Media;
  isActive: boolean;
  type: ProductType;
};

export type ProductVariant = {
  _id: string;
  name: string;
  ref: string;
  quantity: number;
  price: number;
  isActive: boolean;
  purchases: ProductVariantPurchase[];
  product: ProductVariantProduct;
  tva: Tva;
};

export const defaultProductVariant: ProductVariantDto = {
  name: "",
  ref: "",
  isActive: false,
  quantity: 1,
  price: 0,
  purchases: [],
};

export const productVariantDbToDto = (
  variant: ProductVariant
): ProductVariantDto => {
  const v = variant;
  return {
    name: v?.name,
    ref: v?.ref,
    isActive: v.isActive || false,
    quantity: v?.quantity || 1,
    price: v.price || 0,
    purchases: (v.purchases || []).map((purchase) => ({
      item: purchase.item._id,
      quantity: purchase.quantity,
    })),
  };
};

export const productVariantSchema = yup.object({
  name: yup.string().required(),
  ref: yup.string().required(),
  price: yup.number().min(0).required(),
  isActive: yup.boolean().required(),
  purchase: yup.array().of(
    yup.object({
      purchase: yup.string().required(),
      quantity: yup.number().min(0).required(),
    })
  ),
});
