import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScrollRef from "../../components/ScrollRef";
import useOrders from "../../hooks/useOrders";
import { Order, orderStatusIndex } from "../../types";
import LinkShow from "../../components/Element/LinkShow";
import LinkAdd from "../../components/Element/LinkAdd";
import LinkEdit from "../../components/Element/LinkEdit";
import Search from "../../components/Field/Search";
import price from "../../utils/price";
import { format } from "date-fns";
import LinkCustom from "../../components/Element/LinkCustom";
import Receipt from "@mui/icons-material/Receipt";

export default function OrderList() {
  const [search, setSearch] = useState("");
  const { orders, size, setSize } = useOrders(
    `&s=${search}&sort=order&desc=order`
  );

  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/order/add`} />
      </div>
      <h1>Liste des commandes</h1>
      <Search onChange={setSearch} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Commande</TableCell>
              <TableCell>Facture</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date de paiement</TableCell>
              <TableCell>Date de livraison</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(orders || []).map((order: Order) => (
              <TableRow
                key={order._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>#{order?.number}</TableCell>
                <TableCell>
                  {order.invoiceNumber ? <>n°{order?.invoiceNumber}</> : "-"}
                </TableCell>
                <TableCell>{orderStatusIndex.get(order.status)}</TableCell>
                <TableCell>{order?.user?.email}</TableCell>
                <TableCell>
                  {order.payOn
                    ? format(new Date(order.payOn), "dd/MM/yyyy HH:mm")
                    : "-"}
                </TableCell>
                <TableCell>
                  {order.deliveryOn
                    ? format(new Date(order.deliveryOn), "dd/MM/yyyy HH:mm")
                    : "-"}
                </TableCell>
                <TableCell>{price(order.total)}</TableCell>
                <TableCell align="right">
                  <LinkShow url={`/order/${order._id}/show`} target="_blank" />
                  {order.invoiceNumber && (
                    <LinkCustom
                      url={`/invoice/${order._id}/show`}
                      icon={<Receipt />}
                      target="_blank"
                    />
                  )}
                  {!order.invoiceNumber && (
                    <LinkEdit url={`/order/${order._id}/update`} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ScrollRef load={() => setSize(size + 1)} />
    </>
  );
}
