import { useFormState, Control } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import get from "lodash/get";

type Props = {
  control: Control<any>;
  name: string;
};

export default function ErrorMessage({
  control,
  name,
}: Props): React.ReactElement | null {
  const { errors } = useFormState({
    control,
    name,
  });

  const error = get(errors, `${name}.message`);

  if (!error) {
    return null;
  }

  return (
    <FormHelperText error sx={{ mb: 1 }}>
      {error}
    </FormHelperText>
  );
}
