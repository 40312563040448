import React from "react";
import InputText from "../../components/Field/InputText";
import InputNumber from "../../components/Field/InputNumber";
import Button from "../../components/Field/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { TvaDto, Tva, tvaDbToDto } from "../../types";
import { tvaCreate, tvaUpdate } from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

type Props = {
  tva?: Tva;
  onFinish?: (tva: Tva) => any;
};

const schema = yup.object({
  name: yup.string().min(1).required(),
  value: yup.number().min(0).required(),
});

const TvaForm = ({ tva, onFinish }: Props) => {
  const { control, handleSubmit } = useForm<TvaDto>({
    defaultValues: tvaDbToDto(tva),
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<TvaDto> = async (data) => {
    let res = await (tva?._id ? tvaUpdate(tva._id, data) : tvaCreate(data));

    if (onFinish) {
      onFinish(res);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputText label="Nom" name="name" control={control} />
      <InputNumber
        label="Valeur en %"
        precision={2}
        name="value"
        control={control}
      />
      <Button type="submit" label={tva?._id ? "Modifier" : "Ajouter"} />
    </form>
  );
};

export default TvaForm;
