import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import InputText from "../../components/Field/InputText";
import Button from "../../components/Field/Button";
import { Filter, defaultFilter, filterDbToDto, FilterDto } from "../../types";
import { filterCreate, filterUpdate } from "../../api";
import PopupForm from "../../components/Element/PopupForm";

type Props = {
  filter?: Filter | null;
  onFinish?: (filter: Filter | null) => any;
};

const FilterForm = ({ filter, onFinish }: Props) => {
  const id = filter ? filter?._id : null;
  const { control, handleSubmit, setFocus } = useForm<FilterDto>({
    defaultValues: filter ? filterDbToDto(filter) : { ...defaultFilter },
  });

  useEffect(() => {
    const id = setTimeout(() => {
      setFocus("name");
    }, 200);
    return () => clearTimeout(id);
  }, [setFocus]);

  const onSubmit: SubmitHandler<FilterDto> = async (data) => {
    const res = await (id ? filterUpdate(id, data) : filterCreate(data));
    if (typeof onFinish === "function") onFinish(res);
  };

  return (
    <PopupForm
      title="Filtre"
      onClose={() => {
        if (typeof onFinish === "function") onFinish(null);
      }}
      onSave={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputText label="Nom interne" name="name" control={control} />
        <InputText label="Nom sur le site" name="label" control={control} />
        <Button
          sx={{ mt: "50px", mb: "50px" }}
          type="submit"
          label={id ? "Modifier" : "Ajouter"}
        />
      </form>
    </PopupForm>
  );
};

export default FilterForm;
