import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScrollRef from "../../components/ScrollRef";
import useSuppliers from "../../hooks/useSuppliers";
import { Supplier } from "../../types";
import LinkEdit from "../../components/Element/LinkEdit";
import LinkDelete from "../../components/Element/LinkDelete";
import LinkAdd from "../../components/Element/LinkAdd";
import { useState } from "react";
import Search from "../../components/Field/Search";

export default function SupplierList() {
  const [search, setSearch] = useState("");
  const { suppliers, size, setSize } = useSuppliers(`&s=${search}`);

  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/supplier/add`} />
      </div>
      <h1>Liste des fournisseurs</h1>
      <Search onChange={setSearch} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(suppliers || []).map((supplier: Supplier) => (
              <TableRow
                key={supplier._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{supplier.name}</TableCell>
                <TableCell align="right">
                  <LinkEdit url={`/supplier/update/${supplier._id}`} />
                  <LinkDelete url={`/supplier/delete/${supplier._id}`} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ScrollRef load={() => setSize(size + 1)} />
    </>
  );
}
