import axios from "../utils/axios";
import {
  Purchase,
  PurchaseDto,
  SelectOption,
  PaginateResult,
  purchaseUnityListIndex,
} from "../types";

export const purchaseFindAll = async (): Promise<Purchase[]> => {
  return axios.get(`/purchase`).then((res) => res?.data);
};

export const purchaseFindOne = async (
  id: string,
  params?: Object
): Promise<Purchase> => {
  return axios
    .get(`/purchase/${id}`, {
      params,
    })
    .then((res) => res?.data);
};

export const purchaseCreate = async (
  purchase: PurchaseDto
): Promise<Purchase> => {
  return axios.post(`/purchase`, purchase).then((res) => res?.data);
};

export const purchaseUpdate = async (
  id: string,
  dto: PurchaseDto
): Promise<Purchase> => {
  return axios.put(`/purchase/${id}`, dto).then((res) => res?.data);
};

export const purchaseDelete = async (id: string): Promise<Purchase> => {
  return axios.delete(`/purchase/${id}`).then((res) => res?.data);
};

export const purchaseToSelectOption = (purchase: Purchase): SelectOption => {
  const unity = purchaseUnityListIndex.get(purchase.unity);
  return {
    label: `${purchase.supplier.name} > ${purchase.name} (${unity})`,
    value: purchase._id,
  };
};

export const purchaseFindAllForSelect = async (
  s: string = ""
): Promise<SelectOption[]> => {
  return axios
    .get<PaginateResult<Purchase>>(`/purchase${s !== "" ? `?s=${s}` : ""}`)
    .then((res) => {
      return res.data.docs.map(purchaseToSelectOption);
    });
};

export const purchaseFindOneForSelect = async (
  id: string
): Promise<SelectOption> => {
  const purchase = await purchaseFindOne(id);
  if (!purchase)
    return {
      label: "",
      value: "- Achat supprimé -",
    };
  return purchaseToSelectOption(purchase);
};
