import { Link, useMatch, useResolvedPath } from "react-router-dom";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import BusinessIcon from "@mui/icons-material/Business";

import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PercentIcon from "@mui/icons-material/Percent";
import PersonIcon from "@mui/icons-material/Person";
import SetMealIcon from "@mui/icons-material/SetMeal";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { ListItemButton } from "@mui/material";

type MenuItemProps = {
  href: string;
  icon: any;
  label: string;
  end?: boolean;
  target?: "_blank";
};

const MenuItem = ({
  href,
  icon,
  label,
  end = false,
  target,
}: MenuItemProps) => {
  let resolved = useResolvedPath(href);
  let isActive = useMatch({ path: resolved.pathname, end });

  return (
    <ListItemButton
      component={Link}
      to={href}
      selected={!!isActive}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export default function Menu() {
  return (
    <>
      <List>
        <MenuItem href="/" label="Home" icon={<QueryStatsIcon />} end />

        <Divider />

        <MenuItem href="/user" label="Utilisateurs" icon={<PersonIcon />} />

        <Divider />

        <MenuItem
          href="/supplier"
          label="Fournisseurs"
          icon={<BusinessIcon />}
        />

        <MenuItem href="/purchase" label="Achats" icon={<ShoppingCartIcon />} />

        <MenuItem href="/filter" label="Filtres" icon={<FilterAltIcon />} />
        <MenuItem href="/product" label="Produits" icon={<SetMealIcon />} />

        <MenuItem
          href="/promo-code"
          label="Réductions"
          icon={<PercentIcon />}
        />

        <MenuItem href="/date" label="Dates" icon={<EventAvailableIcon />} />

        <Divider />

        <MenuItem
          href="/delivery"
          label="Lieux de livraison"
          icon={<LocalShippingIcon />}
        />

        <MenuItem
          href="/click-and-collect"
          label="Click & Collect"
          icon={<StorefrontIcon />}
        />

        <MenuItem
          href="/configuration"
          label="Frais de ports"
          icon={<SettingsIcon />}
        />

        <Divider />

        <MenuItem href="/order" label="Commandes" icon={<ShoppingBagIcon />} />

        <MenuItem
          href="/prepare"
          label="Préparation"
          icon={<FactCheckIcon />}
          end={true}
        />

        <MenuItem
          href="/prepare-delivery"
          label="Livraison"
          icon={<LocalShippingIcon />}
          target="_blank"
        />

        <Divider />

        <MenuItem href="/tva" label="Taux de TVA" icon={<PercentIcon />} />
      </List>
      <Divider />
    </>
  );
}
