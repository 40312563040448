import { mediaSchema, Tva } from ".";
import * as yup from "yup";
import { Media } from "./media";
import { randomRef } from "../utils/random";

export enum ProductCategory {
  Platter = "les-plateaux",
  Cave = "la-cave",
  Shop = "la-boutique",
}

export const productCategoryList = [
  {
    label: "Les plateaux",
    value: ProductCategory.Platter,
  },
  {
    label: "La cave",
    value: ProductCategory.Cave,
  },
  {
    label: "La boutique",
    value: ProductCategory.Shop,
  },
];

export enum ProductType {
  Single = "single",
  Assortment = "assortment",
}

export type ProductFilter = {
  name: string;
  value: string;
};

export type Product = {
  _id: string;
  name: string;
  slug: string;
  ref: string;
  categories: ProductCategory[];
  description: string;

  hasDescription2: boolean;
  description2Title: string;
  description2Content: string;

  googleDescription: string;

  technicalSheet: Media | null;
  photo: Media | null;
  photos: Media[];
  isActive: boolean;
  supplier: {
    _id: string;
    name: string;
  } | null;
  suppliers: [];
  filters: ProductFilter[];
  priceMin: number;
  priceMax: number;
  isDomainOfMoment: boolean;
  isSelectionOfMoment: boolean;
  isSuperFresh: boolean;
  tva: Tva;
};

export type ProductDto = {
  name: string;
  ref: string;
  categories: ProductCategory[];
  description: string;

  hasDescription2: boolean;
  description2Title: string;
  description2Content: string;

  googleDescription: string;

  technicalSheet: Media | null;
  photo: Media | null;
  photos: Media[];
  isActive: boolean;
  supplier: string | null;
  filters: ProductFilter[];
  isDomainOfMoment: boolean;
  isSelectionOfMoment: boolean;
  isSuperFresh: boolean;
  tva: string;
};

export const productSchema = yup.object({
  name: yup.string().required(),
  ref: yup.string().required(),
  type: yup.string().oneOf([ProductType.Assortment, ProductType.Single]),
  categories: yup
    .array()
    .min(1)
    .of(
      yup
        .string()
        .oneOf([
          ProductCategory.Platter,
          ProductCategory.Cave,
          ProductCategory.Shop,
        ])
    ),
  description: yup.string().required(),

  photo: mediaSchema.required("La photo est obligatoire"),

  hasDescription2: yup.boolean(),
  description2Title: yup.string(),
  description2Content: yup.string(),

  supplier: yup.string().when("type", {
    is: ProductType.Single,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  tva: yup.string().required(),
});

export const productDbToDto = (product: Partial<Product>): ProductDto => {
  if (!product) return defaultProduct;
  return {
    name: product?.name || "",
    ref: product?.ref || randomRef(),
    description: product?.description || "",
    hasDescription2: !!product?.hasDescription2,
    description2Title: product?.description2Title || "",
    description2Content: product?.description2Content || "",
    googleDescription: product?.googleDescription || "",
    categories: product?.categories || [],
    photo: product?.photo || null,
    photos: product?.photos || [],
    technicalSheet: product?.technicalSheet || null,
    isActive: product.isActive || false,
    supplier: product?.supplier?._id || null,
    filters: product?.filters || [],
    isDomainOfMoment: product?.isDomainOfMoment || false,
    isSelectionOfMoment: product?.isSelectionOfMoment || false,
    isSuperFresh: product?.isSuperFresh || false,
    tva: product.tva?._id || "",
  };
};

export const defaultProduct: ProductDto = {
  name: "",
  ref: "",
  description: "",

  hasDescription2: true,
  description2Title: "Composition",
  description2Content: "",

  googleDescription: "",

  categories: [],
  photo: null,
  technicalSheet: null,
  photos: [],
  isActive: false,
  supplier: null,
  filters: [],
  isDomainOfMoment: false,
  isSelectionOfMoment: false,
  isSuperFresh: false,
  tva: "",
};
