export type Filter = {
  _id: string;
  name: string;
  label: string;
};

export type FilterDto = {
  name: string;
  label: string;
};

export const filterDbToDto = (filter: Filter): FilterDto => {
  return {
    name: filter?.name,
    label: filter?.label,
  };
};

export const defaultFilter: FilterDto = {
  name: "",
  label: "",
};
