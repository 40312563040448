import React, { useEffect, useState } from "react";
import InputText from "../../components/Field/InputText";
import Editor from "../../components/Field/Editor";
import Media from "../../components/Field/Media";
import Button from "../../components/Field/Button";

import { useForm, SubmitHandler } from "react-hook-form";
import {
  Product,
  ProductDto,
  defaultProduct,
  productDbToDto,
  productCategoryList,
  productSchema,
  SelectOption,
} from "../../types";
import { productCreate, productUpdate, tvaFindAll } from "../../api";
import Switch from "../../components/Field/Switch";
import SelectMulti from "../../components/Field/SelectMulti";
import Select from "../../components/Field/Select";
import SelectFilters from "./fields/Filters";
import { randomRef } from "../../utils/random";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  product?: Partial<Product>;
  onFinish?: (product: Product) => any;
};

const ProductForm = ({ product, onFinish }: Props) => {
  const [tva, setTva] = useState<SelectOption[]>([]);
  const id = product?._id || null;
  const { control, handleSubmit, watch } = useForm<ProductDto>({
    resolver: yupResolver(productSchema),
    defaultValues: product
      ? productDbToDto(product)
      : { ...defaultProduct, ref: randomRef() },
  });

  const onSubmit: SubmitHandler<ProductDto> = async (data) => {
    const res = await (id ? productUpdate(id, data) : productCreate(data));
    if (typeof onFinish === "function") onFinish(res);
  };

  useEffect(() => {
    tvaFindAll().then((res) =>
      setTva(
        res.map((v) => ({
          label: `${v.name} - ${v.value}%`,
          value: v._id,
        }))
      )
    );
  }, []);

  const googleDescription = watch("googleDescription");

  return (
    <form key={product?._id || ""} onSubmit={handleSubmit(onSubmit)}>
      <InputText label="Nom" name="name" control={control} />
      <InputText label="Référence" name="ref" control={control} />
      <InputText
        label="Description Google"
        name="googleDescription"
        maxLength={155}
        control={control}
      />
      <em>{googleDescription.length}/155</em>
      <SelectMulti
        id="categories"
        label="Pages"
        name="categories"
        control={control}
        options={productCategoryList}
      />
      <Editor label="Description" name="description" control={control} />
      <Switch
        label="Afficher la description annexe"
        name="hasDescription2"
        control={control}
      />
      <InputText
        label="Titre de description annexe"
        name="description2Title"
        control={control}
      />
      <Editor
        label="Contenu de description annexe"
        name="description2Content"
        control={control}
      />
      <Media
        label="Photo principale"
        name="photo"
        extensions={["jpg", "jpeg", "png"]}
        control={control}
      />
      <Media
        label="Photo secondaires"
        name="photos"
        multi
        extensions={["jpg", "jpeg", "png"]}
        control={control}
      />
      <Media
        label="Fiche technique"
        name="technicalSheet"
        extensions={["pdf"]}
        control={control}
      />
      <SelectFilters
        control={control}
        label="Filtres"
        name="filters"
        id="filters"
      />
      <Select name="tva" control={control} label="TVA" options={tva} />
      <Switch
        label="Afficher dans le catalogue"
        name="isActive"
        control={control}
      />
      <Switch
        label="Sélection du moment"
        name="isSelectionOfMoment"
        control={control}
      />
      <Switch
        label="Domaine du moment"
        name="isDomainOfMoment"
        control={control}
      />
      <Switch
        label="Produit ultra frais"
        name="isSuperFresh"
        control={control}
      />
      <Button
        sx={{ mt: "50px", mb: "50px" }}
        type="submit"
        label={id ? "Modifier" : "Ajouter"}
      />
    </form>
  );
};

export default ProductForm;
