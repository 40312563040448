import { SelectOption } from ".";
export enum DeliveryDatePeriod {
  AM = "am",
  PM = "pm",
}

export const deliveryDatePeriodOptions: SelectOption[] = [
  { value: DeliveryDatePeriod.AM, label: "10h -> 13h" },
  { value: DeliveryDatePeriod.PM, label: "17h -> 21h" },
];

export type DeliveryDate = {
  _id: string;
  clickAndCollect: string | null;
  key: string;
  date: Date;
  period: DeliveryDatePeriod;
  lastCommand: Date;
  lastSuperFreshCommand: Date;
};

export type DeliveryDateDto = {
  clickAndCollect: string | null;
  date: Date;
  period: DeliveryDatePeriod;
  lastCommand: Date;
  lastSuperFreshCommand: Date;
};

export const defaultDeliveryDate: DeliveryDateDto = {
  clickAndCollect: null,
  date: new Date(),
  period: DeliveryDatePeriod.AM,
  lastCommand: new Date(),
  lastSuperFreshCommand: new Date(),
};

export const deliveryDateDbToDto = (
  deliveryDate: DeliveryDate | null
): DeliveryDateDto => {
  if (!deliveryDate) {
    return defaultDeliveryDate;
  }
  return {
    clickAndCollect: deliveryDate.clickAndCollect,
    date: deliveryDate.date,
    period: deliveryDate.period,
    lastCommand: deliveryDate.lastCommand,
    lastSuperFreshCommand: deliveryDate.lastSuperFreshCommand,
  };
};
