import { TextField } from "@mui/material";
import { useController, Control } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import DateTimePicker from "@mui/lab/MobileDateTimePicker";

type Props = {
  control: Control<any>;
  disabled?: boolean;
  label: string;
  name: string;
};

function InputDateTime({
  control,
  disabled,
  label,
  name,
}: Props): React.ReactElement {
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
  });

  return (
    <div style={{ marginTop: "10px" }}>
      <DateTimePicker
        disabled={disabled}
        label={label}
        mask="__/__/____ __:__"
        renderInput={(props) => (
          <TextField {...props} fullWidth sx={{ mt: 2 }} />
        )}
        {...field}
        value={field.value || ""}
        inputRef={ref}
      />
      <ErrorMessage name={name} control={control} />
    </div>
  );
}

InputDateTime.defaultProps = {
  disabled: false,
};

export default InputDateTime;
