import ClickAndCollectForm from "./ClickAndCollectForm";
import { useNavigate } from "react-router-dom";
import { useSnack } from "../../contexts/SnackContext";
import { ClickAndCollect } from "../../types";

export default function ClickAndCollectList() {
  const navigate = useNavigate();
  const snack = useSnack();

  const onFinish = (clickAndCollect: ClickAndCollect) => {
    snack.success(`${clickAndCollect.name} a bien été créé.`);
    navigate("/click-and-collect");
  };

  return (
    <>
      <h1>Créer un click & collect</h1>
      <ClickAndCollectForm onFinish={onFinish} />
    </>
  );
}
