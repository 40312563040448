import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Control, useController } from "react-hook-form";
import { useMemo } from "react";
import ErrorMessage from "./ErrorMessage";

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  id: string;
  name: string;
  label: string;
  options: OptionType[];
  control: Control<any>;
}

export default function SelectMulti({
  control,
  label,
  options,
  name,
  id,
}: Props): React.ReactElement {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const index = useMemo(
    () =>
      options.reduce((acc, option) => {
        acc.set(option.value, option);
        return acc;
      }, new Map()),
    [options]
  );

  return (
    <FormControl
      error={!!error}
      sx={{ mt: 2, width: "100%" }}
      variant="outlined"
    >
      <Autocomplete
        id={id}
        multiple
        options={options}
        onChange={(_event: any, newValue: OptionType[]) => {
          onChange({
            target: {
              value: (newValue || []).map((v) => v.value),
            },
          });
        }}
        filterSelectedOptions
        getOptionLabel={(option) => option?.label || ""}
        value={(field.value || []).map((v: any) => index.get(v))}
        isOptionEqualToValue={(option, newValue) =>
          option?.value === newValue?.value
        }
        renderInput={(params) => <TextField {...params} label={label} />}
      />

      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}
