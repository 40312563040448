import React, { useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import {
  filterValueFindAll,
  filterValueDelete,
  filterFindOne,
} from "../../api";
import { FilterValue, Filter } from "../../types";
import FilterValueForm from "./FilterValueForm";
import { Fab } from "@mui/material";
import BtnDelete from "../../components/Element/BtnDelete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

export default function FilterValueList() {
  const [filter, setFilter] = useState<Filter | null>(null);
  const [filterValues, setFilterValues] = useState<FilterValue[]>([]);
  const [showFormFilterValue, setShowFormFilterValue] =
    useState<boolean>(false);
  const [editFilterValue, setEditFilterValue] = useState<FilterValue | null>(
    null
  );

  const { id } = useParams();

  useEffect(() => {
    if (!id || typeof id !== "string") return;
    filterFindOne(id).then(setFilter);
  }, [id]);

  useEffect(() => {
    if (!id || typeof id !== "string") return;
    filterValueFindAll(id).then(setFilterValues);
  }, [id]);

  const deleteFilterValue = useCallback(
    async (filterValue: FilterValue) => {
      if (!window.confirm(`Supprimer le filtre ${filterValue.value} ?`)) return;
      await filterValueDelete(`${id}`, filterValue._id);
      setFilterValues((fs) => fs.filter((f) => f._id !== filterValue._id));
    },
    [id]
  );

  return (
    <>
      <div style={{ float: "right", padding: "20px 0" }}>
        <Fab color="primary" onClick={() => setShowFormFilterValue(true)}>
          <AddIcon />
        </Fab>
      </div>
      <h1>Gestion du filtre {filter && `"${filter?.name}"`}</h1>
      {showFormFilterValue && (
        <FilterValueForm
          filterId={`${id}`}
          filterValue={editFilterValue === null ? undefined : editFilterValue}
          onFinish={(filterValue) => {
            setShowFormFilterValue(false);
            setEditFilterValue(null);
            if (filterValue !== null) {
              if (filterValues.map((f) => f._id).includes(filterValue._id)) {
                setFilterValues(
                  filterValues.map((f) =>
                    f._id === filterValue._id ? filterValue : f
                  )
                );
              } else {
                setFilterValues([...filterValues, filterValue]);
              }
            }
          }}
        />
      )}

      <TableContainer component={Paper}>
        <Table aria-label="Filtres">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterValues.map((filterValue: FilterValue) => (
              <TableRow
                key={filterValue._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{filterValue.value}</TableCell>
                <TableCell align="right">
                  <Fab
                    color="primary"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setEditFilterValue(filterValue);
                      setShowFormFilterValue(true);
                    }}
                  >
                    <EditIcon />
                  </Fab>
                  <BtnDelete
                    onClick={() => {
                      deleteFilterValue(filterValue);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
