import styled, { createGlobalStyle, css } from "styled-components";
import { Fonts, Colors } from "../../utils/style";
import Color from "color";

const Button = css`
  color: ${Colors.CAPUCINE};
  font-family: ${Fonts.SANS};
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.8rem;
  padding: 10px 30px;
  border-radius: 0px 15px;
  border: 1px solid ${Colors.CAPUCINE};
  background: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  text-align: center;

  &:hover {
    background-color: ${Colors.CAPUCINE};
    color: ${Colors.WHITE};
  }
`;

export const GlobalStyle = createGlobalStyle`

  html {
    font-size: 0.625em;
    font-size: calc(1em * 0.625);
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-size: 1.4em;
    margin: 0;
    padding: 50px 0;
    background-color: ${Colors.MIRAGE};
  }

  .draggable {
    cursor: grab;
  }

  @media print {

    .no-print {
      display: none;
  }

    body {
      background-color: ${Colors.WHITE};
      padding: 0;
    }
  }



  button {
    ${Button}
  }
`;

export const StyledAddress = styled.address`
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-style: normal;
  strong {
    font-size: 1.9rem;
  }
`;

export const StyledOrder = styled.div`
  background-color: ${Colors.WHITE};
  width: 1000px;
  margin: auto;
  padding: 50px;
  aspect-ratio: 21/29.3;
  box-sizing: border-box;
  position: relative;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 200px auto auto 55px 1fr auto;

  p,
  address,
  td,
  th,
  h1,
  h2 {
    color: ${Colors.MIRAGE};
    font-family: ${Fonts.SANS};
  }

  h1 {
    font-size: 2.5rem;
  }

  @media print {
    border: none;
  }
`;

export const Logo = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
  display: flex;
  justify-content: center;
`;

const borderColor = new Color(Colors.MIRAGE).fade(0.8).toString();

export const Content = styled.div`
  grid-column: 1 / 3;
  grid-row: 3 / 5;

  .invoiceOn {
    margin: 0 0 10px;
    padding: 0;
    font-style: italic;
  }

  table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;
    td,
    th {
      border: 1px solid ${borderColor};

      padding: 7px;
      font-size: 1.2rem;
      line-height: 1.3rem;
      vertical-align: middle;

      &.center {
        text-align: center;
        white-space: nowrap;
      }

      &.right {
        text-align: right;
        white-space: nowrap;
      }
    }

    tr.to-invoice td {
      background-color: ${Colors.CAPUCINE};
    }

    th {
      background-color: ${Colors.BISQUE};

      &.ref {
        width: 150px;
      }

      &.quantity {
        width: 100px;
      }
    }

    .item-product {
      .ref,
      .name {
        border-bottom: 0px;
        padding-bottom: 0;
      }

      .name {
        font-size: 1.3rem;
      }
    }

    .ref {
      font-size: 1rem;
      vertical-align: top;
    }

    .item-variant {
      .ref,
      .name {
        border-top: 0;
        padding-top: 3px;
      }

      .name {
        font-size: 0.9rem;
      }
    }

    button {
      padding: 5px 10px;
    }
  }
`;

export const Print = styled.div`
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;

  @media print {
    display: none;
  }
`;
