import { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScrollRef from "../../components/ScrollRef";
import useUsers from "../../hooks/useUsers";
import Search from "../../components/Field/Search";
import LinkEdit from "../../components/Element/LinkEdit";
import LinkCustom from "../../components/Element/LinkCustom";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LinkAdd from "../../components/Element/LinkAdd";

export default function UserList() {
  const [search, setSearch] = useState("");
  const { users, setSize } = useUsers(`&s=${search}`);

  let totalUsers = users.length;
  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/user/add`} />
      </div>
      <h1>Liste des Utilisateurs</h1>
      <Search onChange={setSearch} />
      <p>{totalUsers} utilisateur(s)</p>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.roles.join(", ")}</TableCell>
                <TableCell align="right">
                  <LinkEdit url={`/user/update/${user._id}`} />
                  <LinkCustom
                    url={`/user/order/${user._id}`}
                    icon={<ShoppingBagIcon />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ScrollRef load={() => setSize((s) => s + 1)} />
    </>
  );
}
