import { useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import {
  Checkbox, FormGroup, FormControlLabel, FormControl,
} from '@mui/material';
import { useController, Control } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

type Choice = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  control: Control<any>;
  name: string;
  label: string;
  row?: boolean;
  choices: Choice[];
};

export default function Checkboxes({
  control,
  choices,
  name,
  id,
  row,
  label,
}: Props): React.ReactElement {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: [],
  });
  const [values, setValues] = useState<string[]>(value);
  return (
    <FormControl
      component="fieldset"
      error={!!error}
      sx={{ mt: 2, width: '100%' }}
      variant="outlined"
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row={row}>
        {choices.map((choice) => (
          <FormControlLabel
            id={`${id}_${choice.value}`}
            key={choice.value}
            label={choice.label}
            control={(
              <Checkbox
                checked={(values || []).includes(choice.value)}
                onChange={(e) => {
                  let newValues = [];
                  if (e.target.checked) {
                    newValues = [...values, choice.value];
                  } else {
                    newValues = values.filter((v) => v !== choice.value);
                  }
                  setValues(newValues);
                  onChange({ target: { value: newValues } });
                }}
              />
            )}
          />
        ))}
      </FormGroup>
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}

Checkboxes.defaultProps = {
  row: true,
};
