import { useState, useCallback } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScrollRef from "../../components/ScrollRef";
import useProducts from "../../hooks/useProducts";
import { Product } from "../../types";
import LinkDelete from "../../components/Element/LinkDelete";
import LinkAdd from "../../components/Element/LinkAdd";
import LinkEdit from "../../components/Element/LinkEdit";
import LinkCopy from "../../components/Element/LinkCopy";
import CategoryIcon from "@mui/icons-material/Category";
import Search from "../../components/Field/Search";
import price from "../../utils/price";
import LinkCustom from "../../components/Element/LinkCustom";

export default function ProductList() {
  const [search, setSearch] = useState("");
  const { products, size, setSize } = useProducts(`&s=${search}`);

  const getPrice = useCallback((product: Product) => {
    if (product.priceMin === product.priceMax) {
      return price(product.priceMin);
    }
    return `${price(product.priceMin)} - ${price(product.priceMax)}`;
  }, []);
  return (
    <>
      <div style={{ float: "right", padding: "20px" }}>
        <LinkAdd url={`/product/add`} />
      </div>
      <h1>Liste des produits</h1>
      <Search onChange={setSearch} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell align="right">Prix</TableCell>
              <TableCell align="right">TVA</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(products || []).map((product: Product) => (
              <TableRow
                key={product._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{product.name}</TableCell>
                <TableCell align="right">{getPrice(product)}</TableCell>
                <TableCell align="right">{product.tva.value}%</TableCell>
                <TableCell align="right">
                  <LinkCopy url={`/product/copy/${product._id}`} />
                  <LinkEdit url={`/product/update/${product._id}`} />
                  <LinkCustom
                    url={`/product/variant/${product._id}`}
                    icon={<CategoryIcon />}
                  />
                  <LinkDelete url={`/product/delete/${product._id}`} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ScrollRef load={() => setSize(size + 1)} />
    </>
  );
}
